<div class="simple-dialog" *transloco="let t;">
  <div class="dialog-loading" *ngIf="data.type === types.Loading">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="data.type !== types.Loading">
    <div class="dialog-icon icon is-success" *ngIf="data.type === types.Success || data.type === types.DownloadSucess"><i class="fas fa-check-circle"></i></div>
    <div class="dialog-icon icon is-failure" *ngIf="data.type === types.Failure"><i class="fas fa-times-circle"></i></div>
    <div class="dialog-icon icon is-warning" *ngIf="data.type === types.Warning"><i class="fas fa-exclamation-triangle"></i></div>
    <div class="dialog-icon icon is-warning" *ngIf="data.type === types.Confirm || data.type === types.Login"><i class="fas fa-exclamation-triangle"></i></div>
    <div class="dialog-icon icon is-info" *ngIf="data.type === types.Info"><i class="fas fa-info-circle"></i></div>
    <div class="dialog-header" mat-dialog-title>
      <h2 class="dialog-title has-text-centered">{{data.title}}</h2>
    </div>
    <div class="dialog-content" mat-dialog-content>
      <div class="has-text-centered">{{data.message}}</div>
    </div>
    <div class="dialog-actions">
      <div class="buttons is-centered" *ngIf="data.type !== types.Confirm && data.type !== types.Login && data.type !== types.DownloadSucess">
        <button class="button is-primary"  mat-dialog-close>{{t('global.formActions.ok')}}</button>
      </div>
      <div class="buttons is-centered" *ngIf="data.type === types.Confirm">
        <button class="button is-light" mat-dialog-close>{{t('global.formActions.cancel')}}</button>
        <button class="button is-primary" [mat-dialog-close]="true">{{t('global.formActions.confirm')}}</button>
      </div>
      <div class="buttons is-centered" *ngIf="data.type === types.Login">
        <button class="button is-light" mat-dialog-close>{{t('global.formActions.cancel')}}</button>
        <button class="button is-primary" [mat-dialog-close]="true">{{t('global.formActions.login')}}</button>
      </div>
      <div class="buttons is-centered" *ngIf="data.type == types.DownloadSucess">
        <button class="button is-light" mat-dialog-close>{{t('global.formActions.cancel')}}</button>
        <button class="button is-primary" [mat-dialog-close]="true">{{t('core.search.go_to_downloads_history')}}</button>
      </div>
    </div>
  </ng-container>
</div>
