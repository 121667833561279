import { Directive, Input, Renderer2, ElementRef, SimpleChanges, OnChanges } from '@angular/core';

const SkeletonWidthMap = [85, 70, 40, 65, 50, 30];
const SkeletonBoxClassName = 'skeleton-box';

@Directive({
  selector: '[appSkeletonBox]'
})
export class SkeletonBoxDirective implements OnChanges{
  @Input('appSkeletonBox') loading = false;
  @Input() SVersion = '1';
  private skeletonBox;
  private initedSkeleton = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  private clearSkeleton() {
    this.renderer.removeClass(this.el.nativeElement, SkeletonBoxClassName);
    if (this.SVersion) {
      this.renderer.removeClass(this.el.nativeElement, this.SVersion);
    }
    this.renderer.removeChild(this.el.nativeElement, this.skeletonBox);
  }
  private addSkeleton() {
    // create skeleton box psudo-component
    this.skeletonBox = this.renderer.createElement('div');
    this.renderer.addClass(this.skeletonBox, 'skeleton-box-container');

    const createSkeletonLine = (percentage = 100) => {
      const percentageString = percentage.toString();
      const line = this.renderer.createElement('div');
      this.renderer.addClass(line, 'skeleton-line');
      this.renderer.setStyle(line, 'width', percentageString + '%');
      return line;
    };

    const createSkeletonBoxImg = () => {
      const img = this.renderer.createElement('div');
      this.renderer.addClass(img, 'skeleton-box-img');
      return img;
    };

    const createVersion1 = () => {
      this.renderer.addClass(this.skeletonBox, 'v1');
      // add paragraph rows
      for (const size of SkeletonWidthMap) {
        this.renderer.appendChild(this.skeletonBox, createSkeletonLine(size));
      }
    };

    const createVersion2 = () => {
      const NUM_ROWS = 3; // define the number of rows you want
      this.renderer.addClass(this.skeletonBox, 'v2');
      // add box image
      this.renderer.appendChild(this.skeletonBox, createSkeletonBoxImg());
      // create paragraph container
      const pContainer = this.renderer.createElement('div');
      this.renderer.addClass(pContainer, 'skeleton-box-p-container');
      this.renderer.appendChild(this.skeletonBox, pContainer);
      // add paragraph rows to paragraph container
      for (let i = 0; i < NUM_ROWS; i++) {
        const size = SkeletonWidthMap[i];
        this.renderer.appendChild(pContainer, createSkeletonLine(size));
      }
    }

    // create a specific version
    switch (this.SVersion) {
      case '1':
        createVersion1();
        break;
      case '2':
        createVersion2();
      default:
        break;
    }

    // render Skeleton box psudo-component to host element
    this.renderer.addClass(this.el.nativeElement, SkeletonBoxClassName);
    this.renderer.appendChild(this.el.nativeElement, this.skeletonBox);
    this.initedSkeleton = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading) {
      if (this.loading) {
        this.addSkeleton();
      } else {
        if (this.initedSkeleton) {
          this.clearSkeleton();
        }
      }
    }
  }

}
