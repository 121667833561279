import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Location } from '@angular/common';
import { Navigate } from '@ngxs/router-plugin';

import {
  SearchRequest,
  ActionsMobileMenu,
  InitRootFilters,
  InitUser,
  Logout,
  UpdateSessionTokens,
  UpdateUser,
  SetAllowNavigation,
  LastSearchRequest,
  SelectFilter} from './main.actions';
import { FilterNode } from '../models/filter.model';
import { FiltersService } from '../services/filters.service';
import { tap } from 'rxjs/operators';
import { User } from '../models/user.model';

export class MainStateModel {
  public searchString: string;
  public lastSearch: string;
  public mobileMenuState: boolean;
  public rootFilters: FilterNode[];
  public selectedFilters: FilterNode[];
  public user: User | null;
  public cameFromLogin: boolean;
  public allowNavigation: boolean;
}

@Injectable()
@State<MainStateModel>({
  name: 'main',
  defaults: {
    searchString: '',
    lastSearch: '',
    rootFilters: [],
    selectedFilters: [],
    mobileMenuState: false,
    user: null,
    cameFromLogin: false,
    allowNavigation: true
  }
})
export class MainState {
  @Selector() static searchString(state: MainStateModel) { return state.searchString; }
  @Selector() static lastSearch(state: MainStateModel) { return state.lastSearch; }
  @Selector() static mobileMenu(state: MainStateModel) { return state.mobileMenuState; }
  @Selector() static rootFilters(state: MainStateModel) { return state.rootFilters; }
  @Selector() static selectedFilters(state: MainStateModel) { return state.selectedFilters; }
  @Selector() static user(state: MainStateModel) { return state.user; }

  constructor(
    private filtersService: FiltersService,
    private location: Location
  ) {}

  @Action(SearchRequest)
  SearchRequest(ctx: StateContext<MainStateModel>, action: SearchRequest) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      searchString: action.payload
    });
  }

  @Action(LastSearchRequest)
  LastSearchRequest(ctx: StateContext<MainStateModel>, action: LastSearchRequest) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      lastSearch: action.payload
    });
  }

  @Action(ActionsMobileMenu)
  ActionsMobileMenu(ctx: StateContext<MainStateModel>, action: ActionsMobileMenu) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mobileMenuState: action.payload
    });
  }

  @Action(InitRootFilters)
  InitRootFilters(ctx: StateContext<MainStateModel>) {
    return this.filtersService.getsearchFilters().pipe(tap((filters) => {
      const result = JSON.parse(JSON.stringify(filters));
      const state = ctx.getState();
      state.selectedFilters.forEach(selectedFilter => {
        const index = result.findIndex((filter: any) => filter.id === selectedFilter.id && selectedFilter.hasChildSelected);
        if (index === -1) return;
        result[index] = selectedFilter;
      });
      ctx.setState({
        ...state,
        rootFilters: result,
      });
    }));
  }

  @Action(SelectFilter)
  SelectedFilter(ctx: StateContext<MainStateModel>, action: SelectFilter) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      selectedFilters: action.payload
    });
  }

  @Action(InitUser)
  initUser(ctx: StateContext<MainStateModel>, { payload }: InitUser) {
    const state = ctx.getState();
    ctx.patchState({
      user: payload,
      cameFromLogin: true,
    });
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<MainStateModel>, { payload }: UpdateUser) {
    const state = ctx.getState();
    ctx.patchState({
      user: {
        ...state.user,
        ...payload
      }
    });
  }

  @Action(UpdateSessionTokens)
  updateSessionTokens(ctx: StateContext<MainStateModel>, { payload }: UpdateSessionTokens) {
    const state = ctx.getState();
    ctx.patchState({
      user: {
        ...state.user,
        ...payload
      }
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<MainStateModel>) {
    ctx.patchState({
      user: null,
      searchString: '',
      lastSearch: '',
      selectedFilters: []
    });
    ctx.dispatch(new Navigate(['/search']));
  }
}
