import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';

import { UsersService } from '../../services/users.service';
import { AdminDialogService } from '../../../coreModule/services/adminDialog/admin-dialog.service';
import { SetAllowNavigation } from '../../store/main.actions';
import { compareValidator } from '../../../adminModule/commons/validators';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  userCreateForm: FormGroup;
  submitting = false;
  users: User[] = [];
  available = true;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private fb: FormBuilder,
    private adminDialog: AdminDialogService,
    private store: Store,
    private translocoS: TranslocoService
  ) { }

  ngOnInit(): void {
    const reg_exp = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9$@$!%-.#*]{8,16}';
    this.userCreateForm = this.fb.group({
      email: [null, { validators: [Validators.required, Validators.email] }],
      first_name: [null, { validators: [Validators.required] }],
      last_name: [null, { validators: [Validators.required] }],
      password1: [null, { validators: [Validators.required, Validators.pattern(reg_exp)] }],
      password2: [null, { validators: [Validators.required, compareValidator('password1')] }],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }

  searchAvailableEmail() {
    let params = '?'
    let prev = false
    let email = this.userCreateForm.get('email').value;
    this.available = true;
    if (email){
      params += 'email=' + email;
      prev = true;
    }

    this.usersService.getEmailUsers(params).subscribe( data => {
      this.users = data.results;
      if (data.count > 0) {
        this.available = false;
      }
    });

  }

  async onSubmit() {
    if (this.userCreateForm.enable && this.userCreateForm.valid) {
      try {
        this.submitting = true;
        const userData: User = {
          email: this.userCreateForm.value.email,
          first_name: this.userCreateForm.value.first_name,
          last_name: this.userCreateForm.value.last_name,
          password: this.userCreateForm.value.password1,
          is_admin_user: false
        }
        this.userCreateForm.disable();
        // this.store.dispatch(new SetAllowNavigation(false));
        await this.usersService.createUser(userData);
        // this.store.dispatch(new SetAllowNavigation(true));
        this.submitting = false;
        this.adminDialog.success(this.translocoS.translate('admin.usersCreate.create_user_success'));
        this.router.navigate(['/']);
      } catch (error) {
        console.error(error);
        this.submitting = false;
        this.store.dispatch(new SetAllowNavigation(true));
        this.userCreateForm.enable();
        // TODO: show error message from backend onces standardized
        this.adminDialog.failure();
      }
    }
  }

}
