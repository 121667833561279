import { Directive, Input, Renderer2, ElementRef, AfterViewInit, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

const READMORECONTAINERCLASS = 'read-more-container';
const READMOREINNETCLASS = 'read-more-inner';
const FULLBORDERCLASS = 'full-border';
const HEIGHTOFFSET = 50;

// TODO: implement translation for "read more" text using transloco
@Directive({
  selector: '[appReadMore]'
})
export class ReadMoreDirective implements AfterViewInit, OnChanges, OnInit {
  @Input() maxHeight = 200;
  @Input() fullBorder = false;
  @Input() readMoreReCalc = false;
  originalHeight: number;
  originalWidth: number;
  innerNode: HTMLElement;
  readMoreClicked = false;
  stopRecalc = false;
  readMoreText: string;
  button: HTMLButtonElement;

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private translocoS: TranslocoService
  ) {}

  ngOnInit() {
    this.translocoS.selectTranslate('global.read_more').subscribe((value) => {
      this.readMoreText = value;
      if (this.button) { this.button.innerText = value; }
    });
  }

  private createInner(): void {
    this.innerNode = this.renderer.createElement('div');
    this.renderer.addClass(this.innerNode, READMOREINNETCLASS);
    if (this.fullBorder) {
      this.renderer.addClass(this.innerNode, FULLBORDERCLASS);
    }
    const rail = this.renderer.createElement('div');
    this.renderer.addClass(rail, 'rail');
    this.button = this.renderer.createElement('button');
    this.renderer.addClass(this.button, 'is-primary');
    this.renderer.addClass(this.button, 'button');
    this.renderer.listen(this.button, 'click', () => this.onReadMoreClick());

    const buttonText = this.renderer.createText(this.readMoreText);
    this.renderer.appendChild(this.button, buttonText);
    this.renderer.appendChild(rail, this.button);
    this.renderer.appendChild(this.innerNode, rail);
    this.renderer.appendChild(this.el.nativeElement, this.innerNode);
  }

  private onReadMoreClick() {
    this.renderer.setStyle(this.el.nativeElement, 'max-height', this.originalHeight + 'px');
    this.renderer.removeChild(this.el.nativeElement, this.innerNode);
    this.readMoreClicked = true;
  }

  private checkForReadMore() {
    if (this.readMoreClicked || this.stopRecalc) { return; }
    setTimeout(() => {
      if (this.stopRecalc) { return; }
      this.originalHeight = this.el.nativeElement.offsetHeight;
      this.originalWidth = this.el.nativeElement.offsetWidth;
      if (this.originalHeight >= (this.maxHeight + HEIGHTOFFSET)) {
        this.stopRecalc = true;
        this.renderer.addClass(this.el.nativeElement, READMORECONTAINERCLASS);
        this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
        this.renderer.setStyle(this.el.nativeElement, 'max-height', this.maxHeight + 'px');
        this.createInner();
      }
    }, 0);
  }

  ngAfterViewInit(): void {
    this.checkForReadMore();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.readMoreReCalc) {
      this.checkForReadMore();
    }
  }

}
