import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removenumber'
})
export class RemoveNumberPipe implements PipeTransform {
// TODO con el nuevo backend esto es innecesario. Deuda Tecnica
  transform(value: string, args?: any): string {
    return value ? value.split(' ')[1] : '';
  }
}
