import { AfterViewInit, Component, OnInit, Input, ComponentFactoryResolver, Injector, ApplicationRef, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';

import * as L from 'leaflet';

import { Assessment } from '../../models/assessment.model';
import { AssessmentCardComponent } from '../assessment-card/assessment-card.component';
import { getCatColor, categoryImg } from '../../utils/functions';
import { RestService } from '../../services/rest.service';
import { BrandLogo, AdminSetting } from 'src/app/adminModule/models/adminsetting.model';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements AfterViewInit, OnChanges, OnDestroy {

  // Add base layers
  openstreet = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  });
  imagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  });
  worldtopomap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
  });
  oceanbasemap = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}', {
    attribution: 'Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri'
  });

  baseMaps = {
    "OpenStreet": this.openstreet,
    "Imagery": this.imagery,
    "World TopoMap": this.worldtopomap,
    "Ocean Base Map": this.oceanbasemap,
  };

  contactUs: string;
  mapDisclaimer_en: string;
  mapDisclaimer_es: string;
  mapDisclaimer: string;
  langChangeSubscription: Subscription;
  private map;
  public disclaimer = true;
  @Input() markers: Assessment[];
  @Input() centerX = 0;
  @Input() centerY = 0;
  @Input() zoom = 1;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private restService: RestService,
    private translocoS: TranslocoService
  ) { }

  private async getSetting() {
    const settingData = await this.restService.getSettings();
    this.contactUs = settingData ? settingData?.[0]?.link_email : null;
    const data_disclaimer = settingData ? settingData?.[0]?.map_message : null;
    const mapDisclaimerData = data_disclaimer ? JSON.parse(data_disclaimer) : [];
    if (mapDisclaimerData){
      mapDisclaimerData.forEach(row =>{
        if (row){
          if (row && row.lang === 'en'){
            this.mapDisclaimer_en = row.content;
          }
          if (row && row.lang  === 'es'){
            this.mapDisclaimer_es = row.content;
          }
        }
      });
    }
    const switchLang = window.navigator.language.slice(0, 2);
    if (switchLang === 'es'){
      this.mapDisclaimer = this.mapDisclaimer_es;
    }else{
      this.mapDisclaimer = this.mapDisclaimer_en;
    }
  }

  ngOnInit(): void {
    this.getSetting();
    this.langChangeSubscription = this.translocoS.langChanges$.subscribe(lang => {
      if (lang === 'es') {
        this.mapDisclaimer = this.mapDisclaimer_es;
        return;
      }
      this.mapDisclaimer = this.mapDisclaimer_en;
    })
  }


  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.addMarkers(this.markers);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const markers = changes.markers;
    if (markers) {
      // only do something if previousValue is truthy
      if (markers.previousValue) {
        // find the new elements
        const difference = markers.currentValue.filter((current) => !markers.previousValue.some((prev) => prev.id === current.id));
        this.addMarkers(difference);
      }
    }
  }

  public hideDisclaimer(): void {
    // value tru will hide the disclaimer
    this.disclaimer = false;
  }

  private addMarkers(markers: Assessment[]): void {
    for (const m of markers) {
      const longitude = this.calculateCentroid(m.coord[0].longitude, m.coord[1].longitude);
      const latitude = this.calculateCentroid(m.coord[0].latitude, m.coord[1].latitude);
      const TIPOFFSET = 5; // this is because the tip of the marker overflows a little bit on the bottom
      const icon = L.divIcon({
        iconSize: [30, 30],
        className: 'not-default-pin',
        iconAnchor: [15, 30 + TIPOFFSET],
        popupAnchor: [0, -20],
        html: this.getCategoryIcon(m.category.name),
      });

      // create popup contents
      // TODO: adding this causes an ExpressionChangedAfterItHasBeenCheckedError, I'm currently not sure how to solve it
      const AssessmentCardComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AssessmentCardComponent);
      const AssessmentCardComponentRef = AssessmentCardComponentFactory.create(this.injector);
      AssessmentCardComponentRef.instance.assessment = m;
      AssessmentCardComponentRef.instance.noVfx = true;
      this.appRef.attachView(AssessmentCardComponentRef.hostView);
      AssessmentCardComponentRef.onDestroy(() => {
        this.appRef.detachView(AssessmentCardComponentRef.hostView);
      });

      // specify popup options
      const customOptions = {
        minWidth: 280,
      };
      const marker = L.marker([latitude, longitude], { icon })
        .bindPopup(AssessmentCardComponentRef.location.nativeElement, customOptions)
        .addTo(this.map);
    }
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [this.centerX, this.centerY],
      zoom: this.zoom
    });
    // Add Layers control
    L.control.layers(this.baseMaps).addTo(this.map);
    // Set openStreet as initial map
    this.openstreet.addTo(this.map);
  }

  private calculateCentroid(upperrightcorner, lowerleftcorner) {
    return (upperrightcorner + lowerleftcorner) / 2;
  }

  private getCategoryIcon(cat: string) {
    const ICONTEMPLATE = `
      <div class="category-marker" style="background-color: ${getCatColor(cat)};">
        <div class="category-img" style="background-image: url('${categoryImg(cat)}')"></div>
      </div>
    `;
    return ICONTEMPLATE;
  }
}
