import { Pipe, PipeTransform } from '@angular/core';

/*
  this pipe receives a `scope_class_elem_ids` field from the `scope` of the assessment
  and the values could be like the following:
  * "1. Global"
  * "2. Subglobal"
  * "2.1. Regional"
  ...

  the idea behind this pipe is to separate the label "Global", "Subglobal", "Regional" from the accompanying number
  soo we can display only the label in the UI.
*/
@Pipe({
  name: 'categorizer'
})
export class CategorizerPipe implements PipeTransform {
// TODO con el nuevo backend esto es innecesario. Deuda Tecnica
  transform(value: string[], args?: any): string {
    if (value && value.length > 1) {
      return value[0].split(' ')[1];
    } else {
      return value[0].split(' ')[1];
    }
  }
}
