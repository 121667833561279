<main class="admin-auth-base">
  <section class="section" *transloco="let t">
    <div class="wrapper">
      <div class="auth-main-container">
        <div class="container-header" [innerHTML]="t('core.login.log_in')"></div>
        <p [innerHTML]="t('core.login.legend')"></p>
        <br>
        <p [innerHTML]="t('core.login.sub-legend')"></p>
        <br>
        <br>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="field field-email">
            <div class="control has-icons-left">
              <input class="input" type="text" [ngClass]="{'is-danger': f.email.dirty && f.email.errors }" formControlName="email" placeholder="{{ t('admin.login.enter_email') }}">
              <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
            </div>
            <p class="help is-danger" *ngIf="f.email.dirty">
              <span *ngIf="f.email.errors?.email">{{ t('global.validation.invalid_email') }}</span>
              <span *ngIf="f.email.errors?.required">{{ t('global.validation.required_field') }}</span>
            </p>
          </div>
          <div class="field field-password">
            <div class="control has-icons-left">
              <input class="input" type="password" [ngClass]="{'is-danger': f.password.dirty && f.password.errors}" formControlName="password" placeholder="{{ t('admin.login.enter_password') }}">
              <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
            </div>
            <p class="help is-danger" *ngIf="f.password.dirty">
              <span *ngIf="f.password.errors?.required">{{ t('global.validation.required_field') }}</span>
            </p>
          </div>
          <div class="field field-action">
            <div class="control">
              <button class="button is-fullwidth is-primary" [ngClass]="{'is-loading': submitting }" [disabled]="submitting" type="submit">{{ t('admin.login.log_in_button') }}</button>
            </div>
          </div>
          <div class="field">
            <div class="control is-expanded has-text-centered">
              <a class="button is-white forgot-p" routerLink="../forgot-password">{{ t('admin.login.forgot_password') }}</a>
            </div>
          </div>
          <div class="field">
            <div class="control is-expanded has-text-centered">
              <a class="button is-white forgot-p" routerLink="../register">{{ t('core.login.create_new_user') }}</a>
            </div>
          </div>
        </form>
        <div class="container-footer" [innerHTML]="t('admin.login.container_footer')"></div>
      </div>
      <div class="notifications-container" *ngIf="backendErrorMessage">
        <div class="notification is-danger">
          <button class="delete" (click)="backendErrorMessage = null;"></button>
          {{backendErrorMessage}}
        </div>
      </div>
    </div>

  </section>
</main>
