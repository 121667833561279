import { FilterNode } from '../models/filter.model';
import { User, SessionJWTTokens } from '../models/user.model';

export class SearchRequest {
  static readonly type = '[Main] Header search input bar';
  constructor(public payload: string) { }
}

export class LastSearchRequest {
  static readonly type = '[Main] Last search request';
  constructor(public payload: string) { }
}

export class ActionsMobileMenu {
  static readonly type = '[Main] Close or open mobile menu request';
  constructor(public payload: boolean) { }
}

export class InitRootFilters {
  static readonly type = '[Search Filters] Init search filters';
}

export class SelectFilter {
  static readonly type = '[Search Filters] Select filter';
  constructor(public payload: FilterNode[]) { }
}

export class InitUser {
  static readonly type = '[Main] InitUser';
  constructor(public payload: User) { }
}

export class UpdateSessionTokens {
  static readonly type = '[Main] updateSessionTokens';
  constructor(public payload: SessionJWTTokens) { }
}

export class UpdateUser {
  static readonly type = '[Main] UpdateUser';
  constructor(public payload: User) { }
}

export class Logout {
  static readonly type = '[Main] Logout';
}

export class SetAllowNavigation {
  static readonly type = '[Main] SetAllowNavigation';
  constructor(public payload: boolean) { }
}
