<main class="admin-auth-base">
  <section class="section" *transloco="let t">
    <div class="wrapper">
      <div class="auth-main-container" *ngIf="!loading">
        <div class="container-header">{{t('admin.aAccount.container_header')}}</div>
        <div class="container-footer">
          <div class="activate-message" *ngIf="success">{{t('admin.aAccount.success_message')}}</div>
          <div class="activate-message" *ngIf="!success">{{t('admin.aAccount.error_message')}}</div>
          <a class="button is-fullwidth is-primary" routerLink="/login">{{ t('admin.aAccount.back_to_login_button') }}</a>
        </div>
      </div>
      <div class="auth-main-container" *ngIf="loading">
        <div class="lds-ripple"><div></div><div></div></div>
        <div class="container-footer">{{t('admin.aAccount.loading_message')}}</div>
      </div>
    </div>
  </section>
</main>
