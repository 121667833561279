<footer *transloco="let t; read:'core.footerComponent'">
  <div class="foot">
    <div class="container">
      <div class="row">
        <div class="col-sm-2 col-xs-12">
          <img src="assets/images/logoRedList_v.png" alt="Red List of Ecosysytems" class="logofoot" />
        </div>
        <div class="col-sm-10 col-xs-12">
          <div class="menuf">
            <p><a href="https://iucnrle.org" title="{{ t('home_link') }}">{{ t('home_link') }}</a></p>
            <p><a href="https://iucnrle.org/team" title="{{ t('rle_team_link') }}">{{ t('rle_team_link') }}</a></p>
            <p><a href="https://iucnrle.org/what-is-the-rle" title="{{ t('red_list_of_ecosystems_link') }}">{{ t('red_list_of_ecosystems_link') }}</a></p>
            <p><a href="https://iucnrle.org/iucn-ext-resources" title="{{ t('links_link') }}">{{ t('links_link') }}</a></p>
            <p><a href="https://iucnrle.org/rle-glossary" title="{{ t('glossary_link') }}">{{ t('glossary_link') }}</a></p>
            <p><a href="https://iucnrle.org/terms-of-use" title="{{ t('terms_of_service') }}">{{ t('terms_of_service') }}</a></p>
          </div>
          <ul>
            <li><a href="http://www.facebook.com/IUCN-Red-List-of-Ecosystems-163207713753180/" target="_blank" rel="noopener noreferrer">
                <img src="assets/images/ICON-FACEBOOK.svg" alt="Facebook" class="iconfoot" /> Facebook </a></li>
            <li><a href="http://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=redlisteco" target="_blank" rel="noopener noreferrer">
                <img src="assets/images/ICON-TWITTER.svg" alt="Twitter" class="iconfoot" /> Twitter </a></li>
            <li><a href="http://instagram.com/redlist_of_ecosystems" target="_blank" rel="noopener noreferrer">
                <img src="assets/images/ICON-INSTAGRAM.svg" alt="Instagram" class="iconfoot" /> Instagram </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="copy">IUCN Red List of Ecosystems &copy; 2014-2020 IUCN-CEM. <span>All Rights Reserved</span></div>
  <div class="cookie-prompt" *ngIf="cookiePrompt">
    <div class="disclaimer">{{ cookieDisclaimer }}</div>
    <button class="button is-primary" (click)="acceptCookies()">{{ t('accept_cookie') }}</button>
  </div>
</footer>
