<section class="section">
  <h1 class="title">{{bannerTitle}}</h1>
  <h2 class="subtitle">{{bannerSubtitle}}</h2>
  <div class="stadistics">
    <div class="stadistic" *ngFor="let stadistic of stadistics" [routerLink]="stadistic.route">
      <div class="info">
        <div class="name">{{stadistic.name}}</div>
        <div class="percentage">{{stadistic.percentage}}</div>
      </div>
      <figure class="figure">
        <img [src]="stadistic.figureUrl">
      </figure>
    </div>
  </div>
</section>
