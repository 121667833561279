export const DEFAULT_IMG_SQUARE = 'assets/images/ImageBrokenSquare.png';
export const DEFAULT_IMG_LANDSCAPE = 'assets/images/ImageBrokenLandscape.png';

export enum GalleryType {
  CEM = 'CEM',
  MAP = 'MAP',
}

export interface Image {
  id: number;
  description: string;
  name: string;
  image_data: string;
  is_cover: boolean;
  width?: number;
  heigth?: number;
  imgtype?: GalleryType;
}
