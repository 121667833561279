<figure class="image">
  <img [appImgLoader]="currentImg" [alt]="currentImg.name" [title]="currentImg.name">
  <figcaption>{{ currentImg.description }}</figcaption>
</figure>

<div class="left arrow" (click)="cycleLeft()" *ngIf="currentIndex !== 0">
  <span class="icon is-large"><i class="fas fa-2x fa-chevron-left"></i></span>
</div>
<div class="right arrow" (click)="cycleRight()" *ngIf="currentIndex !== (gallery.length - 1)">
  <span class="icon is-large"><i class="fas fa-2x fa-chevron-right"></i></span>
</div>
