
<div class="container" *transloco="let t; read:'core.search'">
  <h1>{{t('systematic_assessments')}}</h1>
  <div class="columns is-marginless">
    <mat-tab-group class="column" animationDuration="0ms" (selectedTabChange)="tabSelected($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <span class="icon"><i class="fas fa-th"></i></span>
            <span>
              <span>{{ t('grid') }}</span>
              <span class="counter" *ngIf="!loading.results">({{searchResultsCount}})</span>
              <span class="loading-icon" *ngIf="loading.results"><i class="fas fa-spinner"></i></span>
            </span>
          </div>
        </ng-template>
        <ng-container *ngIf="!loading.results">
          <ng-container *ngIf="assessments?.length > 0">
            <div class="columns is-multiline is-marginless">
              <div class="column is-one-third-widescreen is-half-desktop is-half-tablet" *ngFor="let assessment of assessments; trackBy: trackByFn">
                <app-assessment-card [appSkeletonBox]="loading.results" SVersion="2" [assessment]="assessment"></app-assessment-card>
              </div>
            </div>
            <div class="load-more" *ngIf="searchResultsNextUrl">
              <button class="button is-primary" [ngClass]="{'is-loading': loading.loadingMore}" (click)="searchStudiesNextPage()">{{ t('load_more') }}</button>
            </div>
          </ng-container>

          <ng-container *ngIf="assessments?.length === 0">
            <div class="box empty-result">{{ t('results_empty') }}</div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="loading.results">
          <div class="columns is-multiline is-marginless">
            <div class="column is-one-third-widescreen is-half-desktop is-half-tablet" *ngFor="let skeleton of skeletons;">
              <div class="card" [appSkeletonBox]="loading.results" SVersion="2"></div>
            </div>
          </div>
        </ng-container>
      </mat-tab>


      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <span class="icon"><i class="fas fa-list"></i></span>
            <span>
              <span>{{ t('list') }}</span>
              <span class="counter" *ngIf="!loading.results">({{searchResultsCount}})</span>
              <span class="loading-icon" *ngIf="loading.results"><i class="fas fa-spinner"></i></span>
            </span>
          </div>
        </ng-template>
        <ng-container *ngIf="!loading.results">
          <ng-container *ngIf="assessments?.length > 0">
            <div class="list-container box">
              <table class="assessment-list-container">
                <thead>
                  <tr><th class="study-typology is-hidden-touch">{{t('global_typology')}}</th><th class="study-name">{{t('assessment_title')}}</th><th class="col-category">{{t('category')}}</th></tr>
                </thead>
                <tbody>
                  <tr class="assessment-list-item" *ngFor="let assessment of assessments; trackBy: trackByFn" [routerLink]="[ '/systematics', assessment.id ]">
                    <td class="study-typology is-hidden-touch">{{ assessment.global_typology_origin[0]?.name }}</td>
                    <td class="study-name">{{ assessment.name }}</td>
                    <td class="col-category">
                      <div class="cat-wrapper"><app-risk-category-switcher class="category" size="m" [assessment_type]="true"></app-risk-category-switcher></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="load-more" *ngIf="searchResultsNextUrl">
              <button class="button is-primary" [ngClass]="{'is-loading': loading.loadingMore}" (click)="searchStudiesNextPage()">{{ t('load_more') }}</button>
            </div>
          </ng-container>
          <ng-container *ngIf="assessments?.length === 0">
            <div class="box empty-result">{{ t('results_empty') }}</div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="loading.results">
          <div class="list-container box">
            <div class="list-item skeletons" *ngFor="let skeleton of skeletons;">
              <div [appSkeletonLine]="true"></div>
              <div class="loading-category is-hidden-touch"></div>
              <div class="loading-category"></div>
            </div>
          </div>
        </ng-container>
      </mat-tab>

    </mat-tab-group>
    <div class="systematic-button  is-inline-flex-desktop">
      <a routerLink="/search" class="button is-primary">{{ t('view_all') }}</a>
    </div>
  </div>
</div>
