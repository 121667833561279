import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';

import { MainState } from '../../store/main.state';
import { FilterNode, FilterGET } from '../../models/filter.model';
import { StudiesService, SearchResults } from '../../services/studies.service';
import { RestService } from '../../services/rest.service';
import { Assessment } from '../../models/assessment.model';
import { WindowRefService } from '../../services/window-ref.service';
import { getCatColor, getSystemColor } from '../../utils/functions';
import { User } from '../../models/user.model';
import { AdminDialogService, SimpleDialogType } from '../../services/adminDialog/admin-dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DownloadDialogComponent } from './download-dialog/download-dialog';
import { SelectFilter } from '../../store/main.actions';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  showMap = false;

  dialogRef: MatDialogRef<DownloadDialogComponent>;
  public Highcharts = Highcharts;
  public pie1Options: Highcharts.Options = null;
  public pie2Options: Highcharts.Options = null;
  private highchartsSwitch = false;

  @Select(MainState.user) user$: Observable<User>;
  @Select(MainState.searchString) searchString$: Observable<string>;
  @Select(MainState.rootFilters) filterNodes$: Observable<FilterNode[]>;
  @Select(MainState.selectedFilters) selectedFilters$: Observable<FilterNode[]>;
  loading = {
    results: true,
    loadingMore: false,
  };
  search: string;
  selectedFilters: FilterNode[] = [];
  filterNodes: FilterNode[] = [];
  private subscriptions: Subscription[] = [];
  public assessments: Assessment[] = [];
  public skeletons: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public searchResultsCount: number;
  public searchResultsNextUrl: string;
  user: User;
  statistic_message_en: string;
  statistic_message_es: string;
  chart_legend: string;
  sessionDropdownActive = false;
  last_search: any;
  show_download_button = false;
  pdf_fil_url = null;

  constructor(
    private studiesService: StudiesService,
    private window: WindowRefService,
    private restService: RestService,
    private translocoS: TranslocoService,
    private router: Router,
    private dialog: MatDialog,
    private dialogService: AdminDialogService,
    private store: Store
  ) { }

  async getSettingMsg() {
    this.statistic_message_en = null;
    this.statistic_message_es = null;
    this.chart_legend = null;
    const settingData = await this.restService.getSettings();
    const statistic_msg = settingData ? settingData?.[0]?.statistic_message : null;
    const statisticData = statistic_msg ? JSON.parse(statistic_msg) : [];
    localStorage.setItem('pdf_file_url', settingData?.[0]?.pdf_file);
    if (statisticData){
      statisticData.forEach(row =>{
        if (row){
          if (row && row.lang === 'en'){
            this.statistic_message_en = row.content;
          }
          if (row && row.lang === 'es'){
            this.statistic_message_es = row.content;
          }
        }
      });
    }
    const switchLang = window.navigator.language.slice(0, 2);
    if (switchLang === 'es'){
      this.chart_legend = this.statistic_message_es;
    }else{
      this.chart_legend = this.statistic_message_en;
    }
  }


  ngOnInit() {
    this.user$.subscribe((user: User) => this.user = user);
    const searchObservable$ = this.searchString$.subscribe((payload) => {
      this.search = payload;
      this.searchStudies(this.selectedFilters, this.search);
    });
    this.selectedFilters$.subscribe(selectedFiltes => {
      this.selectedFilters = this.flatArray(selectedFiltes);
      this.searchStudies(this.selectedFilters, this.search);
    })
    const rootFiltersObservable$ = this.filterNodes$.subscribe((payload) => {
      this.filterNodes = JSON.parse(JSON.stringify(payload));
    });
    const StudiesDelayedObservable$ = this.studiesService.searchStudiesResultsDelayed().subscribe(
      (searchResults) => {
        this.searchResultsCount = searchResults.search.count;
        this.searchResultsNextUrl = searchResults.search.next;
        this.renderChart(searchResults.charts);
        this.assessments = searchResults.search.results;
        this.show_download_button = (this.last_search !== undefined && Object.keys(this.last_search).length > 0)
          && this.assessments.length > 0;
        this.loading.results = false;
      }
    );
    this.subscriptions.push(searchObservable$, rootFiltersObservable$, StudiesDelayedObservable$);

    this.searchStudies(this.selectedFilters, this.search);
    this.getSettingMsg();
    this.subscriptions.push(
      this.translocoS.langChanges$.subscribe(lang => {
        if (lang === 'es') {
          this.chart_legend = this.statistic_message_es;
          return;
        }
        this.chart_legend = this.statistic_message_en;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  filterSelection(selectedFilters: FilterNode[]) {

    this.store.dispatch(new SelectFilter(JSON.parse(JSON.stringify(this.filterNodes))));

  }

  tabSelected(event: MatTabChangeEvent) {
    if (event.index === 2) {
      this.showMap = true;
    } else {
      this.showMap = false;
    }

    // index 3 at this moment is the index of the hightchart tab
    if (!this.highchartsSwitch && event.index === 3) {
      // This is a hack that force highcharts component to adjust its width
      // The first time hightchart renders its view doesn't know the width of its parents because the mat tab is not active
      // and not being active means that is not present in the DOM a that moment.
      this.window.nativeWindow.dispatchEvent(new Event('resize'));
      // only trigger the event onces
      this.highchartsSwitch = true;
    }
  }

  // TODO: add interface to chartData endpoint
  // TODO: add translation to labels
  private renderChart(chartData: any) {
    this.pie1Options = null;
    this.pie2Options = null;
    const parseName = (text: string) => text.split(' ')[0];
    const { categ_data, system_data } = chartData;
    const pie1 = categ_data.map((e) => ({
      ...e,
      y: parseFloat(e.y),
      color: getCatColor(parseName(e.name))
    }));
    const pie2 = system_data.map((e) => ({
      ...e,
      y: parseFloat(e.y),
      color: getSystemColor(e.name)
    }));
    const plotOptions: Highcharts.PlotOptions = {
      pie: {
        showInLegend: true,
        point: {
          events: {
            legendItemClick() { return false; }
          }
        }
      }
    };
    this.pie1Options = {
      title: { text: 'Red List of Ecosystems category' },
      series: [{ name: '%', data: pie1, type: 'pie', }],
      plotOptions,
      credits: { enabled: false },
      responsive: {
        rules: [{
          chartOptions: {
            series: [{ name: '%', type: 'pie', data: pie1, dataLabels: {
              formatter() { return parseName(this.point.name); }
            }}],
            plotOptions,
            chart: { height: 550 },
            legend: { layout: 'vertical' }
          },
          condition: { callback: () => this.window.nativeWindow.innerWidth < 768 }
        }, {
          // this rule is only when transitioning from < 768 back to >= 768
          // the highcharts gives a runtime error complaining about formatter not being defined???
          chartOptions: {
            series: [{ name: '%', type: 'pie', data: pie1, dataLabels: {
              formatter() { return this.point.name; }
            }}],
            plotOptions,
          },
          condition: { callback: () => this.window.nativeWindow.innerWidth >= 768 }
        }]
      }
    };
    this.pie2Options = {
      title: { text: 'Systems' },
      series: [{ name: '%', data: pie2, type: 'pie', }],
      plotOptions,
      credits: { enabled: false },
      responsive: {
        rules: [{
          chartOptions: {
            plotOptions, chart: { height: 550 }, legend: { layout: 'vertical' }
          },
          condition: { callback: () => this.window.nativeWindow.innerWidth < 768 }
        }]
      }
    };
  }

  private searchStudies(selectedFilters?: FilterNode[], searchPattern?: string) {
    // at this point we would like to set the charts in loading state by cleaning the pieOptions variables
    this.pie1Options = null;
    this.pie2Options = null;

    const mapSelectedFilters = (filters: FilterNode[]) => {
      const getParams = {};
      filters.forEach(el => {
        if (!getParams.hasOwnProperty(el.rootId)) {
          getParams[el.rootId] = [];
        }
      });
      filters.forEach(filter => getParams[filter.rootId].push(filter.id));
      return getParams;
    };

    this.loading.results = true;
    let searchParams: FilterGET = {};
    // selectedFilters could be an empty array which is still intended behaviour
    if (selectedFilters) {
      searchParams = mapSelectedFilters(selectedFilters);
    }
    if (searchPattern) {
      searchParams.name = [searchPattern];
    }

    if (selectedFilters || searchPattern) {
      this.last_search = searchParams;
      this.studiesService.searchStudies(searchParams);
    }
    if (!selectedFilters && !searchPattern) {
      this.last_search = undefined;
      this.subscriptions.push(this.studiesService.searchStudiesResults().subscribe(
        (searchResults) => {
          this.assessments = searchResults.search.results;
          this.loading.results = false;
        }
      ));
    }
  }

  public searchStudiesNextPage() {
    if (this.searchResultsNextUrl) {
      this.loading.loadingMore = true;
      this.studiesService.searchStudiesNextPage(this.searchResultsNextUrl).then(
        (nextPageResults) => {
          this.searchResultsNextUrl = nextPageResults.search.next;
          this.assessments = this.assessments.concat(nextPageResults.search.results);
          this.loading.loadingMore = false;
        },
        () => { this.loading.loadingMore = false; }
      );
    }
  }

  public trackByFn(index: number, item: Assessment) { return item.id; }

  async downloadCSV() {
    if (!this.user) {
      const message = this.translocoS.translate('core.search.confirm_login_register');
      const confirmation = await this.dialogService.confirmLogin(message).afterClosed().toPromise();
      if (!confirmation) { return; }
      this.router.navigate(['/login']);
      return;
    }
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      panelClass: 'simple-dialog-root',
    };
    const dialogRef = this.dialog.open(DownloadDialogComponent, { ...dialogConfig, data: { last_search: this.last_search } });
    dialogRef.afterClosed().subscribe(async (downloaded) => {
      if (!downloaded) {
        return;
      }
      const message = this.translocoS.translate('core.search.download_success_message');
      const title = this.translocoS.translate('core.search.download_success_title');
      const confirmation = await this.dialogService.showSimpleDialog({
        message,
        title,
        type: SimpleDialogType.DownloadSucess
      }, dialogConfig).afterClosed().toPromise();
      if (confirmation) {
        this.router.navigate(['/profile']);
      }
    });
  }

  private flatArray(nodes: FilterNode[]) {
    const result: FilterNode[] = [];

    const reducer = (node: FilterNode, rootFilterId?: number | string) => {
      if (!node) {
        return;
      }
      if (node.selected) {
        result.push({
          id: node.id,
          rootId: rootFilterId,
        });
        return;
      }
      if (node.child_ids) {
        node.child_ids.forEach(child => reducer(child, rootFilterId));
      }
    };
    nodes.forEach(node => {
      // in this loop we are dealing directly with the root filters
      // we can carry the filter node rootId, to latter map it into something usefull
      reducer(node, node.id);
    });
    return result;
  }

}
