<div class="box" *transloco="let t; read:'core.filtersMenuComponent'">
  <ng-container *ngIf="nodes?.length > 0; else elseTemplate">
    <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('redcategory')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    <div class="filter-group">
      <!-- TODO: implement this label to be translated with transloco t function -->
      <span class="filter-label">Risk assessment information</span>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('scope')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('assesstype')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    </div>
    <div class="filter-group">
      <!-- TODO: implement this label to be translated with transloco t function -->
      <span class="filter-label">Types of ecosystems</span>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('globaltypology')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('habitat')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    </div>
    <div class="filter-group">
      <!-- TODO: implement this label to be translated with transloco t function -->
      <span class="filter-label">Distribution</span>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('biogeographicrealm')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('region')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    </div>
    <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('threats')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('ecosystemtrend')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('conservationaction')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    <div class="filter-group">
      <!-- TODO: implement this label to be translated with transloco t function -->
      <span class="filter-label">Risk assessment protocol</span>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('riskprotocolo')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
      <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('ecocriteriaassessed')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>
    </div>
    <app-filters-menu-item (selection)="filterChange()" [node]="locateFilter('referenceyear')" [base]="true" [clean]="cleanEvent"></app-filters-menu-item>

    <button class="button is-uppercase" *ngIf="filterResult.length > 0" (click)="clearFilters()">{{ t('clear_filters') }}</button>
  </ng-container>
  <ng-template #elseTemplate>
    <div [appSkeletonLine]="true" [SWidth]="width" *ngFor="let width of skeletons"></div>
  </ng-template>
</div>
