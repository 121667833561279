import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication.service';
import { User, Group } from '../../models/user.model';
import { UsersService } from '../../services/users.service';
import { AdminDialogService } from '../../services/adminDialog/admin-dialog.service';
import { Store } from '@ngxs/store';
import { SetAllowNavigation, UpdateSessionTokens, Logout, UpdateUser } from '../../store/main.actions';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss']
})
export class UsersDetailComponent implements OnInit {
  userDetailForm: FormGroup;
  submitting = false;
  loading = false;
  editing: string | boolean; // evaluated as string to be truthy
  userId: string;
  userLoginId: string | number;
  user: User;
  usrGroups: Group[] = [];
  userGroups: Group[] = [];
  groups: Group[] = [];
  userGroup: string | number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private adminDialog: AdminDialogService,
    private store: Store,
    private translocoS: TranslocoService
  ) { }

  private initUserForm(user: User, editing: string | boolean) {
    // const assignedGroups = this.userGroups.map(group => group.id);
    this.userDetailForm = this.fb.group({
      email: [user.email, { validators: [Validators.required] }],
      first_name: [user.first_name, { validators: [Validators.required] }],
      last_name: [user.last_name, { validators: [Validators.required] }]
    });
    this.userDetailForm.get('email').disable();
    this.setEditingState(editing);
  };

  async ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.editing = this.route.snapshot.paramMap.get('editing');
    try {
      this.loading = true;
      this.user = await this.usersService.getUser(this.userId);

      const userInfo = await this.authService.whoAmI();
      this.userLoginId = userInfo.id;
      this.initUserForm(this.user, this.editing);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      // this.adminDialog.failure();
      switch (error.status) {
        case 401:
          this.store.dispatch(new Logout());
          break;
        case 404:
          this.store.dispatch(new Logout());
          break;
        default:
          break;
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.userDetailForm.controls; }

  setEditingState(state: string | boolean) {
    this.editing = !!state;
    if (state) {
      this.userDetailForm.get('first_name').enable();
      this.userDetailForm.get('last_name').enable();
    } else {
      this.userDetailForm.get('first_name').disable();
      this.userDetailForm.get('last_name').disable();
    }
  }

  public async saveChanges() {
    if (this.userDetailForm.disabled || this.userDetailForm.invalid || !this.userId) {
      console.error('invalid form');
      return;
    }

    const userData = {
      first_name: this.userDetailForm.value.first_name,
      last_name: this.userDetailForm.value.last_name,
    };
    try {
      this.submitting = true;
      this.userDetailForm.disable();
      this.store.dispatch(new SetAllowNavigation(false));
      await this.usersService.patchUser(this.userId, userData);
      this.store.dispatch(new SetAllowNavigation(true));
      const userInfo = await this.authService.whoAmI();
      this.store.dispatch(new UpdateUser(userInfo));
      this.userDetailForm.enable();
      this.submitting = false;
      // this.initUserForm(updatedUser, true);
      this.adminDialog.success(this.translocoS.translate('admin.usersDetail.update_user_success'));

      if (this.userLoginId === Number(this.userId)){
        // this.router.navigate([success_path]);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      this.submitting = false;
      this.userDetailForm.enable();
      this.store.dispatch(new SetAllowNavigation(true));
      // TODO: implement proper error handling messages
      this.adminDialog.failure();
    }
  }

  public async resendActivationEmail() {
    try {
      this.submitting = true;
      this.userDetailForm.disable();
      this.store.dispatch(new SetAllowNavigation(false));
      await this.usersService.resentActivationEmail(this.user.email);
      this.store.dispatch(new SetAllowNavigation(true));
      this.userDetailForm.enable();
      this.submitting = false;
      this.adminDialog.success(this.translocoS.translate('admin.usersDetail.send_user_activation_success'));
    } catch (error) {
      this.userDetailForm.enable();
      this.submitting = false;
      this.store.dispatch(new SetAllowNavigation(true));
      // TODO: implement proper error handling messages
      this.adminDialog.failure();
    }
  }

}
