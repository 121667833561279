import { Injectable, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';

export enum SimpleDialogType {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Warning ='WARNING',
  Info ='INFO',
  Confirm ='CONFIRM',
  Loading = 'LOADING',
  DownloadSucess = 'DOWNLOAD-SUCCESS',
  Login = 'LOGIN'
};

interface SimpleDialogData {
  message?: string;
  title?: string;
  type: SimpleDialogType;
}

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.html',
  styleUrls: ['./simple-dialog.scss'],
})
export class SimpleDialogComponent {
  constructor(
    private translocoS: TranslocoService,
    public dialogRef: MatDialogRef<SimpleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData
  ) {}
  types = SimpleDialogType;
  okLabel = this.translocoS.translate('global.formActions.ok');
  onNoClick(): void { this.dialogRef.close(); }
}

@Injectable()
export class AdminDialogService {
  dialogRef: MatDialogRef<SimpleDialogComponent>;
  constructor(
    private dialog: MatDialog,
    private translocoS: TranslocoService,
  ) { }
  showSimpleDialog(data: SimpleDialogData, matDialogConfig: MatDialogConfig) {
    const dialogConfig: MatDialogConfig = {
      ...matDialogConfig,
      width: '350px',
      panelClass: 'simple-dialog-root',
    };
    return this.dialog.open(SimpleDialogComponent, { ...dialogConfig, data });
  }

  success(message: string, title?: string, matDialogConfig?: MatDialogConfig) {
    title = title ? title : this.translocoS.translate('global.dialogTitles.success');
    return this.showSimpleDialog({ message, title, type: SimpleDialogType.Success }, matDialogConfig);
  }
  failure(message?: string, title?: string, matDialogConfig?: MatDialogConfig) {
    title = title ? title : this.translocoS.translate('global.dialogTitles.failure');
    message = message ? message : this.translocoS.translate('global.errors.simple_error_1');
    return this.showSimpleDialog({ message, title, type: SimpleDialogType.Failure }, matDialogConfig);
  }
  warning(message: string, title?: string, matDialogConfig?: MatDialogConfig) {
    title = title ? title : this.translocoS.translate('global.dialogTitles.warning');
    return this.showSimpleDialog({ message, title, type: SimpleDialogType.Warning }, matDialogConfig);
  }
  info(message: string, title?: string, matDialogConfig?: MatDialogConfig) {
    title = title ? title : this.translocoS.translate('global.dialogTitles.info');
    return this.showSimpleDialog({ message, title, type: SimpleDialogType.Info }, matDialogConfig);
  }
  confirm(message: string, title?: string, matDialogConfig?: MatDialogConfig) {
    title = title ? title : this.translocoS.translate('global.dialogTitles.confirm');
    matDialogConfig = {
      ...matDialogConfig,
      width: '500px'
    }
    return this.showSimpleDialog({ message, title, type: SimpleDialogType.Confirm }, matDialogConfig);
  }
  confirmLogin(message: string, title?: string, matDialogConfig?: MatDialogConfig) {
    title = title ? title : this.translocoS.translate('global.dialogTitles.login');
    matDialogConfig = {
      ...matDialogConfig,
      width: '500px'
    }
    return this.showSimpleDialog({ message, title, type: SimpleDialogType.Login }, matDialogConfig);
  }
  loading(loadingState: boolean, matDialogConfig?: MatDialogConfig) {
    if (typeof loadingState !== 'boolean') { console.error('expected loadingState to be provided'); return; }
    if (loadingState) {
      matDialogConfig = {
        ...matDialogConfig,
        disableClose: true,
      };
      this.dialogRef = this.showSimpleDialog({ type: SimpleDialogType.Loading }, matDialogConfig);
    } else {
      this.dialogRef.close();
    }
  }
}
