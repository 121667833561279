import { Component } from '@angular/core';
import { inlineWorldMap } from './worldMap.svg';

@Component({
  selector: 'app-skeleton-svg-map',
  template: inlineWorldMap,
  styleUrls: ['./skeleton-map.component.scss']
})
export class SkeletonSVGMapComponent {
  public worldMap = inlineWorldMap;
}
