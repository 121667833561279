<div class="simple-dialog" *transloco="let t;">
  <ng-container>
    <div class="dialog-header" mat-dialog-title>
      <h1 class="dialog-title has-text-centered">{{ t('core.search.confirm_title') }}</h1>
    </div>
    <div class="dialog-content" mat-dialog-content>
      <p>{{ t('core.search.confirm_subtitle') }}</p>
      <br>
      <form [formGroup]="downloadsForm" (ngSubmit)="onSubmit()">
        <div class="field">
          <label class="label required">{{ t('core.search.research_label') }}</label>
          <div class="button is-white terms-field">
            <input  id="ecosystemRisk" type="checkbox" (change)="usesOptions.ecosystem_risk_assessment = !usesOptions.ecosystem_risk_assessment;" class="is-checkradio" formControlName="academicResearch">
            <label for="ecosystemRisk">
              <span class="desc" [ngClass]="{'expanded': usesOptions.ecosystem_risk_assessment}" title="{{ t('core.search.ecosystem_risk_assessment') }}">{{ t('core.search.ecosystem_risk_assessment') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.ecosystem_risk_assessment = !usesOptions.ecosystem_risk_assessment">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.ecosystem_risk_assessment}"></i>
              </span>
            </div>
          </div>
          <div class="button is-white terms-field">
            <input  id="academicResearch" type="checkbox" (change)="usesOptions.academic_research_or_educational_use = !usesOptions.academic_research_or_educational_use;" class="is-checkradio" formControlName="academicResearch">
            <label for="academicResearch">
              <span class="desc" [ngClass]="{'expanded': usesOptions.academic_research_or_educational_use}" title="{{ t('core.search.academic_research_or_educational_use') }}">{{ t('core.search.academic_research_or_educational_use') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.academic_research_or_educational_use = !usesOptions.academic_research_or_educational_use">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.academic_research_or_educational_use}"></i>
              </span>
            </div>
          </div>
          <div class="button is-white terms-field">
            <input  id="biodiversityManagement" type="checkbox" (change)="usesOptions.biodiversity_management = !usesOptions.biodiversity_management;" class="is-checkradio" formControlName="academicResearch">
            <label for="biodiversityManagement">
              <span class="desc" [ngClass]="{'expanded': usesOptions.biodiversity_management}" title="{{ t('core.search.biodiversity_management') }}">{{ t('core.search.biodiversity_management') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.biodiversity_management = !usesOptions.biodiversity_management">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.biodiversity_management}"></i>
              </span>
            </div>
          </div>
          <div class="button is-white terms-field">
            <input  id="environmentalReporting" type="checkbox" (change)="usesOptions.environmental_reporting = !usesOptions.environmental_reporting;" class="is-checkradio" formControlName="academicResearch">
            <label for="environmentalReporting">
              <span class="desc" [ngClass]="{'expanded': usesOptions.environmental_reporting}" title="{{ t('core.search.environmental_reporting') }}">{{ t('core.search.environmental_reporting') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.environmental_reporting = !usesOptions.environmental_reporting">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.environmental_reporting}"></i>
              </span>
            </div>
          </div>
          <div class="button is-white terms-field">
            <input  id="policyMaking" type="checkbox" (change)="usesOptions.policy_making = !usesOptions.policy_making;" class="is-checkradio" formControlName="academicResearch">
            <label for="policyMaking">
              <span class="desc" [ngClass]="{'expanded': usesOptions.policy_making}" title="{{ t('core.search.policy_making') }}">{{ t('core.search.policy_making') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.policy_making = !usesOptions.policy_making">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.policy_making}"></i>
              </span>
            </div>
          </div>
          <div class="button is-white terms-field">
            <input  id="economicPlanning" type="checkbox" (change)="usesOptions.economic_planning = !usesOptions.economic_planning;" class="is-checkradio" formControlName="academicResearch">
            <label for="economicPlanning">
              <span class="desc" [ngClass]="{'expanded': usesOptions.economic_planning}" title="{{ t('core.search.economic_planning') }}">{{ t('core.search.economic_planning') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.economic_planning = !usesOptions.economic_planning">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.economic_planning}"></i>
              </span>
            </div>
          </div>
          <div class="button is-white terms-field">
            <input  id="others" type="checkbox" (change)="usesOptions.other = !usesOptions.other;" class="is-checkradio" formControlName="academicResearch">
            <label for="others">
              <span class="desc" [ngClass]="{'expanded': usesOptions.other}" title="{{ t('core.search.other') }}">{{ t('core.search.other') }}</span>
            </label>
            <div class="icon-container" (click)="usesOptions.other = !usesOptions.other;">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': usesOptions.other}"></i>
              </span>
            </div>
          </div>
          <p class="help is-danger" *ngIf="f.academicResearch.dirty">
            <span *ngIf="f.academicResearch.errors?.required">{{ t('global.validation.required_field') }}</span>
          </p>
        </div>
        <div class="field">
          <label class="label required">{{ t('core.search.description_label') }}</label>
          <p>{{ t('core.search.description_help') }}</p>
          <div class="control">
            <textarea class="textarea" [ngClass]="{'is-danger': f.description.dirty && f.description.errors }" formControlName="description">

            </textarea>
          </div>
          <p class="help" >
            <span>{{ t('global.validation.min_length_30') }}</span>
          </p>
          <p class="help is-danger" *ngIf="f.description.dirty">
            <span *ngIf="f.description.errors?.required">{{ t('global.validation.required_field') }}</span>
          </p>
        </div>
        <div class="field">
          <label class="label required">{{ t('core.search.terms_label') }}</label>
          <div class="button is-white terms-field final">
            <input  id="termsID" type="checkbox" class="is-checkradio" [ngClass]="{'is-danger': f.terms.dirty && f.terms.errors}" formControlName="terms">
            <label for="termsID">
              <span class="desc" [ngClass]="{'expanded': active}" title="{{ t('core.search.terms_label') }}"><a [href]="pdf_file_url" target="_blank">{{ t('core.search.terms_help_1') }}</a> {{ t('core.search.terms_help_2') }}</span>
            </label>
            <div class="icon-container" (click)="toggle()">
              <span class="icon">
                <i class="caretRight" [ngClass]="{ 'expanded': active}"></i>
              </span>
            </div>
          </div>
          <p class="help is-danger" *ngIf="f.terms.dirty">
            <span *ngIf="f.terms.errors?.required">{{ t('global.validation.required_field') }}</span>
          </p>
        </div>
      </form>
      <br>
      <p><strong>{{ t('core.search.pre_auth_use_message') }}</strong></p>
    </div>
    <div class="dialog-actions">
      <div class="buttons is-centered">
        <button class="button is-light" mat-dialog-close>{{ t('global.formActions.close') }}</button>
        <button (click)="onSubmit()" [disabled]="downloadsForm.invalid || submitting" class="button is-primary">{{ t('global.formActions.finish') }}</button>
      </div>
    </div>
  </ng-container>
</div>
