import { Component, OnInit } from '@angular/core';

import { Stadistic } from '../../models/stadistic.model';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  stadistics: Stadistic[] = [];
  sections;

  constructor(
    private restService: RestService
  ) { }

  ngOnInit() {
    // TODO: implement real data
    // init stadistic dummy content

    this.restService.loadSections().subscribe(
      sections =>{
        this.sections = sections;
        // console.log(sections);
      }
    );

    const initIMG = () => {
      const random = Math.floor(Math.random() * Math.floor(10));
      return random >= 5 ?
        'https://nrl.iucnredlist.org/assets/icons/taxonomy-amphibians-9031ab78070d1b80a7537ec8baa788814a129d149db7702f76bcf38bb34a4601.svg' :
        'https://nrl.iucnredlist.org/assets/icons/taxonomy-sharks-8417182ca2e61aedc243ce946e10edc4da0c07427fc5fe3b64026c2ea407dd24.svg';
    };
    for (let i = 0; i < 6; i++) {
      this.stadistics.push({
        name: `name ${i}`,
        percentage: 10 + 10 * i,
        figureUrl: initIMG(),
        route: `/assessments/${i}`
      });
    }
  }

}
