<div class="unique" [ngClass]="{ 'base': base, 'expanded': active }">
  <ng-container *ngIf="base; else notBase">
    <a class="button is-white" (click)="toggle()">
      <h3 [ngClass]="{ 'expanded': active, 'hasChildSelected': node.hasChildSelected}" title="{{ node.name }}">{{ node.name }}</h3>
      <span class="icon" *ngIf="node.child_ids">
        <i class="caretRight" [ngClass]="{ 'expanded': active}"></i>
      </span>
    </a>
  </ng-container>
  <ng-template #notBase>
    <div class="button is-white">
      <input class="is-checkradio" [indeterminate]="node.hasChildSelected" (change)="onSelection()" [(ngModel)]="node.selected" [id]="psudoUUID" type="checkbox">
      <label [for]="psudoUUID">
        <span class="desc" [ngClass]="{'expanded': active}" title="{{ node.name }}">{{ node.name }}</span>
      </label>
      <div class="icon-container" *ngIf="node.child_ids?.length > 0" (click)="toggle()">
        <span class="icon">
          <i class="caretRight" [ngClass]="{ 'expanded': active}"></i>
        </span>
      </div>
    </div>
  </ng-template>

  <div class="child-content" [ngClass]="{ 'collapsed': !active, 'expanded': active }">
    <ng-container *ngIf="node.child_ids?.length > 0 && active">
      <app-filters-menu-item (selection)="passToParent()" *ngFor="let child of node.child_ids" [node]="child"></app-filters-menu-item>
    </ng-container>
  </div>
</div>
