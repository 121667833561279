import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { RestService } from '../../services/rest.service';

const LOCALSTORAGE_COOKIE_ACCEPTED = 'RLE_COOKIE_ACCEPT';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  cookiePrompt = false;
  cookiePrompt_disclaimer_en: string;
  cookiePrompt_disclaimer_es: string;
  cookieDisclaimer: string;
  langChangeSubscription: Subscription;

  constructor(
    private translocoS: TranslocoService,
    private restService: RestService
  ) { }

  async ngOnInit() {
    this.cookiePrompt_disclaimer_en = null;
    this.cookiePrompt_disclaimer_es = null;
    this.cookieDisclaimer = null;
    const settingData = await this.restService.getSettings();
    const cookie_disclaimer = settingData ? settingData?.[0]?.cookie_prompt_disclaimer : null;
    const cookieData = cookie_disclaimer ? JSON.parse(cookie_disclaimer) : [];
    if (cookieData){
      cookieData.forEach(row =>{
        if (row){
          if (row && row.lang === 'en'){
            this.cookiePrompt_disclaimer_en = row.content;
          }
          if (row && row.lang === 'es'){
            this.cookiePrompt_disclaimer_es = row.content;
          }
        }
      });
    }
    const switchLang = window.navigator.language.slice(0, 2);
    if (switchLang === 'es'){
      this.cookieDisclaimer = this.cookiePrompt_disclaimer_es;
    }else{
      this.cookieDisclaimer = this.cookiePrompt_disclaimer_en;
    }

    const cookie = JSON.parse(localStorage.getItem(LOCALSTORAGE_COOKIE_ACCEPTED));
    // cookie should be only a boolean true or false, after being parsed
    this.cookiePrompt = cookie ? false : true;

    this.langChangeSubscription = this.translocoS.langChanges$.subscribe(lang => {
      if (lang === 'es') {
        this.cookieDisclaimer = this.cookiePrompt_disclaimer_es;
        return;
      }
      this.cookieDisclaimer = this.cookiePrompt_disclaimer_en;
    });
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  acceptCookies() {
    localStorage.setItem(LOCALSTORAGE_COOKIE_ACCEPTED, JSON.stringify(true));
    this.cookiePrompt = false;
  }

}
