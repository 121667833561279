<section class="wrapper" [ngClass]="{
  'dark':item.main_theme ==='dark',
  'light':item.main_theme ==='light',
  'main':item.main_theme ==='main'
  }" [ngStyle]="getStyles(item.custom_styles)">
  <div class="container">
    <h1 class="title">{{item.title}}</h1>
    <h2 class="subtitle">{{item.subtitle}}</h2>
    <div class="columns">
      <!-- First module -->
      <div [ngClass]="{
          'column':true,
          'is-full':item.layout ==='1',
          'is-two-thirds':item.layout ==='2',
          'is-one-third':item.layout ===3
        }">
        <h1 class="title">{{item.first_title}}</h1>
        <h2 class="subtitle">{{item.first_subtitle}}</h2>
        <div class='card equal-height' *ngIf="item.first_image_url">
          <div class='card-content is-flex is-horizontal-center'>
            <figure class='image'>
              <img class="column_image" [src]="item.first_image_url" [ngStyle]="{
          'width': item.first_image_width + 'px',
          'height': item.first_image_height + 'px'
        }">
            </figure>
          </div>
        </div>
        <p class="image_caption" *ngIf="item.first_image_footer" [innerHTML]="item.first_image_footer"></p>
        <div class="content" *ngIf="item.first_content" [innerHTML]="item.first_content"></div>

        <div [ngClass]="{
          'stadistics':true,
          'is-vertical':item.first_bricks_layout==='is-vertical'
        }">
          <div [ngClass]="{'stadistic':true}" *ngFor="let brick of item.first_bricks">
            <div class="">
              <div class="name">{{brick.title}}</div>
              <div class="percentage">{{brick.number}}</div>
            </div>
            <figure *ngIf="brick.icon" class="figure">
              <i class="{{brick.icon}}"></i>

            </figure>
          </div>
        </div>
        <a class="cta cta--primary" *ngIf="item.first_cta_link" href="{{item.first_cta_link}}"
          target="{{item.first_cta_action}}">{{item.first_cta_text}}</a>
      </div>
      <!-- Second module -->
      <div *ngIf="item.layout !=='1'" [ngClass]="{
        'column':true,
        'is-two-thirds':item.layout ==='3',
        'is-one-third':item.layout ==='2'
      }">
        <h1 class="title">{{item.second_title}}</h1>
        <h2 class="subtitle">{{item.second_subtitle}}</h2>

        <div class='card equal-height' *ngIf="item.second_image_url">
          <div class='card-content is-flex is-horizontal-center'>
            <figure class='image'>
              <img class="column_image" [src]="item.second_image_url" [ngStyle]="{
        'width': item.second_image_width + 'px',
        'height': item.second_image_height + 'px'
      }">
            </figure>
          </div>
        </div>
        <p class="image_caption" *ngIf="item.second_image_footer" [innerHTML]="item.second_image_footer"></p>
        <div class="content" *ngIf="item.second_content" [innerHTML]="item.second_content"></div>
        <div [ngClass]="{
          'stadistics':true,
          'is-vertical':item.second_bricks_layout==='is-vertical'
        }">
          <div [ngClass]="{'stadistic':true}" *ngFor="let brick of item.second_bricks">
            <div class="">
              <div class="name">{{brick.title}}</div>
              <div class="percentage">{{brick.number}}</div>
            </div>
            <figure *ngIf="item.icon" class="figure">
              <img [src]="brick.icon">
            </figure>
          </div>
        </div>
        <a class="cta cta--primary" *ngIf="item.second_cta_link" href="{{item.second_cta_link}}"
          target="{{item.second_cta_action}}">{{item.second_cta_text}}</a>
      </div>

      <!-- Third module -->
      <div *ngIf="item.layout ==='4'" class="column is-one-third">
        <h1 class="title">{{item.third_title}}</h1>
        <h2 class="subtitle">{{item.third_subtitle}}</h2>

        <div class='card equal-height' *ngIf="item.third_image_url">
          <div class='card-content is-flex is-horizontal-center'>
            <figure class='image'>
              <img class="column_image" [src]="item.third_image_url" [ngStyle]="{
            'width': item.third_image_url_width + 'px',
            'height': item.third_image_url_height + 'px'
          }">
            </figure>
          </div>
        </div>
        <p class="image_caption" *ngIf="item.third_image_footer">{{item.third_image_footer}}</p>
        <div class="content" *ngIf="item.third_content" [innerHTML]="item.third_content"></div>
        <div [ngClass]="{
            'stadistics':true,
            'is-vertical':item.third_bricks_layout==='is-vertical'}">
          <div [ngClass]="{'stadistic':true}" *ngFor="let brick of item.third_bricks">
            <div class="">
              <div class="name">{{brick.title}}</div>
              <div class="percentage">{{brick.number}}</div>
            </div>
            <figure *ngIf="item.icon" class="figure">
              <img [src]="brick.icon">
            </figure>
          </div>
        </div>
        <a class="cta cta--primary" *ngIf="item.third_cta_link" href="{{item.third_cta_link}}"
          target="{{item.third_cta_action}}">{{item.third_cta_text}}</a>
      </div>
    </div>
  </div>
</section>