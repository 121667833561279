import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { FilterNode } from '../models/filter.model';
const API = environment.APIBASE;

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private searchFilters: FilterNode[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  getsearchFilters() {
    return this.http.get<FilterNode[]>(API + 'cases/studies/advancedsearchsections/').pipe(
      map((rootFilters) => {
        const filters = rootFilters.map((filter: FilterNode) => {
          return {
            id: filter.param,
            code: filter.code,
            order: filter.order,
            name: filter.name,
            child_ids: filter.content
          };
        });
        return filters.sort((filter) => filter.order - filter.order);
      })
    );
  }

  getRedListCategoryFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/overallcategories/');
  }
  getScopeFilters() {
    return this.http.get<FilterNode>(API + 'cases/scopeclassificationelements/filtersections/');
  }
  getConservationFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/conservationclassificationelements/filtersections/');
  }
  getThreadFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/threatclassificationelements/filtersections/');
  }
  getRegionFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/regionclassificationelements/filtersections/');
  }
  getResearchFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/researchneedclassificationelements/filtersections/');
  }
  getHabitatsFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/habitatclassificationelements/filtersections/?class=habitat');
  }
  getTypologyFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/habitatclassificationelements/filtersections/?class=typology');
  }
  getOtherFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/habitatclassificationelements/filtersections/?class=other');
  }
  getCaseFilters() {
    return this.http.get<FilterNode>(API + 'ops/studies/filtersections/');
  }
  getBiogeographicRealmFilters() {
    return this.http.get<FilterNode>(API + 'locations/biogeographics/');
  }
  getGlobalTypologyFilters() {
    return this.http.get<FilterNode>(API + 'ecosystems/habitatclassificationelements/');
  }
  getUserFilters() {
    return this.http.get<FilterNode>(API + 'users/filtersections/');
  }
  // TODO: add the rest just in case
}
