import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Angular Material Imports
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

// CONTAINERS
import { MainComponent } from './containers/main/main.component';
import { AppComponent } from './containers/core/app.component';
import { ShellComponent } from './containers/shell/shell.component';
import { NotFoundComponent } from './containers/not-found/not-found.component';
import { SearchComponent } from './containers/search/search.component';
import { AssessmentComponent } from './containers/assessment/assessment.component';
import { AssessmentSystematicComponent } from './containers/assessment-systematic/assessment-systematic.component';

// PIPES
import { CategorizerPipe } from './pipes/categorizer/categorizer.pipe';
import { ValuesPipe } from './pipes/valueUnits/valueUnits.pipe';
import { RemoveNumberPipe } from './pipes/removeNumber/removeNumber.pipe';
import { TrueFalsePipe  } from './pipes/trueFalseSwitcher/truefalse.pipe';
import { FrmYearPipe  } from './pipes/frmYear/frmYear.pipe';

// ENV
import { environment } from '../../environments/environment';

// MODULES
import { TranslocoRootModule } from './transloco-root.module';
import { HighchartsChartModule } from 'highcharts-angular';

// STORE
import { MainState } from './store/main.state';

// SERVICES

// Directives
import {
  SkeletonParagraphDirective,
  SkeletonLineDirective,
  SkeletonImageDirective,
  SkeletonBoxDirective,
  SkeletonSVGMapComponent,
} from './directives/skeleton';
import { ImgLoaderDirective } from './directives/img-loader.directive';

// COMPONENTS
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerComponent } from './components/banner/banner.component';
import { FiltersMenuComponent } from './components/filters-menu/filters-menu.component';
import { FiltersMenuItemComponent } from './components/filters-menu-item/filters-menu-item.component';
import { SectionComponent } from './components/section/section.component';
import { RiskCategorySwitcherComponent } from './components/risk-category-switcher/risk-category-switcher.component';
import { ImageGalleryComponent, DialogImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { AssessmentCardComponent } from './components/assessment-card/assessment-card.component';
import { ReadMoreDirective } from './directives/read-more/read-more.directive';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import { SearchSystematicComponent } from './containers/search-systematic/search-systematic.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { RegisterComponent } from './containers/register/register.component';
import { LoginComponent } from './containers/login/login.component';
import { ForgotPasswordComponent } from './containers/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { ActivateAccountComponent } from './containers/activate-account/activate-account.component';
import { AuthenticationService } from '../coreModule/services/authentication.service';
import { AdminDialogService, SimpleDialogComponent } from './services/adminDialog/admin-dialog.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UsersService } from './services/users.service';
import { ProfileComponent } from './containers/profile/profile.component';
import { UsersDetailComponent } from './containers/users-detail/users-detail.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { UpdatePasswordComponent } from './containers/update-password/update-password.component';
import { AuthGuard } from './services/auth.guard';
import { DownloadDialogComponent } from './containers/search/download-dialog/download-dialog';
import { MatTableModule } from '@angular/material/table';


// ROUTES
// TODO: remove this if conditions onces the landing page is defined
export const routes: Routes = [
  { path: '', component: ShellComponent, children: [
    { path: '', component: MainComponent },
    { path: 'search', component: SearchComponent },
    { path: 'searchsys', component: SearchSystematicComponent },
    { path: 'assessments/:id', component: AssessmentComponent },
    { path: 'assessments/:id/:prev', component: AssessmentComponent },
    { path: 'systematics/:id', component: AssessmentSystematicComponent },
    { path: 'systematics/:id/:prev', component: AssessmentSystematicComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: 'update-profile/:id', component: UsersDetailComponent, canActivate: [AuthGuard] },
    { path: 'update-password', component: UpdatePasswordComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'activate-account', component: ActivateAccountComponent },
    { path: 'not-found', component: NotFoundComponent },
  ] },
  {
    path: 'admin',
    loadChildren: () => import('../adminModule/admin.module').then(m => m.AdminModule)
  },
  { path: '**', redirectTo: '/not-found' },
];
@NgModule({
  declarations: [
    CategorizerPipe,
    ValuesPipe,
    RemoveNumberPipe,
    TrueFalsePipe,
    SimpleDialogComponent,
    DownloadDialogComponent,
    FrmYearPipe,
    AppComponent,
    NotFoundComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    SearchSystematicComponent,
    BannerComponent,
    FiltersMenuComponent,
    FiltersMenuItemComponent,
    SectionComponent,
    ProfileMenuComponent,
    UpdatePasswordComponent,
    AssessmentComponent,
    AssessmentSystematicComponent,
    ProfileComponent,
    RegisterComponent,
    UsersDetailComponent,
    LoginComponent,
    ResetPasswordComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    SkeletonParagraphDirective,
    SkeletonLineDirective,
    SkeletonImageDirective,
    SkeletonBoxDirective,
    SkeletonSVGMapComponent,
    RiskCategorySwitcherComponent,
    ImageGalleryComponent,
    DialogImageGalleryComponent,
    ImgLoaderDirective,
    AssessmentCardComponent,
    ReadMoreDirective,
    LeafletMapComponent,
    ShellComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatListModule,
    MatAutocompleteModule,
    MatInputModule,
    MatExpansionModule,
    MatTooltipModule,
    MatTableModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    TranslocoRootModule,
    RouterModule.forRoot(routes, { enableTracing: false }),
    NgxsModule.forRoot([MainState], {
      developmentMode: !environment.production
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['admin.user', 'main.user', 'main.lastSearch', 'main.selectedFilters', 'main.searchString']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      maxAge: 25, // Retains last 25 states,
      disabled: environment.production
    }),
  ],
  providers: [
    AuthenticationService,
    AdminDialogService,
    AuthGuard,
    UsersService
  ],
  entryComponents: [
    SimpleDialogComponent,
    DownloadDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
