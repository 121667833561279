<article class="wrapper" *transloco="let t; read:'core.assessment'">
  <div class="container">
    <section class="head">
      <div class="columns">
        <div class="column is-one-third assessment-img">
          <figure class="image is-4by3">
            <img [matTooltip]="assessment?.cover.description ? assessment?.cover.description : assessment?.name " [appImgLoader]="assessment?.cover" >
          </figure>
        </div>
        <div class="column is-two-thirds">
          <h3 class="scope-name" [appSkeletonLine]="loading.assessment" [SWidth]="40" SClass="s2">{{t('scope_of_assessment')}}: {{ globalScope }}</h3>
          <div class="assessment-name">
            <h1 class="title" [appSkeletonLine]="loading.assessment" [SWidth]="80" SClass="s3">{{ assessment?.name }}</h1>
            <!-- <a [matTooltip]="t('download_pdf')" class="button is-white is-medium download-btn" target="_blank"
            [href]="'backendAPIBASE'+'ops/studies/downloadpdf/'+'?study='+assessment?.id">
              <span class="icon has-text-primary"><i class="fas fa-lg fa-download"></i></span>
            </a> -->
          </div>
          <hr class="scope-hr">
          <div class="scope-container">
            <!-- <div class="scope overall-risk" *ngIf="!loading.assessment">
              <span class="title">{{t('overall_risk_category')}}</span>
              <app-risk-category-switcher class="category-bullet" [ngStyleSize]="{'height': '40px', 'width': '40px'}"
                [assessment_category]="assessment?.category.name"></app-risk-category-switcher>
            </div> -->
            <div class="scope assessment-type" [appSkeletonLine]="loading.assessment" [SWidth]="40">
              <span class="title">{{t('assessment_type')}}</span>
              <app-risk-category-switcher class="category-bullet"
              [ngStyleSize]="{'height': '45px', 'width': '45px'}" [assessment_type]="true">
            </app-risk-category-switcher>
            </div>
            <div style="margin-left: 15px;">
              <span class="title">{{t('number_of_assessed_units')}}:
                <b>{{assessment?.number_units}}</b></span>
            </div>
          </div>
          <hr>
          <div class="" style="margin-left: 0px !important;">
            <div class="info-wrapper">
              <div class="info">
                <h2 class="section-title" style="font-weight: bold;" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('summary')}}:</h2>
                <p [appSkeletonParagraph]="loading.assessment">{{ assessment?.summary }}</p>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="column">
              <h1 class="title" [appSkeletonLine]="loading.assessment" [SWidth]="80" SClass="s3">{{ assessment?.name }}
              </h1>
            </div>
            <hr>
            <div class="column">
              <div class="scope-container">
                <div class="scope assessment-type" [appSkeletonLine]="loading.assessment" [SWidth]="40">
                  <span class="title">{{t('assessment_type')}}</span>
                  TODO: esto en columnas
                  <app-risk-category-switcher class="category-bullet"

                    [ngStyleSize]="{'height': '45px', 'width': '45px'}" [assessment_type]="true">
                  </app-risk-category-switcher>
                  &nbsp;&nbsp;&nbsp;<span class="title">{{t('number_of_assessed_units')}}:
                    <b>{{assessment?.number_units}}</b></span>
                </div>
              </div>
            </div>
            <hr>
            <div class="column">
              <h2 class="section-title" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('summary')}}</h2>
              <p [appSkeletonParagraph]="loading.assessment">{{ assessment?.summary }}</p>
            </div>
          </div> -->
          <div class="column is-full">
            <div class="info-wrapper">
              <div class="info" [appSkeletonParagraph]="loading.assessment" Srows="6">
                <ng-container *ngIf="assessment?.parent_version && !assessment?.parent_version.last_version.id">
                  <div class="info-item"
                    *ngIf="assessment.parent_version && assessment.parent_version.versions.length > 0">
                    <div class="info-h">{{t('previously_published')}}:</div>
                    <div class="keywords-wrapper">
                      <span class="tag is-white" *ngFor="let version of assessment.parent_version.versions">
                        <a class="button" routerLink="/systematics/{{version.id}}/previous">{{ version.year }} -
                          {{ version.category }}</a>
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="assessment?.parent_version && assessment?.parent_version.last_version.id">
                  <div class="info-item"
                    *ngIf="assessment.parent_version && assessment.parent_version.versions.length > 0">
                    <div class="info-highlight">
                      <i class="fas fa-exclamation-circle"></i>
                      <span class="aligned-with-icon">{{t('message_latest_assessment')}}
                      <a routerLink="/systematics/{{assessment.parent_version.last_version.id}}">{{t('link_latest_assessment')}}</a> </span>
                    </div>
                    <!-- <div class="keywords-wrapper">
                      <span class="tag is-white"></span>
                        <a class="button"
                          routerLink="/assessments/{{assessment.parent_version.last_version.id}}">{{ assessment.parent_version.last_version.year }}
                          -
                          {{ assessment.parent_version.last_version.category }}</a>
                      </span>
                    </div> -->
                    <ng-container *ngIf="assessment.parent_version.versions.length>1">
                      <div class="info-h">{{t('previously_published')}}:</div>
                      <div class="keywords-wrapper">
                        <span class="tag is-white" *ngFor="let version of assessment.parent_version.versions">
                          <a class="button"
                            routerLink="/assessments/{{version.id}}/previous">{{ version.year }} -
                            {{ version.category }}</a>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <div class="box outer">
      <section class="head-info">
        <div class="info-wrapper">
          <div class="info" [appSkeletonParagraph]="loading.assessment" Srows="6">
            <ng-container *ngIf="reference">
              <div class="info-item" *ngIf="reference.citation !== ' '">
                <div class="info-h">{{t('citation')}}:</div>
                <span>{{ reference.citation }}</span>
              </div>
              <div class="info-item" *ngIf="reference.web_url">
                <div class="info-h">{{t('link')}}:</div>
                <a class="button is-text link" target="_blank" [href]="reference.web_url">{{ reference.web_url }}</a>
              </div>
              <div class="info-item" *ngIf="reference.year">
                <div class="info-h">{{t('publication_date')}}:</div>
                <span>{{ reference.year }}</span>
              </div>
              <div class="info-item" *ngIf="assessment?.keywords && assessment?.keywords.length > 0">
                <div class="info-h">{{t('keywords')}}:</div>
                <div class="keywords-wrapper">
                  <span class="tag is-white" *ngFor="let keyword of assessment?.keywords">{{ keyword.name }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </section>
    </div>

    <div class="box outer">
      <section class="ecosystem">
        <div class="container">
          <div class="columns">
            <div class="column is-6">
              <h3 class="section-title" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('ecosystem_map')}}</h3>
              <div class="subsection-img">
                <div class="subsection-img">
                  <figure class="image">
                    <img [appImgLoader]="assessment?.image_map" [matTooltip]="assessment?.image_map?.description">
                  </figure>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <h3 class="section-title" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('summary_risk_statistics')}}</h3>
              <div class="subsection-img chart-container box">
                <highcharts-chart *ngIf="pie1Options"
                  [Highcharts]="Highcharts"
                  [options]="pie1Options"
                  [runOutsideAngular]="true"
                ></highcharts-chart>
                <div class="chart-footer">{{ chart_legend }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="box outer">
      <section class="search-assessments">
        <h2 class="section-title-search" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('assessed_units')}}</h2>
        <form class="field has-addons" [formGroup]="searchUnits" (ngSubmit)="onSearch()">
          <div class="control has-icons-left is-expanded">
            <input class="input" type="text" formControlName="search" placeholder="{{t('assessed_units')}}">
            <span class="icon is-small is-left"><i class="fas fa-search"></i></span>
          </div>
          <div class="control">
            <button type="submit" class="button is-primary">{{t('search')}}</button>
          </div>
        </form>
        <hr>
        <div class="columns is-multiline">
          <div class="column is-6-desktop is-4-widescreen is-6-tablet" *ngFor="let item of related_units">
            <a [routerLink]="[ '/assessments', item.id ]">
              <div class="card unit-container">
                <div class="card-image">
                  <figure class="image is-4by3"><img [appImgLoader]="item.cover" [matTooltip]="assessment?.cover.description"></figure>
                </div>
                <div class="unit-content">
                  <div class="info-item">
                    <div class="study-typology">{{ item.typology }}</div>
                    <div class="study-name" [matTooltip]="item.name">{{ item.name_systematic }}</div>
                  </div>
                  <div class="cat-wrapper">
                    <app-risk-category-switcher class="category" size="m" [assessment_category]="item.category.name"></app-risk-category-switcher>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="load-more-syst" *ngIf="searchResultsNextUrl">
          <button class="button is-primary" [ngClass]="{'is-loading': loading.loadingMore}" (click)="searchStudiesNextPage()">{{t('load_more')}} </button>
        </div>
        <hr>
      </section>
    </div>

    <div class="floating-bullet" [ngClass]="{'allowToShow': legendAllowToShow, 'inactive': legendShow}">
      <button class="button is-large" (click)="legendShow = true">
        <span class="icon is-medium"><i class="fas fa-question"></i></span>
      </button>
    </div>
    <div class="floating-wrapper is-hidden-mobile" [ngClass]="{'allowToShow': legendAllowToShow, 'show': legendShow}">
      <div class="assessment-legend">
        <div class="container">
          <div class="legend-title">{{t('legend')}}</div>
          <div class="pipe"></div>
          <div class="legend-head" [innerHTML]="t('red_list_ecosystems_categories_html')"></div>
          <div class="categories">
            <img src="assets/images/riskbullets/co.png" matTooltip="Collapsed" alt="Collapsed">
            <img src="assets/images/riskbullets/cr.png" matTooltip="Critically Endangered" alt="Critically Endangered">
            <img src="assets/images/riskbullets/en.png" matTooltip="Endangered" alt="Endangered">
            <img src="assets/images/riskbullets/vu.png" matTooltip="Vulnerable" alt="Vulnerable">
            <img src="assets/images/riskbullets/nt.png" matTooltip="Near Threatened" alt="Near Threatened">
            <img src="assets/images/riskbullets/lc.png" matTooltip="Least Concern" alt="Least Concern">
            <img src="assets/images/riskbullets/dd.png" matTooltip="Data Deficient" alt="Data Deficient">
            <img src="assets/images/riskbullets/ne.png" matTooltip="Not Evaluated" alt="Not Evaluated">
          </div>
          <div class="pipe"></div>
          <div class="legend-head" [innerHTML]="t('assessment_type_html')"></div>
          <div class="categories">
            <img src="{{icon_strategic}}" matTooltip="Strategic" alt="Strategic">
            <img src="{{icon_systematic}}" matTooltip="Systematic" alt="Systematic">
          </div>
        </div>
        <div class="dismiss" (click)="legendShow = false">
          <span class="icon is-medium"><i class="fas fa-times"></i></span>
        </div>
      </div>
    </div>

  </div>
  <div class="assessment-legend is-hidden-mobile" #assessmentLegend>
    <div class="container">
      <div class="legend-title">{{t('legend')}}</div>
      <div class="pipe"></div>
      <div class="legend-head" [innerHTML]="t('red_list_ecosystems_categories_html')"></div>
      <div class="categories">
        <img src="assets/images/riskbullets/co.png" matTooltip="Collapsed" alt="Collapsed">
        <img src="assets/images/riskbullets/cr.png" matTooltip="Critically Endangered" alt="Critically Endangered">
        <img src="assets/images/riskbullets/en.png" matTooltip="Endangered" alt="Endangered">
        <img src="assets/images/riskbullets/vu.png" matTooltip="Vulnerable" alt="Vulnerable">
        <img src="assets/images/riskbullets/nt.png" matTooltip="Near Threatened" alt="Near Threatened">
        <img src="assets/images/riskbullets/lc.png" matTooltip="Least Concern" alt="Least Concern">
        <img src="assets/images/riskbullets/dd.png" matTooltip="Data Deficient" alt="Data Deficient">
        <img src="assets/images/riskbullets/ne.png" matTooltip="Not Evaluated" alt="Not Evaluated">
      </div>
      <div class="pipe"></div>
      <div class="legend-head" [innerHTML]="t('assessment_type_html')"></div>
      <div class="categories">
        <img src="{{icon_strategic}}" matTooltip="Strategic" alt="Strategic">
        <img src="{{icon_systematic}}" matTooltip="Systematic" alt="Systematic">
      </div>
    </div>
  </div>
</article>
