<div class="disclaimer" *ngIf="disclaimer">
  <span class="icon has-text-info"><i class="fas fa-lg fa-info-circle"></i></span>
  <span style="margin-right: 3px !important;">{{ mapDisclaimer }} <a class="is-link" target="_blank" href="{{contactUs}}">{{ 'core.leafletMapComponent.disclaimer_link' | transloco }}</a></span>
  <a class="button is-white is-primary" (click)="hideDisclaimer()">{{ 'core.leafletMapComponent.disclaimer_button' | transloco }}</a>
</div>
<div id="map"></div>
<div class="map-legend">
  <div class="columns">
    <div class="column legend-head" innerHTML="{{ 'core.leafletMapComponent.legend_html' | transloco }}"></div>
    <div class="column categories">
      <img src="assets/images/riskbullets/co.png" matTooltip="Collapsed" alt="Collapsed">
      <img src="assets/images/riskbullets/cr.png" matTooltip="Critically Endangered" alt="Critically Endangered">
      <img src="assets/images/riskbullets/en.png" matTooltip="Endangered" alt="Endangered">
      <img src="assets/images/riskbullets/vu.png" matTooltip="Vulnerable" alt="Vulnerable">
      <img src="assets/images/riskbullets/nt.png" matTooltip="Near Threatened" alt="Near Threatened">
      <img src="assets/images/riskbullets/lc.png" matTooltip="Least Concern" alt="Least Concern">
      <img src="assets/images/riskbullets/dd.png" matTooltip="Data Deficient" alt="Data Deficient">
      <img src="assets/images/riskbullets/ne.png" matTooltip="Not Evaluated" alt="Not Evaluated">
    </div>
  </div>
</div>
