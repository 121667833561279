import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { map } from 'rxjs/operators';

import { User } from '../models/user.model';
import { Logout } from '../store/main.actions';

// prevent an un-authenticated user to access admin views
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.selectOnce(state => state.main.user).pipe(
      map((user: User) => {
        if (user && user.access && user.refresh) {
          return true;
        } else {
          this.store.dispatch(new Logout());
        }
        return false;
      })
    );
  }
}

// prevent an authenticated user with a valid token try to access into the login screen
@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.selectOnce(state => state.main.user).pipe(
      map((user: User) => {
        if (user && user.access && user.refresh) {
          this.store.dispatch(new Navigate(['/admin']));
          return false;
        } else {
          return true;
        }
      })
    );
  }
}


