import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store, Actions, ofActionSuccessful, Select } from '@ngxs/store';

import { SearchRequest, ActionsMobileMenu, Logout } from '../../store/main.actions';
import { TranslocoService } from '@ngneat/transloco';
import { RestService } from '../../services/rest.service';
import { User } from '../../models/user.model';
import { MainState } from '../../store/main.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(MainState.user) user$: Observable<User>;
  @Select(MainState.searchString) searchString$: Observable<string>;
  searchObservable$: Subscription;
  mobileMenu$: Subscription;
  searchForm: FormGroup = this.fb.group({
    search: '',
  });
  showMenu = false;
  langDropdownActive = false;
  sessionDropdownActive = false;
  activeLang: string;
  logo: string;
  logo_url: string;
  availableLangs = [
    { label: 'English', value: 'en', active: true },
    { label: 'Español', value: 'es', active: false }
  ];
  bgImage = `url(assets/images/headers/internals/header-internas_pgb0${Math.floor((Math.random() * 7) + 1)}.jpg)`;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private translocoS: TranslocoService,
    private restService: RestService
  ) { }

  ngOnDestroy() {
    this.mobileMenu$?.unsubscribe();
    this.searchObservable$?.unsubscribe();
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  // async ngOnInit() {
  //   this.selectActiveLang(this.translocoS.getActiveLang());


  // }

  async ngOnInit() {
    console.log(this.router.url);
    this.bgImage = `url(assets/images/headers/home/header-home_pgb0${Math.floor((Math.random() * 8) + 1)}.jpg)`;
    const settingData = await this.restService.getBrandLogo();
    this.logo = settingData ? settingData?.[0]?.logo_data : null;
    this.logo_url = settingData ? settingData?.[0]?.logo_url : null;
    this.mobileMenu$ = this.actions$.pipe(ofActionSuccessful(ActionsMobileMenu)).subscribe(({ payload }) => {
      this.showMenu = payload;
    });
    this.selectActiveLang(this.translocoS.getActiveLang());
    this.searchString$.subscribe((payload) => {
      this.searchForm.get('search').setValue(payload);
    });
  }

  onSubmit() {
    this.searchObservable$ = this.store.dispatch(new SearchRequest( this.searchForm.value.search ))
    .subscribe(() => {
      // navigate to /search if not already there
      if (this.router.url !== '/search') {
        this.router.navigate(['/search']);
      }
    });
  }

  setActiveLang(lang: string) {
    this.translocoS.setActiveLang(lang);
    this.selectActiveLang(lang);
  }
  private selectActiveLang(lang: string) {
    this.availableLangs.forEach((l) => {

      if (l.value === lang) {
        this.activeLang = l.value;
        l.active = true;
      } else {
        l.active = false;
      }
    });
    this.langDropdownActive = false;
  }

  showMenuHandler() {
    if (this.showMenu) {
      this.store.dispatch(new ActionsMobileMenu( false ));
    } else {
      this.store.dispatch(new ActionsMobileMenu( true ));
    }
  }

}
