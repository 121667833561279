<div class="container">
  <div class="columns is-marginless">
    <aside class="column is-fixed-size search-filters">
      <app-profile-menu selected="profile-edit"></app-profile-menu>
    </aside>
    <section class="section" *transloco="let t;">
      <div class="box editor-box">
        <div class="section-title">
          <h2>{{ t('admin.usersDetail.section_title') }}</h2>
        </div>
        <hr class="section-separator">
        <div class="section-loading" *ngIf="!user && loading"><mat-spinner></mat-spinner></div>
        <div class="section-empty" *ngIf="!user && !loading"><span>{{ t('admin.usersDetail.empty_message') }}</span></div>
        <ng-container *ngIf="user && !loading">
          <div class="section-content">
            <form class="admin-generic-form" [formGroup]="userDetailForm">
              <div class="field">
                <label class="label">{{ t('admin.usersDetail.email') }}</label>
                <div class="control">
                  <input class="input is-static" type="text" formControlName="email">
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">{{ t('admin.usersDetail.first_name') }}</label>
                    <div class="control">
                      <input class="input" type="text" [ngClass]="{'is-danger': f.first_name.dirty && f.first_name.errors, 'is-static': !editing }" formControlName="first_name" placeholder="{{ t('admin.usersDetail.enter_first_name') }}">
                    </div>
                    <ng-container *ngIf="editing">
                      <p class="help is-danger" *ngIf="f.first_name.dirty">
                        <span *ngIf="f.first_name.errors?.required">{{ t('global.validation.required_field') }}</span>
                      </p>
                    </ng-container>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">{{ t('admin.usersDetail.last_name') }}</label>
                    <div class="control">
                      <input class="input" type="text" [ngClass]="{'is-danger': f.last_name.dirty && f.last_name.errors, 'is-static': !editing }" formControlName="last_name" placeholder="{{ t('admin.usersDetail.enter_last_name') }}">
                    </div>
                    <ng-container *ngIf="editing">
                      <p class="help is-danger" *ngIf="f.last_name.dirty">
                        <span *ngIf="f.last_name.errors?.required">{{ t('global.validation.required_field') }}</span>
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <hr class="section-separator">
          <div class="section-footer">
            <div class="buttons">
              <button class="button is-primary" *ngIf="!editing" (click)="setEditingState(true)">{{ t('global.formActions.edit') }}</button>
              <button class="button is-light" *ngIf="editing" [ngClass]="{'is-loading': submitting }" [disabled]="submitting" (click)="setEditingState(false)">{{ t('global.formActions.cancel') }}</button>
              <button class="button is-primary" *ngIf="editing" [ngClass]="{'is-loading': submitting }" [disabled]="submitting || userDetailForm.invalid" (click)="saveChanges()">{{ t('global.formActions.save_changes') }}</button>
              <div class="pushed-right">
                <!--<button class="button is-info" *ngIf="!editing" [ngClass]="{'is-loading': submitting }" [disabled]="submitting" (click)="resendActivationEmail()">{{ t('admin.usersDetail.send_activation_email') }}</button>-->
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</div>