<main class="admin-auth-base">
  <section class="section" *transloco="let t">
    <div class="wrapper">
      <div class="auth-main-container">
        <div class="container-header" [innerHTML]="t('admin.fPassword.container_header')"></div>
        <form [formGroup]="fPasswordForm" *ngIf="!submitted" (ngSubmit)="onSubmit()">
          <div class="field field-email">
            <div class="control has-icons-left">
              <input class="input" type="text" [ngClass]="{'is-danger': f.email.dirty && f.email.errors }" formControlName="email" placeholder="{{ t('admin.fPassword.enter_your_email') }}">
              <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
            </div>
            <p class="help is-danger" *ngIf="f.email.dirty">
              <span *ngIf="f.email.errors?.email">{{ t('global.validation.invalid_email') }}</span>
              <span *ngIf="f.email.errors?.required">{{ t('global.validation.required_field') }}</span>
            </p>
          </div>
          <div class="field field-actions">
            <div class="control is-expanded">
              <button class="button is-fullwidth is-primary" [ngClass]="{'is-loading': submitting }" [disabled]="submitting" type="submit">{{ t('admin.fPassword.reset_password_button') }}</button>
            </div>
          </div>
        </form>
        <div class="resend-email-form" *ngIf="submitted">
          <div class="message-success">{{ t('admin.fPassword.email_sent_success', { email_address: submittedForm.email }) }}</div>
          <div class="field field-actions">
            <div class="control is-expanded">
              <button class="button is-fullwidth is-primary" [ngClass]="{'is-loading': submitting }" [disabled]="submitting" type="button" (click)="onReSubmit()">{{ t('admin.fPassword.resend_password_button') }}</button>
            </div>
          </div>
        </div>
        <div class="container-footer" [innerHTML]="t('admin.fPassword.container_footer')"></div>
      </div>
      <div class="notifications-container" *ngIf="backendErrorMessage || backendSuccessMessage">
        <div class="notification is-danger" *ngIf="backendErrorMessage">
          <button class="delete" (click)="backendErrorMessage = null;"></button>
          {{backendErrorMessage}}
        </div>
        <div class="notification is-success" *ngIf="backendSuccessMessage">
          <button class="delete" (click)="backendSuccessMessage = null;"></button>
          {{backendSuccessMessage}}
        </div>
      </div>
    </div>

  </section>
</main>
