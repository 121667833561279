import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';

import { InitRootFilters } from '../../store/main.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private store: Store,
    private transloco: TranslocoService,
  ) { }

  ngOnInit() {
    this.store.dispatch(new InitRootFilters());
    const availableTranslocoLang = ['es']; // do not include default that is 'en' <english>
    const switchLang = availableTranslocoLang.includes(window.navigator.language.slice(0, 2));
    if (switchLang) {
      this.transloco.setActiveLang('es');
    }
  }
}
