import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Credentials, User, FPassword, SessionJWTTokens, RPassword, CPassword, AAccount } from '../models/user.model';
import { Store } from '@ngxs/store';
const API = environment.APIBASE;

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private store: Store) { }

  login(credentials: Credentials) {
    return this.http.post<User>(API + `token/`, credentials).toPromise();
  }
  refreshAccessToken(refreshToken: string) {
    return this.http.post<SessionJWTTokens>(API + `token/refresh/`, { refresh: refreshToken} ).toPromise();
  }
  forgotPassword(emailObj: FPassword) {
    return this.http.post<void>(API + `users/reset_password/`, emailObj).toPromise();
  }
  resetPassword(resetPasswordObj: RPassword) {
    return this.http.post<void>(API + `users/reset_password_confirm/`, resetPasswordObj).toPromise();
  }
  updatePassword(updatePasswordObj: CPassword) {
    const token = this.store.selectSnapshot<string>((state) => state?.main?.user?.access);
    let headers = {};
    if (token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      });
    }
    return this.http.post<void>(API + `users/set_password/`, updatePasswordObj, { headers }).toPromise();
  }
  activateAccount(activateAccountObj: AAccount) {
    return this.http.post<void>(API + `users/activate_user_account/`, activateAccountObj).toPromise();
  }
  whoAmI() {
    const token = this.store.selectSnapshot<string>((state) => state?.main?.user?.access);
    let headers = {};
    if (token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      });
    }
    return this.http.get<User>(API + `users/me/`, { headers }).toPromise();
  }
  // refreshToken(credentials: Credentials) {
  //   return this.http.post(API + `token/`, credentials).toPromise();
  // }

}
