<header *transloco="let t; read:'core.headerComponent'">
  <nav class="navbar" [ngStyle]="{'background-image': bgImage}" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand wrap-mobile">
        <div class="column is-one-quarter">
          <a class="navbar-item logo-link center-mobile" href="{{logo_url}}">
            <!-- <img src="assets/images/iucn-white.png" alt="IUCN">
            <img src="assets/images/cem-white.png" alt="CEM" style="margin-left:10px">
            <span class="brand-name">Red List of Ecosystems</span> -->
            <img [src]="logo" alt="IUCN">
          </a>
        </div>
        <div class="column is-three-quarters">
          <form class="search-bar" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
            <div class="navbar-item input-search-container">
              <div class="field has-addons search">
                <div class="control is-expanded">
                  <input formControlName="search" class="input" type="text" placeholder="{{ t('search_input_placeholder') }}" >
                </div>
                <div class="control">
                  <button class="button is-info">
                    <span class="icon">
                      <i class="fas fa-lg fa-search"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="navbar-item">
              <button class="button advancedsearch has-text-white no-border" type="submit">
                <span>{{ t('advance_search') }}</span>
              </button>
              <div class="dropdown is-right" [ngClass]="{'is-active': sessionDropdownActive}">
                <div class="dropdown-trigger">
                  <button class="button has-text-white no-border" type="button" aria-haspopup="true" aria-controls="dropdown-menu" (click)="sessionDropdownActive = !sessionDropdownActive">
                    <span *ngIf="!(user$ | async)?.complete_name" style="text-transform: uppercase;">
                      {{t('login')}}
                    </span>
                    <span *ngIf="(user$ | async)?.complete_name" style="text-transform: uppercase;">
                      {{(user$ | async)?.complete_name}}
                    </span>
                    <span class="icon is-small"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <a *ngIf="(user$ | async)?.complete_name"
                      class="dropdown-item"
                      style="text-transform: uppercase;"
                      href="/profile">
                      {{t('my_profile')}}
                    </a>
                    <a *ngIf="(user$ | async)?.complete_name"
                      class="dropdown-item"
                      style="text-transform: uppercase;"
                      (click)="logout()">
                      {{t('logout')}}
                    </a>
                    <a *ngIf="!(user$ | async)?.complete_name"
                      class="dropdown-item"
                      style="text-transform: uppercase;"
                      href="login">{{t('login')}}</a>
                    <a *ngIf="!(user$ | async)?.complete_name"
                      class="dropdown-item"
                      style="text-transform: uppercase;"
                      href="register">{{t('register')}}</a>
                  </div>
                </div>
              </div>
              <div class="dropdown is-right" [ngClass]="{'is-active': langDropdownActive}" style="border-left:1px solid white; margin-left: 10px;">
                <div class="dropdown-trigger">
                  <button class="button has-text-white no-border" type="button" aria-haspopup="true" aria-controls="dropdown-menu" (click)="langDropdownActive = !langDropdownActive">
                    <span style="text-transform: uppercase;">{{ activeLang }}</span>
                    <span class="icon is-small"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <a class="dropdown-item" style="text-transform: uppercase;" *ngFor="let lang of availableLangs" (click)="setActiveLang(lang.value)" [ngClass]="{'is-active': lang.active }">{{lang.value}}</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </nav>
</header>
<div class="mobile-menu is-hidden-desktop" [ngClass]="{'is-active': showMenu }">
  <router-outlet name="header-menu"></router-outlet>
</div>
