import { Directive, Input, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appSkeletonLine]'
})
export class SkeletonLineDirective implements OnChanges {
  @Input('appSkeletonLine') loading = false;
  @Input() SClass: string;
  @Input() SWidth = '100';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading) {
      if (this.loading) {
        this.renderer.addClass(this.el.nativeElement, 'skeleton-line');
        if (this.SClass) {
          this.renderer.addClass(this.el.nativeElement, this.SClass);
        }
        this.renderer.setStyle(this.el.nativeElement, 'width', this.SWidth + '%');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'skeleton-line');
        if (this.SClass) {
          this.renderer.removeClass(this.el.nativeElement, this.SClass);
        }
        this.renderer.removeStyle(this.el.nativeElement, 'width');
      }
    }
  }

}
