<div class="not-found-bg">
  
  
  <div class="banner" *transloco="let t; read:'core.notFound'">
    <div class="banner-img">
      <img src="../../../../assets/images/notfound.png" alt="Not Found">    
    </div>
    
    <h1>{{ t('title') }}</h1>
    <h3 [innerHTML]="t('message')"></h3>
    <a class="button go-home-btn" [routerLink]="['/search']">{{ t('message_link') }}</a>
  </div>
</div>

