import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * This Function is intended to validate that to formControl from the same FormGroup have the same value
 * Perfect for:
 * - Password matching validation
 * - Validating against a specific string, declared as a fromControl
 * @param controlNameToCompare name of the fromControl of the same formGroup to validate against
 */
export function compareValidator(controlNameToCompare: string): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (c.value === null || c.value.length === 0) {
      return null; // don't validate empty value
    }
    const controlToCompare = c.root.get(controlNameToCompare);
    if (controlToCompare) {
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
        c.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }
    return controlToCompare && controlToCompare.value !== c.value ? { compare: true } : null;
  };
}

export function atLeastOnItemInArray(minLength = 1): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (c.value === null) {
      return null; // don't validate empty value
    }
    return c.value.length >= minLength ? null : { atLeastOnItemInArray: true };
  };
}

/**
 * This Function is intended to validate that all files match at least one of the provided MIME types in the validator function
 * For more information check: https://developer.mozilla.org/es/docs/Web/HTTP/Basics_of_HTTP/MIME_types
 * @param fileTypes File types MIME can be an array or a single MIME type
 */
export function validFileType(fileTypes: string | string[]): ValidatorFn {
  if (!fileTypes) {
    console.error('Invalid FileType validator, please provide one, using empty string as default');
    fileTypes = '';
  }
  return (c: AbstractControl): ValidationErrors | null => {
    const files: File | FileList = c.value;
    const fileTypesArray: string[] = Array.isArray(fileTypes) ? fileTypes : [fileTypes];
    if (c.value === null || c.value.length === 0) {
      return null; // don't validate empty value
    }
    let filesArray: File[];
    if (files instanceof FileList) { filesArray = Array.from(files); }
    if (files instanceof File) { filesArray = [files]; }
    const isValid = filesArray.every((f: File) => {
      return fileTypesArray.some((t) => t === f.type);
    });
    if (isValid) {
      return null;
    } else {
      return { invalidFileType: true };
    }
  };
}

/**
 * This Function is intended to validate the maximum valid file size of a File type object
 * @param fileSize File size in Bytes
 */
export function maxFileSize(fileSize: number): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (!fileSize) {
      console.error('Invalid fileSize, please provide one, not performing validation as default');
      return null;
    }
    const files: File | FileList = c.value;
    if (c.value === null || c.value.length === 0) {
      return null; // don't validate empty value
    }
    let filesArray: File[];
    if (files instanceof FileList) { filesArray = Array.from(files); }
    if (files instanceof File) { filesArray = [files]; }
    const isValid = filesArray.every((f: File) => {
      return f.size < fileSize;
    });
    if (isValid) {
      return null;
    } else {
      return { maximumFileSize: true };
    }
  };
}
