<main class="admin-auth-base">
  <section class="section" *transloco="let t;">
    <div class="wrapper">
      <div class="auth-main-container">
        <div class="box editor-box">
          <div class="container-header">{{ t('core.register.section_header') }} </div>
          <div class="section-title">
            <h2>{{ t('core.register.section_title') }}</h2>
            <h2>{{ t('core.register.section_subtitle') }}</h2>
            <br><br>
            <button class="button back-button" routerLink="../login">
              <span class="icon is-small"><i class="fas fa-chevron-left"></i></span>
              <span>{{ t('global.formActions.back') }}</span>
            </button>
          </div>
          <hr class="section-separator">
          <div class="section-content">
            <form class="admin-generic-form" [formGroup]="userCreateForm">
              <div class="field">
                <label class="label">{{ t('admin.usersCreate.email') }} *</label>
                <div class="control">
                  <input class="input" type="text" [ngClass]="{'is-danger': f.email.dirty && f.email.errors }" formControlName="email" placeholder="{{ t('admin.usersCreate.enter_email') }}" (blur)="searchAvailableEmail()">
                </div>
                <p class="help is-danger" *ngIf="f.email.dirty">
                  <span *ngIf="f.email.errors?.email">{{ t('global.validation.invalid_email') }}</span>
                  <span *ngIf="f.email.errors?.required">{{ t('global.validation.required_field') }}</span>
                </p>
              </div>
              <div class="help is-danger" *ngIf="!available">{{t('admin.usersCreate.email_exists')}}</div>
              <div class="field">
                <label class="label">{{ t('admin.usersCreate.first_name') }} *</label>
                <div class="control">
                  <input class="input" type="text" [ngClass]="{'is-danger': f.first_name.dirty && f.first_name.errors }" formControlName="first_name" placeholder="{{ t('admin.usersCreate.enter_first_name') }}">
                </div>
                <p class="help is-danger" *ngIf="f.first_name.dirty">
                  <span *ngIf="f.first_name.errors?.required">{{ t('global.validation.required_field') }}</span>
                </p>
              </div>
              <div class="field">
                <label class="label">{{ t('admin.usersCreate.last_name') }} *</label>
                <div class="control">
                  <input class="input" type="text" [ngClass]="{'is-danger': f.last_name.dirty && f.last_name.errors }" formControlName="last_name" placeholder="{{ t('admin.usersCreate.enter_last_name') }}">
                </div>
                <p class="help is-danger" *ngIf="f.last_name.dirty">
                  <span *ngIf="f.last_name.errors?.required">{{ t('global.validation.required_field') }}</span>
                </p>
              </div>
              <div class="field">
                <label class="label">{{ t('admin.usersCreate.password') }} *</label>
                <div class="control">
                  <input class="input" type="password" [ngClass]="{'is-danger': f.password1.dirty && f.password1.errors }" formControlName="password1" placeholder="{{ t('admin.usersCreate.enter_password') }}">
                </div>
                <p class="help is-danger" *ngIf="f.password1.dirty">
                  <span *ngIf="f.password1.errors?.pattern">{{ t('admin.usersCreate.error_pass') }}</span>
                  <span *ngIf="f.password1.errors?.required">{{ t('global.validation.required_field') }}</span>
                </p>
              </div>
              <div class="field">
                <label class="label">{{ t('admin.usersCreate.password_confirm') }} *</label>
                <div class="control">
                  <input class="input" type="password" [ngClass]="{'is-danger': f.password2.dirty && f.password2.errors }" formControlName="password2" placeholder="{{ t('admin.usersCreate.enter_password_confirm') }}">
                </div>
                <p class="help is-danger" *ngIf="f.password2.dirty">
                  <span *ngIf="f.password2.errors?.required">{{ t('global.validation.required_field') }}</span>
                  <span *ngIf="f.password2.errors?.compare">{{ t('global.validation.password_match_error') }}</span>
                </p>
              </div>
            </form>
          </div>
          <hr class="section-separator">
          <div class="section-footer">
            <div class="buttons">
              <button class="button is-primary" type="submit" [ngClass]="{'is-loading': submitting }" [disabled]="submitting || userCreateForm.invalid" (click)="onSubmit()">{{ t('global.formActions.create') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

