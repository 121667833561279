import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  item;
  @Input() section_item: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.item = this.section_item;
  }

  goTo(url){
    this.router.navigate(url);
  }

  getStyles(style){
    try {
      const jsonStyles = JSON.parse(style);
      return jsonStyles;
    } catch (error) {
      return null;
    }
  }

}
