import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

import { AuthenticationService } from '../../services/authentication.service';
import { FPassword } from '../../models/user.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  fPasswordForm: FormGroup;
  submitting: boolean;
  submitted = false;
  submittedForm: FPassword;
  backendErrorMessage: string;
  backendSuccessMessage: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private TranslocoS: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.fPasswordForm = this.fb.group({
      email: ['', {
        validators: [Validators.required, Validators.email]
      }],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.fPasswordForm.controls; }

  async onSubmit() {
    if (this.fPasswordForm.enable && this.fPasswordForm.valid) {
      try {
        this.submitting = true;
        this.backendErrorMessage = null;
        this.backendSuccessMessage = null;
        const forgotPassword = await this.authService.forgotPassword(this.fPasswordForm.value);
        this.submittedForm = this.fPasswordForm.value;
        this.submitted = true;
        this.fPasswordForm.reset();
        this.submitting = false;
      } catch (error) {
        console.error(error);
        this.submitting = false;
        switch (error.status) {
          case 0:
            this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_2');
            break;
          case 500:
            this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_1');
            break;
          default:
            if (error.errors?.message) {
              this.backendErrorMessage = error.errors.message;
            } else {
              this.backendErrorMessage = this.TranslocoS.translate('global.errors.invalid_email');
            }
            break;
        }
      }
    }
  }

  async onReSubmit() {
    try {
      this.submitting = true;
      this.backendErrorMessage = null;
      this.backendSuccessMessage = null;
      const forgotPassword = await this.authService.forgotPassword(this.submittedForm);
      this.backendSuccessMessage = this.TranslocoS.translate('admin.fPassword.email_resent_success');
      this.submitting = false;
    } catch (error) {
      this.submitting = false;
      this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_1');
    }
  }

}
