export const inlineWorldMap = `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   width="260px" height="118.179px" viewBox="0 0 260 118.179" enable-background="new 0 0 260 118.179" xml:space="preserve">
   <defs>
   <linearGradient id="skeleton_gradient" x1="0%" y1="50%" x2="100%" y2="50%">
      <stop offset="0%" stop-color="#ececed">
        <animate attributeName="stop-color" values="#ececed; #a1a1a1; #ececed" dur="3s" repeatCount="indefinite"></animate>
      </stop>
      <stop offset="100%" stop-color="#a1a1a1">
        <animate attributeName="stop-color" values="#a1a1a1; #ececed; #a1a1a1" dur="3s" repeatCount="indefinite"></animate>
      </stop>
   </linearGradient>
</defs>
<g id="layer1" fill="url('#skeleton_gradient')" transform="translate(275.17606,-309.45844)">
	<path id="path2830" d="M-213.991,427.464c-1-0.374-1.279-0.74-1.042-1.364c0.11-0.29,0.321-0.526,0.47-0.526
		c0.148,0,0.797,0.341,1.442,0.758c0.645,0.416,1.433,0.853,1.752,0.968l0.58,0.209l-0.713,0.017
		c-0.393,0.008-0.968,0.052-1.279,0.098C-213.093,427.668-213.637,427.598-213.991,427.464L-213.991,427.464z M-216.26,425.544
		c-0.188-0.105-0.502-0.141-0.698-0.079c-0.246,0.078-0.436-0.038-0.618-0.378c-0.145-0.27-0.403-0.54-0.575-0.598
		c-0.396-0.137-1.223-1.477-1.683-2.727c-0.233-0.632-0.546-1.068-0.917-1.28c-0.659-0.375-0.707-0.545-0.196-0.707
		c0.584-0.185,0.692-1.213,0.201-1.903c-0.234-0.33-0.358-0.716-0.287-0.9c0.083-0.218-0.147-0.598-0.704-1.166
		c-0.703-0.717-0.833-0.978-0.865-1.732c-0.021-0.488-0.14-1.011-0.265-1.163c-0.158-0.189-0.19-0.871-0.104-2.217
		c0.092-1.461,0.039-2.361-0.215-3.618c-0.377-1.866-0.57-5.341-0.472-8.512c0.021-0.675-0.066-1.604-0.195-2.063
		c-0.312-1.117-1.747-2.527-3.521-3.459c-1.269-0.666-1.447-0.837-1.943-1.861c-0.3-0.62-0.874-1.546-1.275-2.059
		c-0.401-0.513-0.837-1.266-0.969-1.672c-0.419-1.294-1.157-2.593-1.66-2.923c-0.268-0.175-0.573-0.632-0.679-1.013
		c-0.171-0.619-0.125-0.792,0.409-1.568c0.58-0.842,0.586-0.876,0.195-0.979c-0.962-0.251-0.558-1.537,1.17-3.716
		c1.147-1.448,1.483-2.552,1.166-3.827c-0.121-0.486-0.229-0.994-0.241-1.129c-0.011-0.135-0.098-0.246-0.192-0.246
		c-0.094,0-0.076-0.178,0.04-0.395c0.179-0.334,0.134-0.43-0.296-0.626c-0.627-0.285-1.573-0.003-1.576,0.471
		c-0.004,0.567-0.254,0.609-1.078,0.18c-0.441-0.23-0.898-0.418-1.018-0.418s-0.581-0.314-1.027-0.699
		c-0.446-0.383-0.922-0.785-1.057-0.893c-0.135-0.107-0.246-0.399-0.246-0.648c0-0.25-0.174-0.611-0.386-0.804
		s-0.488-0.656-0.611-1.033c-0.163-0.493-0.285-0.624-0.44-0.468c-0.325,0.324-0.804,0.262-1.97-0.252
		c-0.609-0.27-1.244-0.729-1.497-1.085c-0.242-0.34-0.704-0.755-1.028-0.922c-0.533-0.275-0.666-0.273-1.442,0.023l-0.855,0.327
		l-2.625-1.271c-1.615-0.783-2.868-1.529-3.256-1.94c-0.602-0.636-0.622-0.705-0.421-1.495c0.184-0.722,0.153-0.946-0.247-1.77
		c-0.251-0.519-0.8-1.316-1.219-1.771c-0.419-0.456-0.715-0.904-0.657-0.999c0.059-0.095-0.237-0.729-0.657-1.412
		c-0.477-0.774-0.813-1.604-0.896-2.211c-0.107-0.779-0.232-1.017-0.627-1.197c-0.418-0.19-0.535-0.167-0.775,0.161
		c-0.338,0.462-0.242,0.949,0.505,2.563c0.304,0.655,0.617,1.628,0.697,2.16c0.08,0.532,0.316,1.302,0.524,1.711
		c0.494,0.967,0.175,0.95-0.603-0.032c-0.459-0.58-0.583-0.897-0.492-1.262c0.098-0.387,0.001-0.575-0.476-0.928
		c-0.724-0.535-0.876-0.88-0.611-1.376c0.155-0.29,0.084-0.607-0.31-1.383c-0.4-0.79-0.483-1.171-0.384-1.757
		c0.162-0.957-0.268-1.824-1.069-2.157c-0.462-0.191-0.622-0.405-0.716-0.962c-0.066-0.394-0.194-1.003-0.284-1.354
		c-0.093-0.365-0.086-0.685,0.016-0.748c0.098-0.061,0.034-0.331-0.142-0.6c-0.26-0.397-0.278-0.584-0.094-0.989
		c0.125-0.274,0.172-0.587,0.104-0.696c-0.067-0.11,0.101-0.435,0.373-0.724c0.272-0.289,0.635-0.866,0.806-1.281
		c0.171-0.415,0.697-1.185,1.17-1.71c0.473-0.526,1.072-1.447,1.331-2.046c0.361-0.833,0.572-1.091,0.893-1.093
		c0.231-0.002,0.409,0.106,0.394,0.242c-0.063,0.563,0.383,0.391,0.883-0.344c0.295-0.433,0.455-0.817,0.354-0.854
		c-0.1-0.036-0.183-0.198-0.183-0.362c0-0.163-0.407-0.692-0.905-1.176c-0.826-0.803-0.879-0.908-0.604-1.212
		c0.223-0.245,0.249-0.426,0.101-0.691c-0.261-0.466,0.15-1.51,0.597-1.514c0.184-0.002,0.236-0.064,0.124-0.147
		c-0.254-0.186-0.369-0.61-0.333-1.227c0.057-0.985-0.159-1.397-0.891-1.704c-0.395-0.165-0.999-0.514-1.34-0.774
		c-0.342-0.26-0.775-0.474-0.963-0.474c-0.188,0-1.043-0.179-1.901-0.397c-1.378-0.352-1.622-0.366-2.1-0.12
		c-0.989,0.51-2.038,0.717-2.465,0.488c-0.32-0.172-0.67-0.066-1.891,0.573c-0.823,0.43-1.807,0.85-2.186,0.931
		c-0.378,0.083-1.104,0.358-1.611,0.613c-1.439,0.726-3.5,1.456-4.07,1.444c-0.368-0.008-0.119-0.17,0.86-0.558
		c1.664-0.66,3.373-1.52,3.531-1.775c0.194-0.315-0.228-0.429-0.736-0.198c-0.374,0.171-0.604,0.155-1.036-0.071l-0.555-0.291
		l0.604-0.313c0.683-0.353,0.897-0.752,0.741-1.376c-0.152-0.605,0.685-1.05,2.489-1.321c0.969-0.146,1.706-0.39,2.201-0.728
		c0.875-0.595,0.725-1.044-0.219-0.654c-0.446,0.184-0.771,0.186-1.432,0.008c-0.804-0.215-0.832-0.247-0.473-0.509
		c0.601-0.44,1.845-0.704,2.662-0.566c0.518,0.086,0.941,0.025,1.375-0.199l0.628-0.325l-0.414-0.44
		c-0.228-0.242-0.414-0.491-0.414-0.555c0-0.182,1.185-0.547,1.777-0.547c0.295,0,1.105-0.215,1.801-0.476
		c0.695-0.262,2.298-0.619,3.563-0.793c2.052-0.284,2.486-0.287,4.024-0.033c0.949,0.158,2.256,0.345,2.905,0.417
		s1.799,0.301,2.554,0.508c1.755,0.481,2.897,0.476,4.857-0.024c0.866-0.221,1.741-0.349,1.944-0.285
		c0.203,0.065,0.55,0.021,0.77-0.095c0.303-0.162,0.537-0.143,0.969,0.08c0.39,0.203,0.872,0.259,1.527,0.183
		c0.691-0.082,1.297,0.003,2.181,0.301c0.673,0.228,1.421,0.414,1.661,0.414c0.426,0,0.428,0.007,0.056,0.281
		c-0.365,0.27-0.366,0.288-0.023,0.42c0.197,0.078,0.882,0.084,1.522,0.019c1.155-0.121,1.165-0.118,1.487,0.505l0.324,0.627
		l0.421-0.528c0.242-0.303,0.817-0.647,1.352-0.808c0.808-0.243,1.053-0.243,1.866,0.006c0.573,0.175,1.259,0.239,1.772,0.165
		c0.654-0.095,0.958-0.036,1.385,0.269c0.539,0.384,0.553,0.384,1.031,0.013c0.267-0.207,0.815-0.468,1.219-0.58
		c0.967-0.27,1.295-0.845,0.715-1.251c-0.411-0.288-0.393-0.309,0.687-0.839c0.805-0.395,1.168-0.484,1.325-0.328
		c0.157,0.157,0.155,0.33-0.006,0.631c-0.274,0.514,0.067,1.29,0.496,1.125c0.153-0.059,0.409-0.058,0.567,0.003
		c0.249,0.095,0.245,0.176-0.029,0.594c-0.757,1.155,0.939,0.828,2.088-0.403c0.466-0.5,1.19-0.652,1.868-0.392
		c0.438,0.168,0.397,0.306-0.329,1.101c-0.864,0.948-1.306,1.142-2.604,1.142c-0.627,0-1.368,0.136-1.713,0.314
		c-0.478,0.247-0.71,0.268-1.087,0.096c-0.702-0.32-0.947-0.053-0.443,0.483l0.43,0.458l-0.858,0.243
		c-0.472,0.134-1.256,0.256-1.743,0.272c-0.707,0.022-0.886,0.1-0.887,0.378c-0.001,0.239-0.344,0.49-1.083,0.792
		c-2.391,0.977-4.418,2.739-3.684,3.2c0.138,0.086,0.356,0.399,0.485,0.694c0.149,0.339,0.436,0.576,0.783,0.645
		c0.302,0.06,1.08,0.372,1.729,0.692c0.649,0.319,1.446,0.639,1.771,0.71c0.894,0.194,0.901,0.215,0.485,1.271
		c-0.356,0.903-0.364,1.033-0.087,1.566c0.166,0.32,0.41,0.579,0.542,0.575c0.645-0.019,1.25-0.613,1.625-1.599
		c0.358-0.94,0.521-1.115,1.549-1.665c1.516-0.81,2.147-1.607,1.979-2.503c-0.109-0.584-0.052-0.72,0.447-1.076
		c0.314-0.224,0.731-0.761,0.925-1.192c0.436-0.964,1.134-1.299,2.313-1.106c1.045,0.171,2.281,0.834,2.431,1.304
		c0.061,0.193,0.01,0.541-0.113,0.771c-0.187,0.35-0.151,0.497,0.211,0.884l0.437,0.464l0.712-0.369
		c0.392-0.203,1.02-0.599,1.395-0.88l0.683-0.511l0.16,0.433c0.088,0.239,0.113,0.557,0.056,0.707
		c-0.058,0.151-0.013,0.445,0.1,0.655c0.126,0.236,0.136,0.503,0.025,0.701c-0.124,0.223-0.041,0.561,0.276,1.118
		c0.454,0.8,0.454,0.8,0.055,1.079c-0.524,0.368-0.188,0.757,0.388,0.449c0.571-0.306,1.225-0.266,1.629,0.099
		c0.596,0.541,0.101,1.186-1.482,1.93c-1.722,0.811-2.49,0.971-4.737,0.991c-1.66,0.015-1.88,0.068-3.494,0.841
		c-0.947,0.454-1.722,0.903-1.722,0.999c0,0.406,0.298,0.37,1.443-0.171c0.675-0.319,1.544-0.575,1.958-0.575
		c0.707,0,0.733,0.022,0.512,0.434c-0.128,0.239-0.404,0.574-0.613,0.744c-0.329,0.267-0.349,0.378-0.143,0.831
		c0.309,0.678,1.116,1.004,2.082,0.841l0.762-0.129l-0.586,0.405c-0.322,0.223-0.854,0.463-1.181,0.533
		c-0.327,0.07-0.999,0.332-1.493,0.581c-0.501,0.252-1.005,0.387-1.141,0.303c-0.311-0.193,0.018-0.481,1.03-0.904
		c0.899-0.376,0.756-0.775-0.214-0.593c-0.377,0.07-1.602,0.532-2.722,1.024c-1.747,0.771-2.052,0.974-2.154,1.437
		c-0.125,0.57-0.552,0.838-2.051,1.295c-0.479,0.146-1.099,0.498-1.378,0.781c-0.279,0.285-0.814,0.71-1.188,0.946
		c-0.375,0.236-0.732,0.65-0.797,0.919c-0.104,0.444-0.129,0.454-0.26,0.112c-0.247-0.641-0.617-0.015-0.639,1.078
		c-0.022,1.119-0.589,1.883-1.834,2.468c-1.276,0.599-3.091,2.017-3.459,2.699c-0.325,0.603-0.338,0.838-0.138,2.388
		c0.187,1.446,0.176,1.809-0.07,2.284c-0.184,0.355-0.398,0.524-0.576,0.456c-0.422-0.162-0.938-1.899-0.794-2.671
		c0.101-0.535,0.034-0.739-0.365-1.114c-0.322-0.302-0.619-0.417-0.872-0.337c-0.21,0.067-0.807-0.06-1.325-0.283
		c-0.794-0.34-1.048-0.368-1.614-0.181c-0.385,0.126-0.767,0.427-0.892,0.702c-0.186,0.408-0.31,0.46-0.823,0.35
		c-0.333-0.071-0.844-0.252-1.136-0.401c-0.72-0.368-1.123-0.343-1.932,0.122c-0.377,0.217-1.146,0.632-1.71,0.923
		c-0.647,0.333-1.093,0.713-1.209,1.033c-0.101,0.276-0.464,1.359-0.806,2.403l-0.622,1.899l0.318,1.226
		c0.583,2.251,1.396,2.926,3.087,2.563c1.275-0.273,2.041-0.827,2.322-1.681c0.179-0.541,0.372-0.72,0.949-0.881
		c0.869-0.241,1.231-0.25,1.565-0.039c0.276,0.175,0.179,0.463-1.054,3.132c-0.846,1.831-0.785,1.995,0.664,1.786
		c1.32-0.191,2.527-0.044,2.669,0.323c0.055,0.143-0.035,1.11-0.199,2.148c-0.294,1.856-0.292,1.898,0.131,2.583
		c0.237,0.382,0.68,0.825,0.985,0.982c0.487,0.252,0.641,0.251,1.254-0.005c0.975-0.407,1.504-0.364,2.181,0.178
		c0.541,0.433,0.617,0.445,1.042,0.166c0.253-0.165,0.597-0.575,0.766-0.911c0.38-0.757,1.021-1.289,1.558-1.295
		c0.225-0.002,0.616-0.226,0.871-0.497c0.517-0.549,1.425-0.728,0.981-0.193c-0.373,0.449-0.586,1.894-0.346,2.344
		c0.2,0.373,0.216,0.371,0.581-0.079c0.259-0.32,0.311-0.525,0.17-0.667c-0.338-0.338-0.237-0.619,0.392-1.083
		c0.653-0.483,1.158-0.434,1.722,0.169c0.413,0.441,2.619,1.038,3.251,0.88c0.963-0.242,3.564,1.34,4.542,2.761
		c0.465,0.677,1.952,1.358,2.606,1.193c1.345-0.337,3.188,1.01,3.62,2.646c0.128,0.486,0.322,0.94,0.43,1.008
		c0.287,0.178-0.143,0.885-1.083,1.779c-0.601,0.572-0.744,0.818-0.575,0.987c0.169,0.169,0.447,0.021,1.114-0.594
		c0.97-0.895,1.294-0.985,1.78-0.499c0.295,0.295,0.287,0.356-0.119,0.786c-0.241,0.257-0.578,0.473-0.749,0.48
		c-0.18,0.007-0.107,0.093,0.172,0.205c0.385,0.154,0.585,0.11,0.984-0.212c1.039-0.839,1.283-0.88,2.206-0.364
		c0.6,0.336,0.84,0.59,0.81,0.861c-0.084,0.75,0.093,0.917,0.613,0.576c0.414-0.271,0.698-0.284,2.081-0.095
		c1.472,0.202,1.664,0.282,2.37,0.988c0.524,0.524,1.114,0.869,1.854,1.084c0.995,0.289,1.103,0.377,1.283,1.047
		c0.261,0.972-0.045,2.049-0.874,3.072c-0.354,0.438-0.788,1.079-0.964,1.427c-0.176,0.347-0.551,0.861-0.833,1.144
		c-0.478,0.478-0.504,0.597-0.385,1.73c0.232,2.214-0.851,6.345-1.895,7.228c-0.34,0.288-0.631,0.355-1.17,0.271
		c-0.444-0.068-0.772-0.024-0.858,0.116c-0.077,0.125-0.507,0.388-0.955,0.585s-1.115,0.614-1.482,0.929
		c-0.636,0.543-0.657,0.603-0.443,1.251c0.247,0.747,0.081,1.631-0.448,2.386c-0.171,0.244-0.311,0.659-0.311,0.923
		s-0.205,0.753-0.456,1.087s-0.735,1.102-1.076,1.704c-0.838,1.481-1.342,1.737-2.557,1.302c-1.055-0.379-1.617-0.423-1.617-0.13
		c0,0.111,0.21,0.297,0.467,0.414c0.257,0.117,0.658,0.594,0.89,1.059c0.346,0.693,0.383,0.934,0.203,1.331
		c-0.28,0.613-1.349,1.128-2.342,1.128c-0.88,0-1.098,0.215-0.794,0.783c0.188,0.35,0.062,0.915-0.301,1.347
		c-0.029,0.035-0.338-0.083-0.685-0.264c-0.829-0.428-1.123-0.154-0.704,0.655c0.17,0.33,0.495,0.657,0.72,0.729
		c0.388,0.123,0.392,0.149,0.077,0.499c-0.184,0.202-0.279,0.511-0.212,0.685c0.072,0.187-0.088,0.592-0.393,0.991
		c-0.495,0.648-0.501,0.694-0.185,1.177c0.182,0.276,0.464,0.554,0.628,0.617c0.593,0.228,0.691,1.005,0.239,1.89
		c-0.453,0.888-0.522,1.777-0.172,2.199C-214.706,425.066-215.778,425.814-216.26,425.544L-216.26,425.544z M-230.763,340.314
		c0.526-1.099,1.504-1.917,2.292-1.917c0.568,0,0.575,0.092,0.055,0.753l-0.409,0.519l0.419,0.339
		c0.337,0.273,0.512,0.297,0.896,0.122c0.263-0.12,0.532-0.389,0.598-0.598c0.091-0.285,0.238-0.351,0.584-0.265
		c0.321,0.082,0.521,0.012,0.654-0.224c0.4-0.714-0.618-1.629-1.367-1.228c-0.536,0.287-1.237-0.284-1.087-0.885
		c0.055-0.223-0.059-0.612-0.256-0.872c-0.407-0.537-1.025-0.593-2.38-0.218c-0.486,0.135-1.042,0.24-1.234,0.232
		c-0.191-0.007-0.297,0.07-0.234,0.172c0.063,0.102,0.043,0.188-0.045,0.189s-0.38,0.174-0.649,0.38l-0.49,0.376l0.55,0.208
		c0.43,0.164,0.684,0.141,1.161-0.106c0.561-0.291,0.665-0.291,1.277,0.002c0.369,0.175,0.873,0.265,1.128,0.201
		c0.253-0.063,0.515-0.027,0.582,0.081c0.068,0.109-0.209,0.25-0.633,0.322c-1.151,0.194-1.75,0.673-2.585,2.072
		c-0.697,1.168-0.737,1.308-0.459,1.617c0.272,0.303,0.348,0.308,0.722,0.046C-231.443,341.474-231.033,340.879-230.763,340.314
		L-230.763,340.314z M-227.187,341.529c0.7-0.358,0.768-0.448,0.521-0.695c-0.247-0.246-0.403-0.23-1.206,0.125
		c-0.943,0.417-1.243,0.979-0.521,0.979C-228.173,341.938-227.63,341.754-227.187,341.529z M-223.698,340.041
		c0.41-0.287,0.425-0.344,0.136-0.506c-0.425-0.238-1.69,0.173-1.69,0.548C-225.253,340.473-224.275,340.445-223.698,340.041z
		 M-208.919,425.022c0-0.086,0.106-0.263,0.236-0.394c0.129-0.13,0.162-0.236,0.071-0.236s-0.271,0.146-0.401,0.322
		c-0.187,0.255-0.315,0.277-0.612,0.105c-0.212-0.123-0.264-0.222-0.119-0.225c0.142-0.003,0.203-0.094,0.136-0.202
		c-0.128-0.208,0.209-0.265,1.181-0.198c0.867,0.059,0.954,0.186,0.418,0.607C-208.508,425.195-208.919,425.294-208.919,425.022
		L-208.919,425.022z M-44.575,420.083c-0.563-0.357,0.609-1.144,3.517-2.362c0.812-0.34,2.049-1.052,2.748-1.581
		c1.19-0.899,1.289-0.939,1.525-0.602c0.358,0.512,0.147,0.882-0.728,1.275c-0.418,0.189-0.944,0.555-1.168,0.812
		c-0.225,0.258-0.586,0.47-0.803,0.47s-0.726,0.298-1.132,0.663C-42.006,420.006-43.767,420.595-44.575,420.083L-44.575,420.083z
		 M-58.348,417.527c-0.204-0.129-0.216-0.264-0.049-0.575c0.119-0.223,0.217-0.683,0.217-1.021c0-0.554,0.045-0.601,0.443-0.462
		c0.243,0.086,0.752,0.168,1.131,0.182s0.757,0.039,0.841,0.054c0.326,0.062-0.545,1.054-1.329,1.513
		C-58.027,417.765-57.988,417.755-58.348,417.527L-58.348,417.527z M-35.609,415.806c-0.068-0.068,0.094-0.296,0.36-0.506
		c0.401-0.315,0.448-0.45,0.273-0.777c-0.254-0.475,0.123-0.984,0.997-1.346c0.678-0.281,0.848-0.849,0.666-2.223
		c-0.125-0.945-0.109-1.004,0.194-0.753c0.239,0.199,0.299,0.448,0.213,0.901c-0.094,0.499-0.009,0.757,0.406,1.25
		c0.448,0.533,0.582,0.591,0.931,0.404c0.348-0.185,0.389-0.169,0.279,0.117c-0.071,0.183-0.128,0.369-0.128,0.411
		c0,0.043-0.288,0.225-0.64,0.405c-0.353,0.18-1.038,0.656-1.525,1.059c-0.792,0.653-1.607,1.182-1.823,1.182
		C-35.448,415.931-35.539,415.875-35.609,415.806L-35.609,415.806z M-56.417,413.265c-0.415-0.441-0.491-0.458-0.889-0.197
		c-0.508,0.333-1.461,0.139-2.226-0.453c-0.407-0.316-0.436-0.418-0.226-0.811c0.18-0.335,0.186-0.57,0.023-0.926
		c-0.12-0.262-0.218-0.649-0.218-0.861c0-0.476-0.309-0.5-0.565-0.041c-0.105,0.188-0.248,0.286-0.317,0.217
		c-0.068-0.068,0.16-0.449,0.507-0.845c0.855-0.975,0.521-1.238-0.607-0.482c-0.487,0.327-0.917,0.683-0.955,0.792
		c-0.039,0.11-0.272-0.228-0.521-0.75c-0.269-0.564-0.731-1.133-1.147-1.408c-0.625-0.412-0.837-0.446-2.038-0.326
		c-1.762,0.175-3.461,0.609-4.574,1.169c-0.505,0.254-1.492,0.514-2.253,0.594c-0.745,0.078-1.929,0.358-2.632,0.623
		c-1.074,0.405-1.381,0.447-1.925,0.268c-0.73-0.241-1.033-0.738-0.562-0.92c0.171-0.065,0.482-0.517,0.69-1.003
		c0.334-0.778,0.353-1.021,0.154-2.026c-0.164-0.832-0.168-1.621-0.017-2.901c0.114-0.967,0.158-1.949,0.097-2.182
		c-0.06-0.233,0.034-0.803,0.212-1.266c0.313-0.818,0.388-0.872,2.499-1.766c1.197-0.507,2.423-0.922,2.724-0.922
		c0.813,0,1.82-0.774,2.375-1.826c0.344-0.65,0.582-0.897,0.788-0.817c0.574,0.222,0.688,0.178,0.644-0.248
		c-0.028-0.274,0.09-0.473,0.323-0.547c0.202-0.065,0.577-0.423,0.832-0.796c0.262-0.383,0.798-0.814,1.229-0.987
		c0.718-0.288,0.789-0.284,1.18,0.071c0.722,0.655,1.232,0.846,1.477,0.552c0.118-0.143,0.159-0.315,0.09-0.385
		c-0.225-0.226,0.806-1.519,1.31-1.643c0.271-0.066,0.826-0.289,1.235-0.494c0.637-0.318,0.86-0.34,1.573-0.148
		c1.539,0.413,1.59,0.48,1.018,1.346c-0.735,1.112-0.54,1.546,1.265,2.817c0.833,0.587,1.62,1.032,1.748,0.989
		c0.129-0.042,0.533-0.491,0.9-0.998c0.501-0.69,0.695-1.191,0.775-2.003c0.114-1.151,0.665-2.755,0.947-2.755
		c0.094,0,0.308,0.474,0.476,1.053c0.169,0.58,0.521,1.255,0.784,1.502c0.615,0.578,0.972,2.191,0.695,3.145
		c-0.182,0.632-0.147,0.733,0.479,1.378c0.372,0.381,0.96,1.314,1.307,2.072s0.973,1.83,1.391,2.382l0.76,1.004l-0.216,1.553
		c-0.157,1.126-0.399,1.877-0.884,2.734c-0.741,1.313-1.851,2.567-2.436,2.753c-0.209,0.067-0.696,0.56-1.081,1.095
		c-0.386,0.536-0.787,0.975-0.893,0.975c-0.106,0-0.556,0.46-1,1.021c-0.742,0.938-0.878,1.023-1.65,1.039
		c-0.592,0.011-1.046,0.171-1.532,0.542l-0.691,0.527L-56.417,413.265L-56.417,413.265z M-152.375,409.11
		c-0.417-0.385-0.486-0.622-0.394-1.195c0.088-0.541,0.004-0.854-0.37-1.405c-0.266-0.391-0.483-0.805-0.483-0.923
		s-0.348-0.68-0.773-1.25c-0.657-0.881-0.792-1.233-0.901-2.348c-0.191-1.966-0.819-4.095-1.663-5.638
		c-0.415-0.759-0.814-1.744-0.886-2.189c-0.154-0.947,0.231-2.357,1.109-4.066l0.584-1.136l-0.329-0.988
		c-0.184-0.554-0.273-1.208-0.204-1.485c0.082-0.326-0.006-0.713-0.254-1.122c-0.208-0.344-0.517-1.113-0.687-1.708
		c-0.227-0.794-0.542-1.313-1.191-1.948c-1.247-1.224-1.409-1.705-1.022-3.037c0.788-2.711-0.063-4.292-2.143-3.979
		c-0.81,0.121-0.84,0.106-0.955-0.466c-0.088-0.438-0.326-0.697-0.923-1.002c-0.776-0.396-1.108-0.397-2.062-0.009
		c-0.216,0.088-1.102,0.394-1.968,0.678c-1.302,0.43-1.693,0.485-2.26,0.321c-0.537-0.156-0.989-0.115-2.088,0.186
		c-1.135,0.311-1.547,0.345-2.16,0.175c-1.28-0.353-3.479-2.334-3.792-3.417c-0.242-0.837-1.748-2.79-2.26-2.93
		c-0.326-0.089-0.366-0.159-0.167-0.288c0.201-0.129,0.166-0.194-0.147-0.276c-0.498-0.129-0.547-0.536-0.065-0.541
		c0.294-0.003,0.301-0.03,0.049-0.194c-0.186-0.12-0.207-0.191-0.056-0.193c0.156-0.002,0.097-0.244-0.171-0.699
		c-0.377-0.64-0.385-0.724-0.097-1.042c0.88-0.974,1.073-3.068,0.404-4.388c-0.319-0.63-0.114-1.5,0.568-2.405
		c0.256-0.341,0.758-1.17,1.113-1.842c0.623-1.176,2.01-2.812,2.386-2.812c0.416,0,1.756-1.441,1.756-1.888
		c0-1.009,0.603-2.014,1.586-2.645c0.528-0.34,1.147-0.92,1.376-1.289c0.467-0.756,0.533-0.78,1.077-0.401
		c0.213,0.148,0.744,0.33,1.179,0.403c0.663,0.111,0.98,0.032,1.968-0.498c1.699-0.908,2.749-1.135,4.694-1.014
		c1.282,0.081,1.859,0.03,2.48-0.22c0.916-0.366,1.707-0.149,1.493,0.409c-0.065,0.167-0.025,0.416,0.087,0.551
		c0.141,0.17,0.14,0.415-0.003,0.793c-0.181,0.475-0.141,0.618,0.308,1.084c0.283,0.296,0.791,0.593,1.127,0.66
		c1.582,0.316,2.031,0.522,2.357,1.074c0.24,0.405,0.553,0.613,1.072,0.71c0.403,0.076,0.989,0.271,1.301,0.432
		c0.531,0.274,0.599,0.264,1.042-0.151c0.298-0.28,0.433-0.576,0.363-0.795c-0.061-0.192-0.015-0.531,0.104-0.751
		c0.305-0.569,1.168-0.514,2.519,0.163c1.471,0.735,4.259,1.261,5.248,0.988c0.476-0.131,0.933-0.133,1.35-0.005
		c1.359,0.418,2.056,0.091,2.241-1.053c0.062-0.379,0.167-0.691,0.234-0.691c0.127,0,0.193-0.571,0.212-1.82
		c0.006-0.406,0.102-0.738,0.213-0.738c0.133,0,0.127-0.143-0.019-0.413c-0.242-0.453-0.732-0.441-1.907,0.04
		c-0.557,0.229-0.712,0.22-1.211-0.075c-0.647-0.383-1.312-0.439-1.503-0.13c-0.143,0.231-1.149,0.069-1.694-0.271
		c-0.393-0.245-0.759-0.974-0.759-1.509c0-0.206-0.199-0.514-0.442-0.686c-0.377-0.266-0.399-0.34-0.147-0.504
		c0.162-0.107,0.496-0.146,0.742-0.089c0.313,0.074,0.602-0.05,0.965-0.413c0.343-0.343,0.759-0.532,1.226-0.557
		c0.389-0.021,0.952-0.199,1.252-0.397c0.649-0.428,2.126-0.309,3.271,0.265c1.544,0.773,2.917,0.728,3.469-0.116
		c0.287-0.438,0.288-0.53,0.017-0.946c-0.167-0.253-0.964-0.837-1.773-1.298l-1.471-0.836l0.275-0.533
		c0.152-0.293,0.227-0.583,0.165-0.643c-0.06-0.061-0.037-0.228,0.052-0.371s0.18-0.373,0.2-0.513
		c0.045-0.298-0.272-0.216-1.837,0.476c-0.909,0.402-1.044,0.525-0.842,0.768c0.132,0.159,0.429,0.293,0.658,0.298
		c0.323,0.006,0.247,0.1-0.335,0.406c-0.705,0.372-0.784,0.377-1.243,0.076c-0.392-0.256-0.443-0.376-0.257-0.6
		c0.185-0.223,0.156-0.308-0.135-0.417c-0.201-0.077-0.462-0.265-0.577-0.419c-0.563-0.751-2.352,1.225-2.873,3.174
		c-0.244,0.915-0.235,0.962,0.224,1.309l0.477,0.359l-0.875,0.181c-0.481,0.099-1.13,0.111-1.441,0.025
		c-0.575-0.157-1.303,0.08-1.303,0.423c0,0.101-0.134,0.132-0.298,0.069c-0.164-0.062-0.424-0.009-0.578,0.119
		c-0.239,0.198-0.211,0.36,0.184,1.105c0.428,0.806,0.442,0.916,0.174,1.452c-0.335,0.672-0.646,0.622-1.12-0.179
		c-0.33-0.558-0.327-0.572,0.109-0.634c0.551-0.078,0.586-0.428,0.052-0.514c-1.219-0.195-1.87-0.838-1.87-1.847
		c0-0.789-0.73-1.657-1.616-1.922c-0.46-0.138-0.96-0.428-1.112-0.644c-0.151-0.215-0.594-0.526-0.984-0.688
		c-0.39-0.163-0.826-0.464-0.97-0.669c-0.206-0.294-0.341-0.329-0.643-0.168c-0.21,0.113-0.382,0.332-0.382,0.486
		c0,0.511,2.508,2.822,3.735,3.441c1.299,0.656,1.503,0.89,0.6,0.692c-0.805-0.177-1.024,0-0.75,0.604
		c0.194,0.425,0.162,0.554-0.227,0.928l-0.452,0.435l0.103-0.587c0.163-0.93-1.063-2.267-2.082-2.267
		c-0.373,0-1.806-1.391-2.181-2.116c-0.401-0.776-0.848-0.926-1.48-0.498c-0.953,0.646-1.667,0.865-2.414,0.739
		c-0.993-0.168-1.617,0.178-1.458,0.809c0.083,0.329,0.037,0.475-0.149,0.475c-0.148,0-0.526,0.222-0.84,0.492
		c-0.314,0.271-0.64,0.492-0.722,0.492c-0.244,0.001-0.931,1.244-0.932,1.686c-0.001,0.422-0.771,1.254-1.513,1.636
		c-0.236,0.122-0.787,0.222-1.223,0.222c-0.6,0-0.846,0.099-1.011,0.406c-0.216,0.403-0.222,0.402-0.727-0.103
		c-0.473-0.473-0.746-0.591-1.7-0.739c-0.502-0.078-0.586-1.213-0.211-2.831c0.161-0.691,0.239-1.467,0.175-1.722
		c-0.186-0.744,0.397-1.181,1.374-1.031c2.254,0.347,3.574,0.365,3.883,0.056c0.425-0.424,0.617-1.825,0.357-2.611
		c-0.169-0.512-0.401-0.721-1.095-0.987c-1.021-0.39-1.042-0.766-0.044-0.776c0.38-0.004,0.928-0.163,1.218-0.353
		c0.291-0.189,0.611-0.294,0.713-0.231c0.278,0.172,1.332-0.436,1.682-0.969c0.169-0.258,0.501-0.519,0.74-0.58
		c0.238-0.061,0.875-0.523,1.417-1.027c0.767-0.715,1.169-0.938,1.828-1.017c0.814-0.098,1.634-0.768,1.25-1.022
		c-0.239-0.158-0.439-1.111-0.306-1.458c0.063-0.165,0.342-0.448,0.619-0.63l0.504-0.331l-0.129,0.81
		c-0.113,0.703-0.083,0.802,0.217,0.752c0.191-0.032,0.547-0.168,0.792-0.303c0.374-0.205,0.465-0.194,0.564,0.062
		c0.168,0.439-0.301,0.957-0.625,0.688c-0.178-0.149-0.292-0.144-0.392,0.018c-0.103,0.167-0.184,0.158-0.302-0.035
		c-0.089-0.143-0.207-0.215-0.262-0.161c-0.056,0.056,0.102,0.355,0.35,0.666c0.417,0.523,0.497,0.547,1.091,0.341
		c0.51-0.178,0.754-0.166,1.188,0.058c0.49,0.253,0.655,0.241,1.595-0.116c0.825-0.313,1.149-0.353,1.514-0.187
		c0.335,0.152,0.584,0.149,0.896-0.011c0.885-0.458,1.13-0.803,1.021-1.436c-0.163-0.938,0.136-1.245,0.921-0.949
		c0.535,0.202,0.691,0.194,0.933-0.048c0.256-0.256,0.233-0.35-0.186-0.796c-0.403-0.429-0.432-0.536-0.186-0.702
		c0.16-0.108,0.757-0.146,1.329-0.086c1.045,0.111,2.347-0.297,2.121-0.664c-0.199-0.322-1.398-0.414-2.415-0.188
		c-0.902,0.202-1.138,0.186-1.829-0.126c-1.073-0.483-1.199-1.05-0.463-2.075c0.947-1.32,1.121-1.742,0.851-2.066
		c-0.399-0.482-0.792-0.324-1.705,0.682c-0.483,0.532-1.14,1.076-1.461,1.208c-0.321,0.133-0.718,0.502-0.882,0.819
		c-0.268,0.518-0.267,0.625,0.004,1.04c0.167,0.254,0.482,0.519,0.702,0.589c0.603,0.191,0.484,0.551-0.379,1.142
		c-0.266,0.182-0.62,0.696-0.788,1.142c-0.209,0.561-0.528,0.942-1.025,1.229c-0.702,0.405-0.733,0.407-1.121,0.056
		c-0.219-0.198-0.452-0.604-0.517-0.899c-0.065-0.297-0.361-0.829-0.657-1.184l-0.538-0.645l-0.683,0.416
		c-0.375,0.229-0.86,0.415-1.077,0.413c-0.624-0.006-1.347-0.681-1.396-1.302c-0.024-0.309-0.104-0.714-0.179-0.899
		c-0.176-0.441,0.705-1.268,1.621-1.521c0.919-0.253,2.783-1.56,3.102-2.173c0.141-0.273,0.62-0.821,1.063-1.219
		c0.444-0.399,0.807-0.812,0.807-0.919c0-0.107,0.42-0.345,0.935-0.528c0.514-0.183,1.377-0.516,1.918-0.738
		c0.664-0.275,1.495-0.419,2.558-0.447c1.857-0.047,3.072,0.199,4.075,0.826c0.502,0.314,0.899,0.422,1.279,0.346
		c0.68-0.135,4.302,0.987,4.452,1.38c0.152,0.395-0.888,0.768-1.781,0.64c-0.408-0.059-1.256-0.217-1.881-0.351
		c-0.842-0.18-1.194-0.185-1.358-0.021c-0.164,0.164-0.082,0.285,0.316,0.466c0.295,0.134,0.665,0.492,0.821,0.795
		c0.398,0.77,1.675,1.397,2.333,1.147c0.633-0.24,0.628-0.316-0.039-0.678c-0.618-0.335-0.333-0.408,0.442-0.113
		c0.619,0.235,1.32-0.058,1.157-0.483c-0.076-0.199,0.138-0.343,0.745-0.499c1.298-0.333,1.393-0.38,1.285-0.644
		c-0.407-0.996-0.413-0.968,0.18-0.899c0.316,0.036,0.548,0.172,0.521,0.305c-0.025,0.132,0.139,0.34,0.366,0.461
		c0.342,0.183,0.611,0.118,1.55-0.375c1.378-0.722,2.45-0.958,2.85-0.625c0.223,0.185,0.593,0.186,1.663,0.006
		c0.756-0.127,1.462-0.179,1.566-0.114c0.105,0.065,0.25,0.024,0.32-0.091c0.155-0.251-0.274-0.82-0.508-0.675
		c-0.284,0.175-1.215-0.138-1.013-0.341c0.204-0.205,5.681,1.072,6.009,1.4c0.221,0.221,0.92-0.073,0.92-0.388
		c0-0.106-0.391-0.382-0.867-0.614c-1.48-0.719-1.576-1.871-0.184-2.22c0.808-0.203,1.157,0.03,2.273,1.522
		c1.451,1.937,1.414,1.838,0.94,2.504c-0.351,0.492-0.372,0.608-0.128,0.702c0.461,0.177,1.448-0.442,1.588-0.997
		c0.162-0.648-0.142-1.202-0.868-1.577c-0.57-0.295-0.983-0.836-0.983-1.288c0-0.155,0.214-0.192,0.64-0.115
		c0.638,0.117,1.165-0.115,2.609-1.145c0.331-0.235,0.89-0.391,1.453-0.403c1.142-0.026,1.512-0.218,1.183-0.614
		c-0.129-0.155-0.193-0.323-0.144-0.374c0.252-0.251,3.491-0.704,4.237-0.592c0.718,0.108,0.94,0.05,1.531-0.401
		c0.38-0.29,0.69-0.605,0.69-0.701c0-0.24-1.719-0.218-1.869,0.023c-0.206,0.334-0.474,0.218-0.398-0.173
		c0.064-0.33-0.103-0.383-1.536-0.495c-3.914-0.304-4.231-0.375-3.763-0.846c0.413-0.413,1.425-0.405,2.272,0.019
		c0.348,0.173,0.923,0.314,1.278,0.314c0.356,0,0.702,0.088,0.77,0.197c0.066,0.108,0.411,0.196,0.765,0.196
		c0.982,0,2.88,0.424,2.762,0.616c-0.059,0.095,0.125,0.171,0.407,0.171s0.797,0.153,1.143,0.339c0.466,0.251,1.117,0.341,2.5,0.347
		c1.485,0.005,1.98,0.082,2.41,0.372c0.298,0.201,0.54,0.421,0.538,0.489c-0.002,0.069-0.455,0.375-1.009,0.681
		c-1.337,0.738-1.054,1.059,0.563,0.64c1.048-0.272,1.288-0.273,2.154-0.016c0.713,0.211,1.156,0.238,1.64,0.1
		c0.48-0.138,0.976-0.108,1.814,0.109c0.875,0.227,1.329,0.25,1.883,0.096c1.267-0.351,2.824-0.252,3.728,0.238
		c0.45,0.243,1.277,0.694,1.841,1.001c0.998,0.545,1.041,0.55,1.734,0.24c0.579-0.26,0.903-0.281,1.758-0.118
		c0.85,0.163,1.158,0.143,1.637-0.105c0.469-0.242,0.544-0.361,0.365-0.576c-0.346-0.417,0.88-0.572,2.387-0.302
		c0.689,0.124,1.564,0.248,1.942,0.275c0.38,0.027,1.119,0.243,1.646,0.479c0.802,0.361,1.406,0.443,3.753,0.508
		c2.226,0.061,2.935,0.15,3.472,0.437c0.545,0.29,1.166,0.364,3.232,0.384c1.901,0.019,2.753,0.109,3.317,0.351
		c0.911,0.392,1.275,0.248,0.779-0.307c-0.356-0.399-0.354-0.399,1.021-0.263c2.304,0.229,5.066,0.732,6.002,1.095
		c0.486,0.188,1.415,0.408,2.063,0.486c0.647,0.079,1.593,0.355,2.1,0.612c0.77,0.39,1.021,0.432,1.515,0.26
		c0.677-0.236,1.115-0.066,2.147,0.831l0.642,0.557l-0.484,0.122c-0.484,0.122-1.772-0.197-2.217-0.549
		c-0.131-0.104-0.313-0.14-0.407-0.083c-0.093,0.058-0.554-0.027-1.022-0.189c-1.1-0.378-1.587-0.236-1.087,0.316
		c0.342,0.378,0.335,0.386-0.318,0.341c-1.22-0.085-0.884,0.274,0.908,0.973c0.973,0.381,1.813,0.731,1.863,0.781
		c0.184,0.178-0.424,0.4-0.868,0.315c-0.486-0.092-1.685,0.429-1.685,0.733c0,0.102-0.189,0.361-0.421,0.577
		c-0.387,0.36-0.467,0.368-0.983,0.101c-0.484-0.25-0.639-0.252-1.105-0.019c-0.297,0.15-0.806,0.337-1.13,0.414
		c-0.324,0.079-0.7,0.234-0.834,0.346c-0.396,0.329,0.302,1.285,1.286,1.761c0.535,0.259,0.923,0.607,1.038,0.931
		c0.102,0.284,0.315,0.751,0.478,1.038c0.263,0.466,0.259,0.533-0.022,0.641c-0.207,0.08-0.268,0.238-0.177,0.463
		c0.075,0.189,0.16,0.564,0.188,0.835c0.029,0.271,0.115,0.639,0.192,0.819c0.101,0.234,0.018,0.365-0.292,0.464
		c-0.346,0.11-0.578-0.022-1.146-0.65c-0.392-0.433-1.045-1.029-1.452-1.325c-0.407-0.296-1.136-1.065-1.62-1.709
		c-0.854-1.137-0.871-1.188-0.589-1.737c0.161-0.31,0.296-0.769,0.299-1.017c0.005-0.25,0.13-0.672,0.278-0.941
		c0.284-0.514,0.175-0.924-0.434-1.621c-0.398-0.458-1.107-0.115-0.929,0.449c0.165,0.52-0.342,0.688-0.924,0.307
		c-0.252-0.165-0.833-0.36-1.29-0.433c-0.786-0.126-0.844-0.102-1.048,0.433c-0.119,0.311-0.168,0.803-0.11,1.092
		c0.09,0.453,0.027,0.544-0.438,0.647c-0.316,0.069-0.857-0.013-1.299-0.197c-0.484-0.202-0.941-0.263-1.271-0.171
		c-0.282,0.081-1.236,0.154-2.12,0.163c-1.401,0.013-1.671,0.074-2.097,0.474c-0.269,0.253-0.592,0.873-0.718,1.378
		c-0.126,0.505-0.356,1.115-0.513,1.356c-0.27,0.417-0.244,0.458,0.527,0.853c0.837,0.426,1.749,0.539,2.442,0.3
		c0.533-0.184,2.16,1.085,2.669,2.083c0.209,0.408,0.553,0.834,0.766,0.948c0.804,0.43,0.967,3.253,0.295,5.099
		c-0.466,1.276-0.987,1.695-1.751,1.404c-0.293-0.111-0.562-0.202-0.597-0.202c-0.037,0-0.256,0.363-0.487,0.807
		c-0.304,0.583-0.363,0.877-0.213,1.058c0.287,0.345,0.074,0.794-0.5,1.056c-0.264,0.121-0.48,0.305-0.48,0.41
		c0,0.105,0.616,0.615,1.368,1.132c0.957,0.658,1.469,1.165,1.704,1.685c0.184,0.409,0.299,0.776,0.255,0.816
		s-0.446,0.257-0.895,0.482l-0.813,0.411l-0.489-1.124c-0.549-1.258-1.019-1.764-1.847-1.987c-0.417-0.113-0.563-0.271-0.563-0.616
		c0-0.315-0.204-0.584-0.638-0.838c-0.632-0.37-0.644-0.369-1.316,0.046c-0.674,0.416-0.681,0.416-0.887,0.03
		c-0.115-0.214-0.209-0.525-0.209-0.69c0-0.166-0.166-0.39-0.368-0.499c-0.3-0.161-0.49-0.063-1.043,0.534
		c-0.371,0.403-0.847,0.829-1.058,0.949c-0.325,0.186-0.344,0.264-0.119,0.534c0.146,0.175,0.646,0.531,1.113,0.792
		c0.741,0.414,0.884,0.438,1.134,0.188c0.238-0.238,0.432-0.248,1.149-0.052l0.864,0.234l-0.481,0.321
		c-0.894,0.597-1.174,0.911-1.302,1.449c-0.104,0.442-0.042,0.575,0.354,0.756c0.265,0.12,0.668,0.602,0.895,1.069
		c0.228,0.468,0.682,1.008,1.009,1.203c0.72,0.425,0.929,0.877,0.513,1.11c-0.388,0.217-0.202,0.541,0.31,0.541
		c0.612,0,0.616,0.366,0.023,2.476c-0.718,2.556-1.364,3.408-3.06,4.027c-0.667,0.245-1.479,0.459-1.803,0.478
		c-0.755,0.042-1.34,0.361-1.851,1.01l-0.406,0.516l-0.189-0.498c-0.246-0.649-0.812-0.812-1.623-0.468
		c-0.744,0.314-1.234,0.815-1.433,1.465c-0.176,0.581,0.45,1.602,1.488,2.424c0.852,0.675,1.808,2.369,2.031,3.601
		c0.195,1.071-0.15,1.775-1.078,2.193c-0.422,0.19-0.817,0.504-0.879,0.695c-0.146,0.46-1.02,1.032-1.18,0.773
		c-0.067-0.109-0.069-0.361-0.007-0.561c0.064-0.201,0.062-0.509-0.006-0.684c-0.098-0.253-0.204-0.276-0.516-0.109
		c-0.323,0.173-0.448,0.129-0.71-0.25c-0.642-0.931-2.504-2.427-3.02-2.427c-0.182,0-0.252,0.219-0.221,0.689
		c0.024,0.378-0.052,1.117-0.172,1.641c-0.194,0.854-0.174,1.01,0.192,1.506c0.225,0.304,0.465,0.775,0.533,1.048
		c0.069,0.275,0.638,0.906,1.289,1.429c1.085,0.87,1.199,1.042,1.655,2.486c0.271,0.854,0.491,1.637,0.491,1.739
		c0,0.334-0.411,0.199-1.27-0.418c-0.887-0.637-1.453-1.644-1.926-3.425c-0.205-0.773-0.43-1.104-1.068-1.574
		c-0.782-0.576-0.806-0.626-0.743-1.579c0.036-0.542,0.124-1.308,0.197-1.705c0.103-0.558,0.018-0.957-0.374-1.771
		c-0.278-0.578-0.608-1.465-0.732-1.972c-0.276-1.132-0.667-1.321-1.359-0.659c-0.285,0.274-0.575,0.499-0.643,0.499
		c-0.313,0-0.743-0.667-0.64-0.993c0.187-0.59-0.966-2.649-1.641-2.929c-0.369-0.152-0.706-0.524-0.933-1.024
		c-0.393-0.871-0.833-1.203-1.272-0.962c-0.159,0.087-0.799,0.262-1.419,0.389c-1.14,0.234-1.547,0.579-1.392,1.175
		c0.057,0.22-0.142,0.431-0.619,0.659c-0.388,0.185-0.805,0.556-0.929,0.825c-0.122,0.27-0.393,0.544-0.6,0.61
		c-0.208,0.065-0.528,0.366-0.711,0.668c-0.185,0.303-0.731,0.855-1.217,1.228c-0.664,0.511-0.871,0.795-0.839,1.155
		c0.193,2.099-0.103,3.854-0.825,4.897c-0.704,1.018-0.754,1.045-1.351,0.774c-0.328-0.151-0.531-0.443-0.614-0.888
		c-0.068-0.365-0.465-1.396-0.881-2.289c-2.516-5.404-2.888-6.435-2.674-7.411c0.145-0.661-0.202-1.655-0.538-1.542
		c-0.135,0.045-0.247,0.208-0.25,0.36c-0.018,0.898-1.026,1.037-1.813,0.248l-0.523-0.522l0.45-0.167
		c0.505-0.188,0.459-0.691-0.056-0.6c-0.183,0.034-0.601-0.166-0.93-0.442c-0.328-0.277-0.672-0.502-0.766-0.502
		c-0.093,0-0.225-0.178-0.294-0.396c-0.182-0.571-1.225-1.068-1.748-0.832c-0.471,0.212-4.742,0.168-5.458-0.057
		c-0.257-0.082-0.733-0.452-1.058-0.823c-0.587-0.671-0.596-0.674-1.476-0.437c-0.835,0.225-0.956,0.204-2.079-0.364
		c-0.971-0.489-1.261-0.753-1.562-1.418c-0.382-0.848-1.23-1.462-1.747-1.263c-0.163,0.062-0.353,0.372-0.423,0.688
		c-0.1,0.455,0.003,0.75,0.484,1.406c0.337,0.457,0.702,1.169,0.814,1.583c0.238,0.889,0.712,1.153,1.104,0.618
		c0.236-0.324,0.3-0.335,0.461-0.082c0.102,0.162,0.156,0.382,0.122,0.491c-0.11,0.34,1.026,0.899,1.556,0.767
		c0.269-0.068,0.84-0.524,1.271-1.014c0.681-0.772,0.917-0.824,0.802-0.176c-0.079,0.436,0.264,0.949,0.833,1.242
		c0.878,0.455,2.102,1.466,2.102,1.737c0,0.125-0.23,0.442-0.511,0.705c-0.282,0.262-0.686,0.818-0.899,1.234
		c-0.519,1.011-2.021,2.49-2.53,2.49c-0.588,0-1.463,0.497-1.812,1.028c-0.165,0.251-0.598,0.606-0.963,0.789
		c-1.871,0.934-4.123,1.881-4.757,2c-0.678,0.127-0.732,0.097-1.092-0.611c-0.208-0.41-0.443-1.233-0.524-1.828
		c-0.104-0.774-0.307-1.267-0.712-1.729c-0.312-0.356-0.565-0.732-0.565-0.836c0-0.105-0.355-0.556-0.789-1.003
		c-0.541-0.558-0.891-1.162-1.109-1.919c-0.176-0.608-0.534-1.372-0.796-1.697c-1.806-2.242-2.34-3.034-2.608-3.858
		c-0.357-1.095-0.659-1.272-0.799-0.468l-0.099,0.571l-0.666-0.84c-1.025-1.295-1.154-0.707-0.191,0.875
		c0.455,0.748,1.182,2.068,1.614,2.935c0.433,0.866,0.992,1.847,1.245,2.183c0.26,0.345,0.459,0.883,0.459,1.242
		c0,0.742,0.222,1.205,1.09,2.281c0.351,0.434,0.695,1.065,0.768,1.405c0.094,0.435,0.473,0.891,1.29,1.546
		c1.241,0.995,2.044,2.035,2.244,2.9c0.075,0.326,0.358,0.659,0.719,0.846c0.55,0.284,0.743,0.275,2.478-0.1
		c1.034-0.224,2.13-0.537,2.436-0.695l0.554-0.286l0.132,0.778c0.12,0.708-0.01,1.049-1.41,3.736
		c-1.507,2.888-1.577,2.984-3.074,4.167c-0.844,0.665-1.726,1.48-1.961,1.811c-0.235,0.33-0.987,1.222-1.672,1.982
		c-1.998,2.219-2.27,4.17-0.945,6.784c0.337,0.664,0.468,1.167,0.389,1.485c-0.066,0.264-0.023,0.932,0.095,1.483
		c0.187,0.869,0.165,1.102-0.157,1.732c-0.466,0.913-0.836,1.24-2.137,1.893c-0.567,0.284-1.032,0.601-1.032,0.704
		c0,0.103-0.399,0.636-0.887,1.184c-0.486,0.548-0.885,1.086-0.885,1.195s0.183,0.556,0.405,0.993
		c0.669,1.311,0.22,2.261-1.384,2.926c-0.676,0.281-0.897,0.853-0.73,1.883c0.087,0.539,0.031,0.719-0.274,0.883
		c-0.211,0.113-0.593,0.534-0.849,0.935c-0.807,1.267-3.125,3.509-4.179,4.04c-0.879,0.444-1.104,0.482-1.75,0.304
		c-0.57-0.157-1.029-0.14-1.984,0.099C-151.94,409.701-151.776,409.751-152.375,409.11L-152.375,409.11z M-126.455,346.362
		c0.508-0.264,0.581-0.408,0.581-1.157c0-0.537-0.115-0.952-0.309-1.113c-0.17-0.141-0.444-0.518-0.61-0.839
		c-0.274-0.532-0.273-0.574,0.014-0.464c0.378,0.146,0.905-0.029,0.905-0.3c0-0.106-0.184-0.452-0.407-0.766
		c-0.337-0.473-0.472-0.536-0.787-0.368c-0.255,0.137-0.381,0.138-0.381,0.004c0-0.11-0.351-0.379-0.78-0.598
		c-0.95-0.485-1.288-1.021-0.992-1.576c0.226-0.419,1.026-0.712,1.368-0.5c0.461,0.285,0.597-0.136,0.216-0.67
		c-0.922-1.296-2.207-1.211-3.801,0.252c-1.029,0.944-1.118,1.195-0.536,1.507c0.216,0.116,0.394,0.343,0.394,0.503
		s0.35,0.672,0.778,1.136c0.984,1.069,1.165,1.558,1.043,2.823l-0.098,1.018l1.352,0.694c0.743,0.381,1.378,0.698,1.411,0.703
		C-127.063,346.657-126.775,346.526-126.455,346.362L-126.455,346.362z M-131.876,401.33c-0.556-0.314-0.635-0.481-0.78-1.643
		c-0.116-0.928-0.067-1.129,0.488-2.033c0.563-0.917,0.604-1.093,0.479-2.089c-0.18-1.438,0.127-2.194,0.963-2.378
		c0.787-0.172,2.492-1.676,2.9-2.561l0.313-0.674l0.216,0.476c0.546,1.198,0.49,1.724-0.459,4.351
		c-0.508,1.406-1.054,3.087-1.211,3.736c-0.507,2.078-0.794,2.58-1.623,2.828C-131.472,401.608-131.386,401.608-131.876,401.33z
		 M-34.939,397.909c-0.628-0.687-1.005-1.276-1.005-1.574c0-0.66,0.107-0.603,0.675,0.366c0.271,0.463,0.762,1.1,1.091,1.414
		c0.328,0.314,0.597,0.644,0.597,0.731C-33.581,399.225-34,398.936-34.939,397.909L-34.939,397.909z M-122.726,397.63
		c0-0.108,0.088-0.197,0.196-0.197s0.197,0.089,0.197,0.197c0,0.108-0.089,0.197-0.197,0.197S-122.726,397.738-122.726,397.63z
		 M-19.096,397.271c-0.069-0.113-0.03-0.264,0.087-0.337c0.293-0.18,0.396-0.037,0.201,0.28
		C-18.912,397.383-19.014,397.403-19.096,397.271L-19.096,397.271z M-121.349,396.953c0-0.048,0.089-0.142,0.196-0.208
		c0.109-0.067,0.197-0.027,0.197,0.086c0,0.115-0.088,0.208-0.197,0.208C-121.26,397.039-121.349,397-121.349,396.953z
		 M-24.214,394.956c-0.19-0.5,0.052-0.674,0.595-0.428c0.468,0.214,0.479,0.246,0.152,0.485
		C-23.924,395.346-24.068,395.335-24.214,394.956z M-22.967,394.08c-0.384-0.237-0.117-0.427,0.494-0.352
		c0.479,0.06,0.49,0.081,0.148,0.293C-22.543,394.155-22.806,394.18-22.967,394.08L-22.967,394.08z M-32.155,393.357
		c-0.404-0.87-0.263-1.205,0.155-0.366c0.435,0.872,0.442,0.9,0.238,0.898C-31.842,393.889-32.02,393.649-32.155,393.357z
		 M-31.401,393.218c-0.01-0.208,0.037-0.324,0.103-0.258c0.066,0.066,0.073,0.235,0.017,0.377
		C-31.345,393.494-31.392,393.448-31.401,393.218L-31.401,393.218z M-15.381,391.329c-0.18-0.291-0.009-0.44,0.411-0.358
		c0.308,0.06,0.311,0.092,0.033,0.322C-15.158,391.477-15.283,391.487-15.381,391.329L-15.381,391.329z M-131.68,390.348
		c-0.067-0.107-0.027-0.196,0.086-0.196c0.115,0,0.209,0.089,0.209,0.196c0,0.109-0.039,0.198-0.087,0.198
		S-131.614,390.457-131.68,390.348z M-45.29,388.5c-1.245-0.393-1.81-0.758-2.3-1.486c-0.561-0.832-1.585-1.462-2.107-1.296
		c-0.212,0.067-0.568,0.432-0.792,0.812c-0.38,0.645-0.453,0.681-1.146,0.558c-0.406-0.072-0.975-0.351-1.263-0.619
		c-0.365-0.341-0.645-0.448-0.931-0.358c-0.225,0.071-0.549,0.051-0.722-0.047c-0.271-0.15-0.244-0.222,0.188-0.505
		c0.502-0.329,0.503-0.333,0.166-1.038c-0.27-0.567-0.643-0.862-1.86-1.474c-0.838-0.421-1.785-0.766-2.104-0.766
		c-0.732,0-1.035-0.674-0.483-1.077c0.327-0.24,0.279-0.3-0.576-0.715c-0.908-0.441-0.921-0.46-0.487-0.701
		c0.245-0.137,0.64-0.251,0.878-0.254c0.461-0.008,1.044,0.657,1.044,1.19c0,0.178,0.191,0.567,0.425,0.865
		c0.42,0.532,0.431,0.534,0.739,0.149c0.172-0.214,0.72-0.624,1.22-0.909l0.907-0.52l1.257,0.567c0.691,0.312,1.674,0.717,2.182,0.9
		c0.509,0.183,1.524,0.729,2.26,1.211c1.402,0.92,2.012,1.698,1.662,2.119c-0.285,0.343,0.834,1.907,1.992,2.784
		C-43.869,388.852-43.891,388.94-45.29,388.5L-45.29,388.5z M-36.727,388.484c-0.154-0.188-0.17-0.3-0.039-0.3
		c0.238,0,0.516,0.374,0.379,0.511C-36.437,388.743-36.589,388.648-36.727,388.484L-36.727,388.484z M-69.79,387.683
		c-0.285-0.158-0.367-0.274-0.196-0.277c0.351-0.004,0.913,0.324,0.781,0.456C-69.257,387.913-69.52,387.832-69.79,387.683z
		 M-66.463,387.423c0.558-0.784,1.432-1.119,2.238-0.86c0.589,0.189,0.599,0.205,0.13,0.223c-0.275,0.011-0.92,0.286-1.433,0.61
		C-66.696,388.138-66.978,388.146-66.463,387.423L-66.463,387.423z M-37.91,387.692c-0.266-0.321-0.202-0.361,0.278-0.176
		c0.172,0.066,0.312,0.199,0.312,0.295C-37.321,388.084-37.639,388.018-37.91,387.692z M-36.989,387.518
		c-0.074-0.193-0.13-0.498-0.122-0.678c0.008-0.218,0.076-0.168,0.202,0.146c0.104,0.261,0.159,0.566,0.122,0.678
		C-36.824,387.777-36.914,387.711-36.989,387.518L-36.989,387.518z M-21.775,387.704c0-0.048,0.089-0.142,0.197-0.209
		c0.108-0.066,0.196-0.027,0.196,0.088c0,0.114-0.088,0.208-0.196,0.208S-21.775,387.751-21.775,387.704z M-72.104,386.877
		c-0.137-0.058-0.197-0.185-0.137-0.285c0.151-0.243,1.412-0.23,1.565,0.017c0.065,0.108,0.005,0.197-0.137,0.199
		c-0.142,0.001-0.435,0.041-0.651,0.087C-71.68,386.943-71.967,386.935-72.104,386.877L-72.104,386.877z M-69.182,386.729
		c-0.358-0.431,0.054-0.562,0.981-0.313c0.934,0.252,0.931,0.291-0.037,0.466C-68.741,386.973-69.017,386.928-69.182,386.729z
		 M-39.636,386.61c-0.136-0.219-0.16-0.394-0.055-0.394s0.287,0.177,0.402,0.394c0.116,0.217,0.141,0.394,0.055,0.394
		S-39.5,386.827-39.636,386.61z M-38.404,386.412c-0.526-0.581-0.244-0.672,0.297-0.097c0.254,0.271,0.371,0.491,0.261,0.491
		S-38.207,386.629-38.404,386.412L-38.404,386.412z M-75.3,386.378c-0.704-0.098-1.695-0.314-2.204-0.481
		c-0.509-0.167-1.109-0.245-1.333-0.175c-0.45,0.144-2.367-0.42-2.367-0.696c0-0.628,1.765-0.676,2.702-0.073
		c0.35,0.226,0.625,0.241,1.366,0.075c0.819-0.184,0.989-0.161,1.472,0.195c0.299,0.221,0.634,0.403,0.743,0.403
		c0.39,0,1.57,0.658,1.471,0.819c-0.056,0.091-0.208,0.153-0.337,0.138C-73.915,386.567-74.597,386.475-75.3,386.378L-75.3,386.378z
		 M-39.977,385.429c-0.196-0.217-0.268-0.394-0.159-0.394c0.107,0,0.356,0.177,0.553,0.394c0.195,0.217,0.268,0.394,0.159,0.394
		S-39.782,385.645-39.977,385.429L-39.977,385.429z M-41.218,385.18c-0.111-0.134-0.26-0.51-0.329-0.834
		c-0.113-0.522-0.1-0.544,0.109-0.195c0.131,0.217,0.363,0.539,0.517,0.718c0.164,0.189,0.203,0.374,0.094,0.44
		C-40.931,385.372-41.106,385.315-41.218,385.18z M-45.386,384.554l-0.489-0.271l0.758-0.143c0.418-0.078,1.059-0.405,1.425-0.726
		c0.399-0.351,0.664-0.478,0.662-0.318c-0.004,0.4-0.246,0.709-0.981,1.251C-44.781,384.914-44.744,384.909-45.386,384.554
		L-45.386,384.554z M-82.3,383.788c-0.724-0.412-2.026-2.006-2.451-2.996c-0.229-0.534-0.754-1.414-1.166-1.955
		c-0.413-0.542-0.887-1.25-1.052-1.575c-0.167-0.324-0.672-0.944-1.122-1.377c-0.95-0.913-1.535-1.872-1.137-1.865
		c0.516,0.008,1.846,0.748,2.415,1.343c1.573,1.642,1.896,1.934,2.638,2.392c0.717,0.441,1.056,0.9,1.09,1.476
		c0.007,0.108,0.21,0.418,0.452,0.688c0.243,0.271,0.701,0.779,1.018,1.13c0.317,0.352,0.634,0.9,0.704,1.219
		C-80.683,383.31-81.523,384.23-82.3,383.788L-82.3,383.788z M-121.939,383.658c0-0.108,0.089-0.196,0.196-0.196
		c0.109,0,0.197,0.088,0.197,0.196c0,0.109-0.088,0.197-0.197,0.197C-121.85,383.855-121.939,383.767-121.939,383.658z
		 M-69.89,383.669c-0.067-0.128-0.068-0.377-0.002-0.588c0.066-0.211-0.015-0.593-0.181-0.848c-0.274-0.418-0.271-0.508,0.027-0.935
		c0.182-0.259,0.396-0.956,0.477-1.548c0.099-0.729,0.295-1.225,0.608-1.538c0.43-0.43,0.538-0.449,1.504-0.266
		c0.829,0.157,1.154,0.138,1.605-0.096c0.756-0.391,0.812-0.374,0.56,0.178c-0.318,0.7-0.956,0.924-1.628,0.572
		c-1.2-0.628-2.793,0.305-1.952,1.146c0.309,0.308,0.479,0.337,1.052,0.173c0.98-0.282,1.367-0.23,0.758,0.101
		c-0.688,0.375-0.82,0.791-0.465,1.478c0.161,0.312,0.294,0.796,0.294,1.076c0,0.449-0.047,0.484-0.394,0.298
		c-0.217-0.116-0.529-0.534-0.695-0.93c-0.298-0.714-0.303-0.717-0.686-0.334c-0.335,0.334-0.357,0.483-0.174,1.147
		c0.166,0.599,0.154,0.805-0.052,0.935C-69.58,383.909-69.742,383.925-69.89,383.669L-69.89,383.669z M-42.89,382.607
		c-0.186-0.284-0.61-0.656-0.944-0.829c-0.334-0.172-0.563-0.358-0.509-0.412c0.307-0.306,2.014,1.083,1.868,1.52
		C-42.52,383.02-42.698,382.9-42.89,382.607L-42.89,382.607z M-73.793,381.952c-0.297-0.156-0.697-0.233-0.89-0.172
		c-0.489,0.155-2.433-0.241-2.626-0.535c-0.153-0.234-0.604-1.675-0.846-2.703c-0.066-0.283,0-0.65,0.155-0.861
		c0.226-0.309,0.341-0.331,0.698-0.141c0.376,0.202,0.457,0.167,0.663-0.286c0.128-0.284,0.559-0.681,0.954-0.883
		c0.421-0.213,0.823-0.614,0.966-0.959c0.165-0.398,0.466-0.665,0.916-0.814c0.369-0.121,0.901-0.524,1.184-0.894
		c0.283-0.37,0.618-0.672,0.747-0.672c0.128,0,0.517,0.292,0.862,0.65l0.63,0.649l-0.505,0.68c-0.573,0.77-0.543,1.433,0.105,2.346
		l0.395,0.552l-0.491,0.322c-0.27,0.176-0.489,0.429-0.489,0.563c0.002,0.451-1.259,3.049-1.574,3.246
		C-73.159,382.175-73.415,382.149-73.793,381.952L-73.793,381.952z M-62.362,381.948c-0.407-0.106-0.464-0.455-0.074-0.455
		c0.149,0,0.566,0.059,0.926,0.131c0.555,0.111,0.597,0.154,0.271,0.282C-61.666,382.073-61.857,382.08-62.362,381.948z
		 M-31.024,379.439c0-0.048,0.089-0.143,0.197-0.208c0.108-0.067,0.196-0.028,0.196,0.086s-0.088,0.208-0.196,0.208
		S-31.024,379.486-31.024,379.439z M-63.051,378.927c-0.157-0.192-0.167-0.351-0.03-0.606c0.108-0.201,0.145-0.458,0.08-0.562
		c-0.178-0.287,0.267-0.987,0.628-0.987c0.289,0,0.288,0.033-0.013,0.365c-0.26,0.287-0.288,0.487-0.135,0.895
		C-62.208,378.853-62.548,379.44-63.051,378.927L-63.051,378.927z M-161.887,378.258c0-0.048,0.088-0.141,0.197-0.208
		c0.108-0.067,0.197-0.027,0.197,0.086c0,0.115-0.088,0.208-0.197,0.208S-161.887,378.306-161.887,378.258z M-25.414,377.754
		c-0.067-0.109-0.028-0.197,0.087-0.197c0.114,0,0.208,0.088,0.208,0.197c0,0.108-0.039,0.196-0.087,0.196
		C-25.253,377.951-25.348,377.862-25.414,377.754z M-160.706,375.897c0-0.048,0.088-0.143,0.197-0.209
		c0.108-0.067,0.197-0.028,0.197,0.087c0,0.115-0.089,0.208-0.197,0.208C-160.617,375.983-160.706,375.943-160.706,375.897z
		 M-66.049,372.937c-0.375-0.876-0.426-0.915-1.003-0.789c-0.575,0.127-0.596,0.112-0.38-0.292c0.178-0.333,0.344-0.399,0.767-0.307
		c0.409,0.089,0.669-0.001,1.069-0.371c0.291-0.27,0.531-0.649,0.533-0.843c0.006-0.498,0.736,1.168,0.903,2.059
		c0.127,0.677,0.11,0.713-0.196,0.459c-0.457-0.378-0.896,0.014-0.522,0.465c0.149,0.18,0.169,0.304,0.048,0.304
		c-0.112,0-0.345,0.053-0.515,0.118C-65.573,373.828-65.758,373.618-66.049,372.937L-66.049,372.937z M-101.93,373.285
		c-0.22-0.357-0.345-1.961-0.186-2.386c0.169-0.452,0.181-0.446,0.683,0.295c0.601,0.888,0.793,1.458,0.595,1.762
		C-101.029,373.247-101.808,373.482-101.93,373.285L-101.93,373.285z M-57.983,372.267c0-0.108,0.089-0.251,0.196-0.318
		c0.108-0.066,0.197-0.033,0.197,0.075c0,0.109-0.089,0.251-0.197,0.318C-57.894,372.41-57.983,372.376-57.983,372.267z
		 M-27.087,372.244c0-0.108,0.039-0.196,0.087-0.196s0.142,0.088,0.209,0.196c0.065,0.109,0.027,0.197-0.088,0.197
		C-26.993,372.441-27.087,372.352-27.087,372.244z M-71.758,371.107c0-0.084,0.395-0.593,0.877-1.131
		c0.706-0.79,0.878-0.9,0.885-0.575c0.006,0.223-0.29,0.731-0.656,1.131C-71.275,371.211-71.758,371.462-71.758,371.107z
		 M-67.495,370.582c-0.072-0.188-0.058-0.471,0.031-0.631c0.14-0.248,0.196-0.25,0.391-0.016c0.174,0.209,0.167,0.359-0.032,0.63
		C-67.349,370.9-67.373,370.901-67.495,370.582z M-218.194,369.815c0.121-0.146,0.161-0.323,0.089-0.394
		c-0.071-0.071,0.055-0.129,0.279-0.129c0.311,0,0.384,0.094,0.305,0.394c-0.06,0.228-0.269,0.394-0.498,0.394
		C-218.326,370.08-218.365,370.021-218.194,369.815L-218.194,369.815z M-66.175,369.764c-0.074-0.206-0.17-0.439-0.214-0.521
		c-0.043-0.081,0.005-0.147,0.106-0.147c0.196,0,0.473,0.809,0.326,0.954C-66.003,370.097-66.102,369.968-66.175,369.764z
		 M-68.17,369.25c-0.275-0.333-0.007-0.807,0.407-0.715c0.259,0.058,0.269,0.14,0.06,0.525
		C-67.904,369.431-67.99,369.466-68.17,369.25z M-65.843,368.573c-0.227-0.287-0.378-0.554-0.338-0.595
		c0.16-0.16,0.714,0.193,1.008,0.642C-64.72,369.311-65.294,369.271-65.843,368.573z M-69.078,367.811
		c-0.175-0.175-0.318-0.42-0.318-0.544c0-0.125,0.194-0.033,0.433,0.205c0.236,0.238,0.38,0.483,0.318,0.545
		C-68.709,368.079-68.904,367.987-69.078,367.811z M-223.547,367.784c-0.072-0.072-0.131-0.223-0.131-0.334
		c0-0.123,0.092-0.112,0.233,0.029c0.128,0.128,0.188,0.279,0.131,0.335C-223.37,367.869-223.475,367.856-223.547,367.784z
		 M-224.662,367.521c0-0.109,0.039-0.197,0.086-0.197s0.141,0.088,0.208,0.197c0.066,0.108,0.028,0.196-0.087,0.196
		S-224.662,367.629-224.662,367.521z M-215.413,367.128c0-0.108,0.039-0.198,0.087-0.198s0.142,0.09,0.208,0.198
		c0.067,0.107,0.028,0.196-0.087,0.196C-215.319,367.324-215.413,367.235-215.413,367.128z M-67.099,367.097
		c-0.197-0.111-0.571-0.148-0.831-0.083c-0.38,0.096-0.644-0.058-1.356-0.792c-1.008-1.037-1.204-1.474-0.908-2.026
		c0.114-0.213,0.208-0.654,0.208-0.979c0-1.068,0.715-1.024,1.24,0.075c0.29,0.609,0.292,0.74,0.019,1.394
		c-0.294,0.703-0.288,0.735,0.236,1.282c0.379,0.396,0.663,0.53,0.963,0.455c0.252-0.063,0.525,0.015,0.674,0.194
		C-66.5,367.043-66.656,367.349-67.099,367.097L-67.099,367.097z M-216.594,365.86c0-0.048,0.088-0.142,0.197-0.208
		c0.108-0.067,0.197-0.028,0.197,0.086c0,0.115-0.088,0.208-0.197,0.208S-216.594,365.908-216.594,365.86z M-185.699,365.159
		c0-0.108,0.089-0.196,0.197-0.196c0.108,0,0.197,0.088,0.197,0.196c0,0.109-0.089,0.197-0.197,0.197
		C-185.61,365.356-185.699,365.268-185.699,365.159z M-217.184,364.395c0-0.108,0.088-0.251,0.197-0.317
		c0.108-0.067,0.197-0.034,0.197,0.074c0,0.108-0.088,0.252-0.197,0.318C-217.096,364.538-217.184,364.504-217.184,364.395z
		 M-218.855,363.587c-0.069-0.112,0.01-0.145,0.185-0.077c0.341,0.131,0.403,0.271,0.121,0.271
		C-218.652,363.782-218.79,363.694-218.855,363.587z M-187.568,363.585c-0.067-0.108-0.028-0.197,0.086-0.197
		c0.115,0,0.208,0.089,0.208,0.197s-0.039,0.196-0.087,0.196C-187.407,363.782-187.501,363.693-187.568,363.585z M-218.07,363.192
		c-0.066-0.108-0.027-0.196,0.087-0.196c0.115,0,0.208,0.088,0.208,0.196s-0.039,0.196-0.087,0.196S-218.003,363.3-218.07,363.192z
		 M-217.184,363.192c0-0.108,0.039-0.196,0.087-0.196s0.142,0.088,0.208,0.196c0.066,0.108,0.027,0.196-0.087,0.196
		S-217.184,363.3-217.184,363.192z M-230.713,362.668c-0.136-0.055-0.247-0.18-0.247-0.28c0-0.284,0.869-0.21,1.291,0.109
		c0.363,0.274,0.353,0.288-0.208,0.28C-230.201,362.771-230.578,362.723-230.713,362.668z M-225.734,362.556
		c-0.177-0.099-0.567-0.125-0.867-0.059c-0.3,0.065-0.762,0.033-1.025-0.072c-0.425-0.17-0.387-0.19,0.325-0.174
		c1.046,0.024,1.326-0.146,1.058-0.645c-0.189-0.355-0.133-0.381,0.806-0.381c0.908,0,2.218,0.416,2.44,0.775
		C-222.849,362.239-225.381,362.753-225.734,362.556L-225.734,362.556z M-221.959,362.681c-0.233-0.234,0.057-0.473,0.572-0.473
		c0.31,0,0.466,0.093,0.416,0.246C-221.062,362.729-221.752,362.888-221.959,362.681z M-79.588,362.368
		c-0.521-0.521-0.005-1.143,0.947-1.143c0.422,0,0.423,0.003,0.062,0.624C-79.003,362.571-79.254,362.701-79.588,362.368z
		 M-220.333,362.327c0-0.043,0.133-0.128,0.295-0.19c0.163-0.062,0.295-0.027,0.295,0.078c0,0.104-0.133,0.19-0.295,0.19
		C-220.2,362.404-220.333,362.37-220.333,362.327z M-228.84,360.917c-0.061-0.061-0.476-0.116-0.923-0.122
		c-0.616-0.008-0.764-0.069-0.613-0.251c0.146-0.176-0.063-0.411-0.787-0.88c-1.418-0.919-2.21-1.099-3.164-0.717
		c-0.894,0.358-0.962,0.368-0.962,0.142c0-0.412,1.566-0.864,2.694-0.779c1.079,0.082,1.683,0.311,3.8,1.444
		c1.741,0.931,1.743,0.933,1.181,1.094C-228.252,361.03-228.702,361.055-228.84,360.917z M-227.417,359.256
		c0.381-0.246,0.59-0.246,0.59,0c0,0.109-0.199,0.195-0.443,0.194C-227.628,359.447-227.656,359.411-227.417,359.256z
		 M-70.696,358.907c-0.119-0.079-0.323-0.347-0.455-0.596c-0.197-0.375-0.177-0.558,0.12-1.066c0.459-0.786,0.77-0.884,0.918-0.29
		C-69.956,357.579-70.408,359.096-70.696,358.907L-70.696,358.907z M-226.125,358.644c0.132-0.212,0.873-0.236,0.873-0.027
		c0,0.08-0.221,0.16-0.491,0.175C-226.013,358.808-226.185,358.741-226.125,358.644L-226.125,358.644z M-230.016,356.748
		c-0.315-0.361-0.269-0.837,0.08-0.837c0.112,0,0.158,0.173,0.103,0.383c-0.055,0.212,0.001,0.421,0.125,0.465
		c0.124,0.045,0.173,0.139,0.109,0.208C-229.663,357.037-229.851,356.938-230.016,356.748z M-228.874,355.202
		c-0.069-0.182-0.083-0.375-0.029-0.431c0.054-0.054,0.156,0.051,0.226,0.234c0.07,0.182,0.083,0.376,0.029,0.43
		C-228.702,355.49-228.803,355.385-228.874,355.202z M-230.107,355.025c0-0.162,0.168-0.295,0.375-0.295
		c0.333,0,0.342,0.033,0.08,0.295c-0.163,0.163-0.332,0.295-0.375,0.295C-230.071,355.32-230.107,355.188-230.107,355.025z
		 M-65.067,350.711c0-0.154-0.216-0.535-0.479-0.848c-0.624-0.742-0.403-1.236,0.336-0.752c0.53,0.348,0.809,1.058,0.612,1.569
		C-64.744,351.067-65.067,351.088-65.067,350.711z M-217.775,349.526c0-0.048,0.089-0.141,0.197-0.208
		c0.108-0.066,0.197-0.027,0.197,0.087c0,0.114-0.089,0.208-0.197,0.208S-217.775,349.574-217.775,349.526z M-63.971,349.285
		c-0.128-0.332,0.175-0.646,0.625-0.652c0.313-0.004,0.319,0.437,0.011,0.745C-63.657,349.698-63.821,349.674-63.971,349.285
		L-63.971,349.285z M-62.173,348.566c-0.381-0.583-0.922-0.664-2.156-0.32c-0.503,0.14-0.85,0.141-1.083,0.004
		c-0.188-0.11-0.239-0.203-0.112-0.206s0.497-0.231,0.823-0.504c0.421-0.354,0.729-0.465,1.062-0.382
		c0.352,0.089,0.732-0.081,1.532-0.682c1.557-1.17,1.697-1.539,1.063-2.788c-0.607-1.201-0.438-1.587,0.462-1.054
		c0.656,0.387,1.274,1.497,1.095,1.963c-0.064,0.169,0.032,0.697,0.214,1.175c0.259,0.675,0.283,0.959,0.111,1.281
		c-0.254,0.472-0.957,0.787-1.756,0.787c-0.449,0-0.583,0.116-0.729,0.627l-0.18,0.625L-62.173,348.566L-62.173,348.566z
		 M-141.893,347.895c-0.235-0.235-0.11-0.446,0.264-0.446c0.21,0,0.437-0.089,0.503-0.197c0.184-0.296,0.495-0.236,0.377,0.072
		C-140.87,347.641-141.723,348.066-141.893,347.895z M-148.604,347.554l-0.59-0.27l0.787-0.015c0.98-0.016,1.703,0.168,1.561,0.398
		C-147.005,347.924-147.927,347.864-148.604,347.554L-148.604,347.554z M-156.553,345.592c-0.788-0.404-0.825-0.454-0.414-0.562
		c0.492-0.128,1.583,0.031,1.561,0.23c-0.008,0.067-0.074,0.27-0.148,0.448C-155.675,346.002-155.771,345.992-156.553,345.592z
		 M-160.497,343.462c-0.17-0.489-0.157-0.675,0.064-0.897c0.4-0.4,0.711-0.119,0.711,0.643
		C-159.722,344.108-160.216,344.27-160.497,343.462L-160.497,343.462z M-165.113,343.625c0.14-0.363,0.739-0.425,0.618-0.063
		c-0.045,0.135-0.229,0.275-0.409,0.31C-165.132,343.916-165.195,343.842-165.113,343.625L-165.113,343.625z M-62.29,341.38
		c-0.359-0.506-0.37-0.599-0.104-0.979c0.262-0.373,0.261-0.484-0.008-1.005c-0.442-0.856-0.381-0.909,0.387-0.323
		c0.379,0.29,1.129,0.656,1.664,0.816c1.107,0.329,1.374,0.587,0.798,0.77c-0.218,0.069-0.397,0.254-0.397,0.411
		c0,0.215-0.135,0.251-0.541,0.147c-1.037-0.266-1.333-0.2-1.267,0.279C-61.678,342.083-61.81,342.055-62.29,341.38L-62.29,341.38z
		 M-160.181,341.611c-0.228-0.229-0.142-0.61,0.164-0.727c0.213-0.082,0.295,0.021,0.295,0.372
		C-159.722,341.729-159.915,341.877-160.181,341.611z M-63.539,337.461c-0.167-0.189-0.568-0.876-0.894-1.525
		c-0.325-0.65-1.184-1.842-1.909-2.651c-0.81-0.903-1.222-1.502-1.066-1.554c0.137-0.045,0.433,0.16,0.655,0.457
		c0.542,0.721,1.209,1.348,2.261,2.123c0.872,0.642,1.304,1.237,0.74,1.021c-0.648-0.25-0.487,0.342,0.354,1.287
		c0.489,0.551,0.89,1.044,0.89,1.095C-62.509,337.954-63.27,337.767-63.539,337.461L-63.539,337.461z M-207.542,337.118
		c0-0.163,0.085-0.295,0.19-0.295c0.105,0,0.14,0.132,0.078,0.295c-0.063,0.162-0.148,0.295-0.19,0.295
		C-207.507,337.413-207.542,337.279-207.542,337.118z M-208.57,336.483l-0.733-0.077l1.379-1.366c0.759-0.751,1.479-1.366,1.6-1.366
		c0.128,0,0.09,0.145-0.091,0.345c-0.41,0.453-0.13,0.961,0.619,1.126c0.326,0.071,0.652,0.322,0.778,0.598
		c0.202,0.444,0.168,0.488-0.514,0.679C-206.247,336.619-207.101,336.637-208.57,336.483L-208.57,336.483z M-256.521,335.071
		c-0.682-0.637-0.758-0.905-0.306-1.078c0.405-0.156,1.152,0.351,1.436,0.972c0.297,0.652,0.292,0.677-0.146,0.677
		C-255.742,335.642-256.185,335.384-256.521,335.071z M-169.857,334.071c0.217-0.107,0.643-0.284,0.947-0.394
		c0.67-0.242,0.559-0.5-0.289-0.669c-0.581-0.116-0.602-0.146-0.269-0.389c0.198-0.145,0.302-0.357,0.232-0.471
		c-0.072-0.116,0.13-0.297,0.462-0.413c0.325-0.113,0.59-0.293,0.59-0.4c0-0.455-0.41-1.22-0.638-1.189
		c-0.525,0.068-0.769-0.024-0.684-0.26c0.048-0.135,0.008-0.401-0.089-0.591c-0.253-0.493,0.329-1.511,0.87-1.522
		c0.082-0.001,0.125,0.105,0.099,0.238c-0.027,0.133,0.158,0.274,0.413,0.312l0.462,0.07l-0.441,0.47l-0.441,0.47l0.498,0.189
		c0.534,0.203,1.048,0.865,1.573,2.021c0.251,0.553,0.416,0.699,0.709,0.622c0.602-0.157,0.673,0.251,0.108,0.621
		c-0.382,0.25-0.455,0.392-0.287,0.559c0.167,0.167-0.163,0.279-1.308,0.445c-0.842,0.121-1.84,0.278-2.22,0.348
		C-170.089,334.235-170.158,334.219-169.857,334.071L-169.857,334.071z M-173.176,332.983c-0.067-0.175,0.01-0.574,0.171-0.885
		c0.185-0.358,0.221-0.611,0.097-0.688c-0.341-0.211-0.213-0.48,0.288-0.606c0.266-0.066,0.587-0.245,0.713-0.396
		c0.262-0.316,1.474-0.375,1.656-0.08c0.067,0.108-0.016,0.393-0.185,0.634c-0.168,0.241-0.306,0.674-0.306,0.961
		c0,0.438-0.135,0.57-0.837,0.813c-0.46,0.16-0.979,0.353-1.155,0.428C-172.94,333.254-173.096,333.19-173.176,332.983z
		 M-169.562,331.028c0-0.047,0.089-0.142,0.197-0.208c0.108-0.067,0.197-0.029,0.197,0.086c0,0.115-0.089,0.208-0.197,0.208
		C-169.473,331.116-169.562,331.076-169.562,331.028z M-256.896,330.08c-0.193-0.232-0.181-0.34,0.061-0.541
		c0.4-0.332,0.598-0.107,0.366,0.415C-256.63,330.314-256.688,330.331-256.896,330.08L-256.896,330.08z M-257.203,328.601
		c-0.067-0.108,0.01-0.305,0.172-0.439c0.32-0.266,0.386-0.057,0.118,0.367C-257.016,328.691-257.13,328.719-257.203,328.601z
		 M-269.923,328.475c0-0.138,0.597-0.38,1.443-0.586c0.321-0.077,0.511-0.058,0.447,0.045c-0.061,0.099-0.357,0.229-0.656,0.289
		c-0.3,0.061-0.701,0.17-0.89,0.243C-269.769,328.539-269.923,328.543-269.923,328.475L-269.923,328.475z M-152.982,326.262
		c-0.363-0.146-0.299-0.373,0.115-0.404c0.198-0.014,0.361,0.1,0.361,0.253C-152.507,326.405-152.575,326.427-152.982,326.262z
		 M-196.226,325.256c-1.246-0.678-1.841-1.722-1.48-2.593c0.171-0.412,0.176-0.671,0.02-0.964c-0.326-0.608,0.1-1.323,1.187-1.991
		c0.516-0.319,1.032-0.752,1.146-0.965c0.315-0.589,0.035-1.599-0.514-1.848c-0.277-0.127-0.429-0.327-0.368-0.486
		c0.14-0.364-0.515-1.608-1.167-2.217c-0.48-0.449-0.688-0.493-2.332-0.504c-2.239-0.014-2.656-0.138-2.619-0.781
		c0.015-0.267,0.075-0.485,0.132-0.485c0.057,0,0.854-0.093,1.771-0.206c1.171-0.145,2.221-0.441,3.526-0.998
		c2.021-0.861,2.175-0.878,6.997-0.765c1.523,0.036,2.924-0.078,4.723-0.38c1.676-0.282,2.909-0.389,3.575-0.309
		c0.565,0.068,1.385,0.023,1.847-0.101c1.073-0.289,3.862,0.058,4.958,0.618c0.648,0.331,0.991,0.374,2.085,0.265
		c1.182-0.117,1.271-0.102,0.916,0.159c-0.217,0.16-0.881,0.354-1.476,0.433c-1.292,0.17-2.479,1.09-2.165,1.678
		c0.136,0.253,0.039,0.47-0.4,0.89c-0.318,0.305-0.626,0.702-0.683,0.882c-0.138,0.431-1.877,1.235-2.74,1.264
		c-0.958,0.034-1.058,0.237-0.317,0.646c0.35,0.192,0.861,0.521,1.136,0.729l0.499,0.379l-0.451,0.167
		c-0.346,0.128-0.626,0.046-1.202-0.353c-0.781-0.541-1.621-0.544-1.173-0.004c0.175,0.21,0.133,0.293-0.194,0.378
		c-0.731,0.191-0.214,0.568,0.592,0.432c0.514-0.086,0.802-0.041,0.963,0.153c0.19,0.231-0.059,0.336-1.491,0.638
		c-0.947,0.199-2.208,0.369-2.803,0.378c-1.106,0.016-1.903,0.333-3.075,1.225c-0.382,0.291-1.195,0.579-2.145,0.761
		c-1.987,0.379-2.659,0.819-3.855,2.524c-0.962,1.369-1.542,1.88-2.142,1.884C-195.11,325.792-195.685,325.551-196.226,325.256
		L-196.226,325.256z M-210.179,323.94c-0.422-0.26-0.94-0.744-1.151-1.076c-0.411-0.649-0.79-0.731-1.898-0.413
		c-0.375,0.107-0.796,0.118-0.965,0.023c-0.583-0.326,0.47-0.647,1.642-0.5c0.892,0.112,1.089,0.08,1.194-0.191
		c0.208-0.544,1.165-0.935,1.696-0.692c0.327,0.148,0.625,0.145,1.042-0.015c0.562-0.215,0.57-0.234,0.203-0.504
		c-0.21-0.154-0.45-0.281-0.533-0.281s-0.382-0.395-0.666-0.878c-0.283-0.483-0.813-1.063-1.179-1.289
		c-0.621-0.385-0.703-0.391-1.258-0.104c-0.486,0.251-0.767,0.269-1.535,0.097c-0.518-0.117-1.24-0.156-1.605-0.087
		c-0.634,0.119-1.795-0.191-1.795-0.479c0-0.529,1.667-1.716,2.657-1.892l0.787-0.141l-0.563,0.6
		c-0.776,0.827-0.485,1.208,0.358,0.467c0.357-0.313,0.976-0.632,1.373-0.706c0.608-0.114,0.868-0.04,1.631,0.466
		c1.015,0.672,1.093,0.689,1.666,0.383c0.345-0.184,0.473-0.133,0.847,0.336c0.259,0.324,0.838,0.678,1.401,0.856
		c0.899,0.285,0.96,0.347,0.96,0.966c0,0.465,0.134,0.759,0.449,0.985c1.321,0.944,1.292,0.905,1.006,1.362
		c-0.392,0.627-0.701,0.673-1.568,0.231c-0.603-0.309-0.867-0.35-1.17-0.188c-0.372,0.199-0.375,0.241-0.076,0.82
		c0.245,0.474,0.268,0.714,0.101,1.08c-0.205,0.451-0.238,0.458-0.796,0.169c-1.097-0.566-1.836-0.193-0.813,0.411
		c0.321,0.189,0.539,0.417,0.484,0.505C-208.444,324.572-209.417,324.409-210.179,323.94L-210.179,323.94z M-169.759,324.195
		c0-0.09,0.133-0.164,0.295-0.164c0.162,0,0.295,0.073,0.295,0.164c0,0.091-0.133,0.164-0.295,0.164
		C-169.626,324.359-169.759,324.286-169.759,324.195z M-217.599,323.137c-0.388-0.205-0.689-0.222-1.232-0.072
		c-0.924,0.255-1.247,0.069-0.778-0.449c0.6-0.663,1.007-0.793,1.731-0.556c0.815,0.27,1.176,0.668,0.956,1.057
		C-217.063,323.366-217.158,323.369-217.599,323.137z M-178.515,323.047c-0.325-0.213-0.738-0.342-0.918-0.285
		c-0.249,0.078-0.304,0.01-0.228-0.282c0.117-0.448,1.846-1.094,2.431-0.908c0.204,0.064,0.725,0.011,1.158-0.119
		c0.679-0.204,0.859-0.188,1.319,0.113l0.532,0.349l-0.474,0.369c-0.399,0.311-1.142,0.582-2.934,1.069
		C-177.792,323.399-178.191,323.26-178.515,323.047z M-231.943,319.374c-0.271-0.043-0.93-0.274-1.465-0.515l-0.973-0.437
		l1.366-0.099c0.751-0.054,1.366-0.187,1.366-0.295c0-0.109-0.531-0.244-1.181-0.302c-0.857-0.077-1.019-0.131-0.59-0.197
		c0.718-0.111,0.77-0.422,0.099-0.591c-0.484-0.122-0.48-0.129,0.2-0.422c1.178-0.508,2.433-0.605,3.545-0.275
		c0.913,0.271,1.055,0.27,1.486-0.012c0.305-0.199,0.485-0.233,0.501-0.092c0.013,0.12,0.036,0.322,0.049,0.449
		c0.045,0.426,0.438,0.371,0.907-0.127c0.434-0.462,1.185-0.683,1.184-0.348c0,0.081-0.175,0.433-0.389,0.784l-0.389,0.637
		l0.635,0.332l0.635,0.331L-226,318.67c-0.94,0.43-1.134,0.454-1.977,0.251c-0.818-0.195-1.052-0.173-1.876,0.18
		C-230.784,319.5-230.98,319.526-231.943,319.374L-231.943,319.374z M-224.051,319.235c-0.178-0.214-0.104-0.319,0.355-0.51
		c0.32-0.132,0.778-0.177,1.024-0.099c0.426,0.135,0.416,0.157-0.231,0.501C-223.73,319.568-223.771,319.572-224.051,319.235
		L-224.051,319.235z M-169.759,318.324c0.382-0.246,0.591-0.246,0.591,0c0,0.108-0.2,0.195-0.443,0.193
		C-169.969,318.516-169.997,318.479-169.759,318.324z M-133.648,317.778c-1.006-0.396-0.985-0.375-0.692-0.728
		c0.136-0.165,0.172-0.302,0.077-0.307c-0.095-0.003,0.005-0.139,0.222-0.303c0.217-0.164,0.395-0.36,0.396-0.437
		c0.007-0.291,0.808-1.059,1.375-1.317c0.324-0.147,1.165-0.314,1.869-0.37c0.703-0.056,1.646-0.158,2.094-0.227
		c0.478-0.074,0.865-0.044,0.938,0.072c0.14,0.226-0.127,0.321-1.653,0.596c-1.517,0.272-2.428,0.697-3.026,1.41
		c-0.633,0.751-0.654,1.226-0.073,1.631l0.442,0.308l-0.59-0.008C-132.595,318.093-133.215,317.949-133.648,317.778
		L-133.648,317.778z M-236.714,317.207c-0.318-0.318-0.246-0.387,1.277-1.22c1.784-0.977,2.68-1.105,4.28-0.618l0.886,0.27
		l-1.181,0.234c-0.965,0.191-3.012,0.876-4.628,1.547C-236.244,317.488-236.529,317.393-236.714,317.207z M-222.498,316.953
		c0-0.105-0.243-0.331-0.541-0.501l-0.541-0.311l0.725,0.114c0.515,0.082,0.771,0.033,0.885-0.167
		c0.136-0.237,1.389-0.711,1.888-0.715c0.084,0,0.016,0.265-0.152,0.589C-220.629,316.726-222.498,317.544-222.498,316.953
		L-222.498,316.953z M-219.434,316.378c-0.282-0.456,1.644-1.162,2.461-0.903c0.597,0.189,0.198,0.514-0.842,0.687
		c-0.465,0.077-0.992,0.204-1.17,0.279C-219.163,316.518-219.365,316.489-219.434,316.378L-219.434,316.378z M-213.15,314.93
		c-1.299-0.104-2.386-0.211-2.415-0.238c-0.176-0.164,0.263-0.695,0.735-0.89c0.464-0.192,0.678-0.167,1.314,0.157
		c0.619,0.315,1.019,0.367,2.17,0.285c0.776-0.056,1.662-0.039,1.968,0.039l0.557,0.141l-0.689,0.368
		c-0.379,0.203-0.821,0.358-0.984,0.347C-210.656,315.127-211.851,315.032-213.15,314.93L-213.15,314.93z M-156.519,314.125
		c-0.967-0.495-1.06-0.719-0.301-0.724c0.298-0.002,0.674-0.099,0.836-0.215c0.255-0.183,0.251-0.199-0.025-0.111
		c-0.176,0.056-0.423-0.001-0.549-0.127c-0.174-0.174-0.265-0.171-0.378,0.013c-0.105,0.17-0.321,0.19-0.729,0.068
		c-1.038-0.312-1.269-0.449-1.269-0.757c0-0.262,0.26-0.285,1.869-0.172c1.226,0.085,1.87,0.062,1.87-0.069
		c0-0.388,0.546-0.196,0.691,0.242l0.146,0.442l0.087-0.442c0.048-0.244,0.214-0.443,0.368-0.443c0.155,0,0.282-0.106,0.282-0.236
		c0-0.183,0.053-0.183,0.236,0c0.137,0.138,0.796,0.236,1.574,0.236c1.407,0,1.737,0.252,0.799,0.608
		c-0.488,0.185-1.335,0.133-2.265-0.14c-0.19-0.056-0.344-0.029-0.344,0.059c0.002,0.182,1.107,0.849,1.407,0.849
		c0.107,0,0.319,0.125,0.471,0.277c0.237,0.237,0.235,0.292-0.014,0.388c-0.16,0.061-0.29,0.015-0.29-0.102
		c0-0.138-0.25-0.166-0.708-0.08c-0.496,0.093-0.667,0.067-0.574-0.085c0.074-0.12,0.028-0.347-0.103-0.504
		c-0.253-0.304-0.977-0.397-0.977-0.125c0,0.088-0.288,0.479-0.64,0.868l-0.64,0.707L-156.519,314.125L-156.519,314.125z
		 M-208.722,313.692c-0.341-0.2-0.824-0.258-1.574-0.189c-0.702,0.064-1.241,0.008-1.533-0.159c-0.402-0.23-0.304-0.256,0.925-0.246
		c1.537,0.012,2.211-0.316,1.379-0.673c-0.396-0.168-0.338-0.185,0.34-0.09c0.885,0.122,1.229-0.115,0.747-0.515
		c-0.514-0.426,0.051-0.56,1.88-0.44c1.132,0.073,1.843,0.032,2.134-0.123c0.557-0.298,0.338-0.678-0.298-0.518
		c-0.44,0.11-3.494-0.071-3.365-0.2c0.092-0.092,4.418-0.493,7.259-0.672c1.473-0.093,3.111-0.223,3.64-0.288l0.962-0.12
		l-1.587,0.763c-1.488,0.715-3.909,1.412-4.903,1.412c-0.242,0-0.938,0.265-1.546,0.59c-0.608,0.324-1.228,0.591-1.377,0.591
		c-0.149,0-0.793,0.265-1.43,0.587c-0.638,0.324-1.159,0.586-1.159,0.584C-208.23,313.982-208.452,313.851-208.722,313.692
		L-208.722,313.692z M-212.56,311.894c-0.954-0.21-0.989-0.29-0.288-0.653c1.022-0.53,1.988-0.665,2.428-0.341
		c0.348,0.256,0.328,0.296-0.355,0.7C-211.488,312.02-211.777,312.068-212.56,311.894z"/>
</g>
</svg>
`;
