import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { MainState } from '../../store/main.state';
import { Logout } from '../../store/main.actions';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  @Select(MainState.user) user$: Observable<User>;
  user: User;
  @Input() selected = '';

  constructor(
    private router: Router,
    private store: Store
  ) { }

  ngOnInit() {
    this.user$.subscribe((user: User) => this.user = user);
  }

  selectionChange(option) {
    this.selected = option;
    let route = '';
    if (option.value === 'profile') {
      route = '/profile';
    } else if (option.value === 'profile-edit') {
      route = '/update-profile/' + this.user.id;
    } else if (option.value === 'change-password') {
      route = '/update-password';
    } else if (option.value === 'logout') {
      route = '/search';
      this.store.dispatch(new Logout());
    }
    this.router.navigate([route]);
  }

}
