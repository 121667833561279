import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subscription } from 'rxjs';

import { Assessment, AssessmentReference, StudyTypeIcon} from '../../models/assessment.model';
import { Ecosystem } from '../../models/ecosystem.model';
import { EcosystemRisk } from '../../models/ecosystem.risk.model';
import { StudiesService } from '../../services/studies.service';
import { EcosystemsService } from '../../services/ecosystems.service';
import { EcosystemDistribution } from '../../models/ecosystem.distribution.model';
import { WindowRefService } from '../../services/window-ref.service';
import { GalleryType } from '../../models/image.model';
import { SysStudiesService } from '../../services/sysstudies.service';
import { RestService } from '../../services/rest.service';
import { getCatColor, getSystemColor } from '../../utils/functions';
import * as Highcharts from 'highcharts';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-assessment-systematic',
  templateUrl: './assessment-systematic.component.html',
  styleUrls: ['./assessment-systematic.component.scss']
})
export class AssessmentSystematicComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('assessmentLegend') legendBox: ElementRef;
  public legendShow = false;
  public legendAllowToShow = false;
  public legendClassSwitchA = false;
  public legendClassSwitchB = false;

  public Highcharts = Highcharts;
  public pie1Options: Highcharts.Options = null;
  public pie2Options: Highcharts.Options = null;
  private highchartsSwitch = false;
  public icons: StudyTypeIcon[] = [];
  public icon_systematic: string;
  public icon_strategic: string;
  systematic_statistic_message_en: string;
  systematic_statistic_message_es: string;
  chart_legend: string;

  searchUnits: FormGroup = this.fb.group({
    search: null,
  });

  legendBoxH: number;
  private subscriptions: Subscription[] = [];
  assessmentId: number;
  loading = {
    assessment: false,
    ecosystem: false,
    risks: false,
    distribution: false,
    loadingMore: false,
  };
  public assessment: Assessment;
  public ecosystem: Ecosystem;
  public ecosystems: Ecosystem[];
  public distribution: EcosystemDistribution;
  public ecosystemRisk: EcosystemRisk;
  public globalScope: string;
  public reference: AssessmentReference;
  public get riskAsUnit() { return this.ecosystemRisk ? this.ecosystemRisk.as_unit[0] : undefined; }
  public readMoreChange = null;
  public related_units: any;
  public unitSearchString: string;
  public searchResultsNextUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studiesService: SysStudiesService,
    private ecosystemsService: EcosystemsService,
    private window: WindowRefService,
    private fb: FormBuilder,
    private restService: RestService
  ) { }

  @HostListener('document:scroll', ['$event.target'])
  onDocumentScroll() {
    // const legendBoxCurrentBottomYPosition = this.windowRef.nativeWindow.innerHeight - this.legendBoxH - Math.round(this.legendBox.nativeElement.getBoundingClientRect().top);
    // if (legendBoxCurrentBottomYPosition > 0 && !this.legendClassSwitchA) {
    //   this.legendAllowToShow = false;
    //   this.legendClassSwitchA = true;
    //   this.legendClassSwitchB = false;
    // }
    // if (legendBoxCurrentBottomYPosition <= 0 && !this.legendClassSwitchB) {
    //   this.legendAllowToShow = true;
    //   this.legendClassSwitchB = true;
    //   this.legendClassSwitchA = false;
    // }
  }

  ngAfterViewInit(): void {
    console.log(this.legendBox)
    // this.legendBoxH = this.legendBox.nativeElement.offsetHeight;
  }

  async getSettingMsg() {
    this.systematic_statistic_message_en = null;
    this.systematic_statistic_message_es = null;
    this.chart_legend = null;
    this.icon_systematic = null;
    this.icon_strategic = null;
    const settingData = await this.restService.getSettings();
    const statistic_msg = settingData ? settingData?.[0]?.systematic_statistic_message : null;
    const statisticData = statistic_msg ? JSON.parse(statistic_msg) : [];
    if (statisticData){
      statisticData.forEach(row =>{
        if (row){
          if (row && row.lang === 'en'){
            this.systematic_statistic_message_en = row.content;
          }
          if (row && row.lang === 'es'){
            this.systematic_statistic_message_es = row.content;
          }
        }
      });
    }
    this.icons = await this.studiesService.getStudyIcons();
    if (this.icons){
      for (let icon of this.icons){
        if (icon.study_type === 'systematic'){
          this.icon_systematic = icon.icon_data;
        }
        if (icon.study_type === 'strategic'){
          this.icon_strategic = icon.icon_data;
        }
      }
    }
    const switchLang = window.navigator.language.slice(0, 2);
    if (switchLang === 'es'){
      this.chart_legend = this.systematic_statistic_message_es;
    }else{
      this.chart_legend = this.systematic_statistic_message_en;
    }
  }

  ngOnInit() {
    history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);

    this.assessmentId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (this.assessmentId) {
      this.fetchAssessmentDetails(this.route.snapshot.paramMap.get('prev')=='previous');
      this.studiesService.getUnits(this.assessmentId, null).subscribe(
        units => {
          this.related_units = units['search'].results;
          this.renderChart(units['charts']);
          this.searchResultsNextUrl = units['search'].next;
        }
      )
    }
    this.getSettingMsg();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    history.scrollRestoration = 'auto';
  }

  public onSearch() {
    const search = this.searchUnits.value.search;
    this.assessmentId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (this.assessmentId) {
      this.studiesService.getUnits(this.assessmentId, search).toPromise().then((units) => {
        this.related_units = units['search'].results;
      });
    }
  }

  public tabChangeHandler(event: MatTabChangeEvent) {
    this.readMoreChange = event.index;
  }

  private fetchAssessmentDetails(prev) {
    if (!prev){
    // loading main assessment content
    this.loading.assessment = true;
    const subAssessment = this.studiesService.getStudy(this.assessmentId).subscribe(
      (assessment) => {
        console.log(assessment)
        this.assessment = assessment;
        this.reference = assessment.referen ? assessment.referen[0] : null;

        let scope2level;
        const scope = assessment.scope[0];
        this.globalScope = scope.scope_class_elem_ids[0].split(' ')[1];
        if (scope.scope_class_elem_ids.length > 1) {
          // scope2level = assessment.scope[1];
          scope2level = ' / ' + scope.scope_class_elem_ids[1].split(' ')[1];
          this.globalScope += scope2level;
        }

        this.loading.assessment = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.assessment = false;
        console.error(err);
      },
    );
    this.subscriptions.push(subAssessment);
  }
  else{

    this.loading.assessment = true;
    const subAssessment = this.studiesService.getStudyPrevious(this.assessmentId).subscribe(
      (assessment) => {
        console.log(assessment)
        this.assessment = assessment;
        this.reference = assessment.referen ? assessment.referen[0] : null;
        this.loading.assessment = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.assessment = false;
        console.error(err);
      },
    );
    this.subscriptions.push(subAssessment);

  }

    // loading assessment ecosystems
    this.loading.ecosystem = true;
    const subEcosystem = this.ecosystemsService.getStudyEcosystems(this.assessmentId).subscribe(
      (ecosystems) => {
        this.ecosystems = ecosystems.length > 1 ? ecosystems : null;
        this.ecosystem = this.locateEcosystem(ecosystems, this.assessmentId);
        this.loading.ecosystem = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.ecosystem = false;
        console.error(err);
      }
    );
    this.subscriptions.push(subEcosystem);

    // loading assessment distribution section
    this.loading.distribution = true;
    const subDistribution = this.ecosystemsService.getStudyEcosystemDistribution(this.assessmentId).subscribe(
      (distribution) => {
        this.distribution = this.locateEcosystem(distribution, this.assessmentId);
        this.loading.distribution = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.distribution = false;
        console.error(err);
      }
    );
    this.subscriptions.push(subDistribution);

    // loading assessment risks section
    this.loading.risks = true;
    const subRisks = this.ecosystemsService.getStudyEcosystemRisks(this.assessmentId).subscribe(
      (risks) => {
        this.ecosystemRisk = this.locateEcosystemRisk(risks, this.assessmentId);
        this.loading.risks = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.risks = false;
        console.error(err);
      }
    );
    this.subscriptions.push(subRisks);
// ///////


  }

  private locateEcosystem(ecosystemsOrDistributions: any, ecosystemId: number) {
    const found = ecosystemsOrDistributions.find(ecosystem => ecosystemId === ecosystem.id);
    if (found) {
      return found;
    } else {
      const response = ecosystemsOrDistributions.length > 0 ? ecosystemsOrDistributions[0] : undefined;
      if (!response) {
        console.error('Ecosystem\'s details were not found!');
        return response;
      } else {
        return response;
      }
    }
  }

  private locateEcosystemRisk(risks: EcosystemRisk[], assessmentId: number) {
    const locateHelper = (array: any, id: number) => {
      const foundHelper = array.find((el: any) => id === el.id);
      if (foundHelper) {
        return foundHelper;
      } else {
        return array.length > 0 ? array[0] : undefined;
      }
    };

    const foundEcosystemRisk: EcosystemRisk = locateHelper(risks, assessmentId);
    if (foundEcosystemRisk) {
      return foundEcosystemRisk;
    } else {
      console.error('Ecosystem Risk details were not found!');
      return undefined;
    }
  }

  public haveCEMImages(ecosystem: Ecosystem) {
    return ecosystem?.images.some((img) => img.imgtype === GalleryType.CEM);
  }

  public classificationLVLClass(level: string) {
    switch (level) {
      case '1': return 'level-1';
      case '2': return 'level-2';
      case '3': return 'level-3';
      case '4': return 'level-4';
      case '5': return 'level-5';
      case '6': return 'level-6';
      default:
        if (parseInt(level, 10) >= 7) {
          return 'level-7';
        }
        break;
    }
  }

  private renderChart(chartData: any) {
    this.pie1Options = null;
    this.pie2Options = null;
    const parseName = (text: string) => text.split(' ')[0];
    const { categ_data, system_data } = chartData;
    const pie1 = categ_data.map((e) => ({
      ...e,
      y: parseFloat(e.y),
      color: getCatColor(parseName(e.name))
    }));
    const pie2 = system_data.map((e) => ({
      ...e,
      y: parseFloat(e.y),
      color: getSystemColor(e.name)
    }));
    const plotOptions: Highcharts.PlotOptions = {
      pie: {
        showInLegend: true,
        point: {
          events: {
            legendItemClick() { return false; }
          }
        }
      }
    };
    this.pie1Options = {
      title: { text: 'Red List of Ecosystems category' },
      series: [{ name: '%', data: pie1, type: 'pie', }],
      plotOptions,
      credits: { enabled: false },
      responsive: {
        rules: [{
          chartOptions: {
            series: [{
              name: '%', type: 'pie', data: pie1, dataLabels: {
                formatter() { return parseName(this.point.name); }
              }
            }],
            plotOptions,
            chart: { height: 550 },
            legend: { layout: 'vertical' }
          },
          condition: { callback: () => this.window.nativeWindow.innerWidth < 768 }
        }, {
          // this rule is only when transitioning from < 768 back to >= 768
          // the highcharts gives a runtime error complaining about formatter not being defined???
          chartOptions: {
            series: [{
              name: '%', type: 'pie', data: pie1, dataLabels: {
                formatter() { return this.point.name; }
              }
            }],
            plotOptions,
          },
          condition: { callback: () => this.window.nativeWindow.innerWidth >= 768 }
        }]
      }
    };
    this.pie2Options = {
      title: { text: 'Systems' },
      series: [{ name: '%', data: pie2, type: 'pie', }],
      plotOptions,
      credits: { enabled: false },
      responsive: {
        rules: [{
          chartOptions: {
            plotOptions, chart: { height: 550 }, legend: { layout: 'vertical' }
          },
          condition: { callback: () => this.window.nativeWindow.innerWidth < 768 }
        }]
      }
    };
  }


  public searchStudiesNextPage() {
    if (this.searchResultsNextUrl) {
      this.loading.loadingMore = true;
      this.studiesService.searchStudiesNextPage(this.searchResultsNextUrl).then(
        (nextPageResults) => {
          this.searchResultsNextUrl = nextPageResults.search.next;
          this.related_units = this.related_units.concat(nextPageResults.search.results);
          this.loading.loadingMore = false;
        },
        () => { this.loading.loadingMore = false; }
      );
    }
  }

}
