import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';

const SkeletonWidthMap = [85, 70, 75, 80, 65, 63];
const MAX_ROWS = 6;
const SkeletonParagraphClassName = 'skeleton-paragraph';

@Directive({
  selector: '[appSkeletonParagraph]'
})
export class SkeletonParagraphDirective implements OnChanges {
  @Input('appSkeletonParagraph') loading = false;
  @Input() Srows = 3;
  @Input() SClass = 'fadeIn';
  private skeletonParagraph;
  private initedSkeleton = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  private clearSkeleton() {
    this.renderer.removeClass(this.el.nativeElement, SkeletonParagraphClassName);
    if (this.SClass) {
      this.renderer.removeClass(this.el.nativeElement, this.SClass);
    }
    this.renderer.removeChild(this.el.nativeElement, this.skeletonParagraph);
  }
  private addSkeleton() {
    // create paragraph psudo-component
    this.skeletonParagraph = this.renderer.createElement('div');
    this.renderer.addClass(this.skeletonParagraph, 'skeleton-p-container');

    const createSkeletonLine = (percentage = 100) => {
      const percentageString = percentage.toString();
      const line = this.renderer.createElement('div');
      this.renderer.addClass(line, 'skeleton-line');
      // add animation class if any
      if (this.SClass) {
        this.renderer.addClass(line, this.SClass);
      }
      this.renderer.setStyle(line, 'width', percentageString + '%');
      return line;
    };

    // maximum rows
    this.Srows = this.Srows >= MAX_ROWS ? MAX_ROWS : this.Srows;

    // add paragraph rows
    for (let i = 0; i < this.Srows; i++) {
      this.renderer.appendChild(this.skeletonParagraph, createSkeletonLine(SkeletonWidthMap[i]));
    }

    // render Skeleton paragraph psudo-component to host element
    this.renderer.addClass(this.el.nativeElement, SkeletonParagraphClassName);
    this.renderer.appendChild(this.el.nativeElement, this.skeletonParagraph);
    this.initedSkeleton = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading) {
      if (this.loading) {
        this.addSkeleton();
      } else {
        if (this.initedSkeleton) {
          this.clearSkeleton();
        }
      }
    }
  }
}
