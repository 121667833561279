import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../services/authentication.service';
import { InitUser, UpdateUser } from '../../store/main.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitting: boolean;
  backendErrorMessage: string;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private authService: AuthenticationService,
    private TranslocoS: TranslocoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', {
        validators: [Validators.required, Validators.email]
      }],
      password: ['', {
        validators: [Validators.required]
      }],
      rememberMe: [false]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  async onSubmit() {
    if (this.loginForm.enable && this.loginForm.valid) {
      try {
        this.submitting = true;
        this.backendErrorMessage = null;
        const login = await this.authService.login(this.loginForm.value);
        this.store.dispatch(new InitUser(login));
        const userInfo = await this.authService.whoAmI();
        this.store.dispatch(new UpdateUser(userInfo));
        this.submitting = false;

        // at this point we have initialize all the required user information for the webApp to work normally
        // because we are going to load the admin view, we need to let know the AdminShell component that
        // it doesn't need to exchange new access tokens as if it was coming from a browser refresh.
        this.router.navigate(['/search']);
      } catch (error) {
        console.error(error);
        this.submitting = false;
        switch (error.status) {
          case 0:
            this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_2');
            break;
          case 500:
            this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_1');
            break;
          default:
            //if (error.errors?.message) {
            if (error.error.detail.includes("No active account")) {
              //this.backendErrorMessage = error.errors.message;
              this.backendErrorMessage = this.TranslocoS.translate('global.errors.inactive_account');
            } else {
              this.backendErrorMessage = this.TranslocoS.translate('global.errors.invalid_credentials');
            }
            break;
        }
      }
    }
  }
}
