import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User, Group, Permission, UserResponse } from '../models/user.model';
import { Store } from '@ngxs/store';
const API = environment.APIBASE;

@Injectable()
export class UsersService {

  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  getUsers(nextPage?: string) {
    const requestURL = nextPage ? nextPage : API + 'users/';
    return this.http.get<UserResponse>(requestURL).toPromise();
  }
  getFilteredUsers(params) {
    return this.http.get<UserResponse>(API + `users/`+params);
  }
  getEmailUsers(params) {
    return this.http.get<UserResponse>(API + `users/findemail/`+params);
  }
  createUser(user: User) {
    return this.http.post<User>(API + `users/`, user).toPromise();
  }
  getUser(userId: string | number) {
    const token = this.store.selectSnapshot<string>((state) => state?.main?.user?.access);
    let headers = {};
    if (token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      });
    }
    return this.http.get<User>(API + `users/${userId}`, { headers }).toPromise();
  }
  getUserGroup(userId: string | number) {
    return this.http.get<Group[]>(API + `users/${userId}/get_groups/`).toPromise();
  }
  setGroupsToUser(userId: string | number, groupsIds: string[] | number[]) {
    return this.http.post<any[]>(API + `users/${userId}/set_groups/`, { groups: groupsIds }).toPromise();
  }
  patchUser(userId: string | number, user: User) {
    const token = this.store.selectSnapshot<string>((state) => state?.main?.user?.access);
    let headers = {};
    if (token) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      });
    }
    return this.http.patch<User>(API + `users/${userId}/`, user, { headers }).toPromise();
  }
  deleteUser(userId: string | number) {
    return this.http.delete<void>(API + `users/${userId}/`).toPromise();
  }
  resentActivationEmail(email: string) {
    return this.http.post<void>(API + `users/resend_activation/`, { email }).toPromise();
  }

  getGroups() {
    return this.http.get<Group[]>(API + `groups/`).toPromise();
  }
  getFilteredGroups(params) {
    return this.http.get<Group[]>(API + `groups/`+params);
  }
  createGroup(group: Group) {
    return this.http.post<User>(API + `groups/`, group).toPromise();
  }
  getGroup(groupId: string | number) {
    return this.http.get<User>(API + `groups/${groupId}`).toPromise();
  }
  patchGroup(groupId: string | number, group: Group) {
    return this.http.patch<User>(API + `groups/${groupId}/`, group).toPromise();
  }
  deleteGroup(groupId: string | number) {
    return this.http.delete<User>(API + `groups/${groupId}/`).toPromise();
  }

  getPermissions() {
    return this.http.get<Permission[]>(API + `permissions/`).toPromise();
  }
}
