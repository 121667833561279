import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';

import { UsersService } from '../../services/users.service';
import { AdminDialogService } from '../../services/adminDialog/admin-dialog.service';
import { SetAllowNavigation } from '../../store/main.actions';
import { compareValidator } from '../../../adminModule/commons/validators';
import { User } from '../../models/user.model';
import { MainState } from '../../store/main.state';
import { Observable } from 'rxjs';
import { SearchDownloadService } from '../../services/searchDownload.service';

export interface DownloadHistory {
  name: string;
  description: string;
  date_created: string;
  comment: string;
  id: number;
  profile: any[]
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Select(MainState.user) user$: Observable<User>;
  downloadHistory: DownloadHistory[];
  dataSource;
  displayedColumns: string[] = ['name', 'description', 'date_created'];

  constructor(
    private router: Router,
    private usersService: UsersService,
    private fb: FormBuilder,
    private adminDialog: AdminDialogService,
    private store: Store,
    private translocoS: TranslocoService,
    private searchDownloadService: SearchDownloadService
  ) { }

  async ngOnInit() {
    const userId = this.store.selectSnapshot<string>((state) => state?.main?.user?.id);
    const result = (await this.searchDownloadService.downloadHistory(userId).toPromise()) as DownloadHistory[];
    this.dataSource = result.map(item => {
      return {
        ...item,
        date_created: new Date(item.date_created.split('.').shift())
      }
    });
  }


}
