import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

import { AuthenticationService } from '../../services/authentication.service';
import { RPassword } from '../../models/user.model';
import { compareValidator } from '../../../adminModule/commons/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitting: boolean;
  backendErrorMessage: string;
  showIncorrectLink = false;
  showResetPasswordSuccess = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private TranslocoS: TranslocoService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      password1: ['', {
        validators: [Validators.required]
      }],
      password2: ['', {
        validators: [Validators.required, compareValidator('password1')]
      }],
    });

    const params = this.route.snapshot.queryParams;
    if (!params.uid || !params.token) {
      this.showIncorrectLink = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }

  async onSubmit() {
    if (this.resetPasswordForm.enable && this.resetPasswordForm.valid) {
      try {
        this.submitting = true;
        this.backendErrorMessage = null;
        const { uid, token } = this.route.snapshot.queryParams;
        const resetPasswordObj: RPassword = {
          new_password: this.resetPasswordForm.value.password1,
          uid,
          token,
        }
        await this.authService.resetPassword(resetPasswordObj);
        // if now errors ocurred we have successfully reset the password
        // show success message and back to login button prompt
        this.submitting = false;
        this.showResetPasswordSuccess = true;
      } catch (error) {
        console.error(error);
        this.submitting = false;
        this.resetPasswordForm.reset();
        switch (error.status) {
          case 0:
            this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_2');
            break;
          case 500:
            this.backendErrorMessage = this.TranslocoS.translate('global.errors.network_error_1');
            break;
          default:
            // TODO: onces standards error message are defined from backend handle accordingly
            if (error.errors?.message) {
              this.backendErrorMessage = error.errors.message;
            } else {
              this.backendErrorMessage = this.TranslocoS.translate('global.errors.invalid_credentials');
            }
            break;
        }
      }
    } else {
      this.backendErrorMessage = this.TranslocoS.translate('admin.rPassword.error1');
    }
  }
}
