import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const API = environment.APIBASE;

export interface Search {
  search: string;
  terms: boolean;
  academicResearch: boolean;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchDownloadService {

  constructor(
    private http: HttpClient
  ) {}

  downloadSearch(search: Search) {
    return this.http.post(API + `cases/studies/downloadcsv/`, search, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadSearchGET(search: Search) {
    return this.http.post(API + `cases/studies/downloadcsv/`, search, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadHistory(userId) {
    return this.http.get(API + `users/${userId}/logs`)
  }
}
