import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { FileSaverService } from 'ngx-filesaver';
import { SearchDownloadService } from 'src/app/coreModule/services/searchDownload.service';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.html',
  styleUrls: ['./download-dialog.scss'],
})
export class DownloadDialogComponent implements OnInit {
  downloadsForm: FormGroup;
  active = false;
  submitting = false;
  pdf_file_url = null;
  usesOptions: any = {
    ecosystem_risk_assessment: false,
    academic_research_or_educational_use: false,
    environmental_reporting: false,
    policy_making: false,
    biodiversity_management: false,
    economic_planning: false,
    other: false
  };
  constructor(
    private translocoS: TranslocoService,
    private fb: FormBuilder,
    private searchDownload: SearchDownloadService,
    private _FileSaverService: FileSaverService,
    public dialogRef: MatDialogRef<DownloadDialogComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onNoClick(): void { this.dialogRef.close(); }
  ngOnInit(): void {
    this.pdf_file_url = localStorage.getItem('pdf_file_url');
    this.downloadsForm = this.fb.group({
      description: ['', {
        validators: [Validators.required, Validators.minLength(30)]
      }],
      terms: [null, {
        validators: [Validators.required]
      }],
      academicResearch: [null, {
        validators: [Validators.required]
      }]
    });
  }
  async onSubmit() {
    console.log(this.usesOptions);
    let academicResearch = '';
    if (this.usesOptions.ecosystem_risk_assessment) {
      academicResearch += 'ecosystem_risk_assessment | '
    }
    if (this.usesOptions.academic_research_or_educational_use) {
      academicResearch += 'academic_research_or_educational_use | '
    }
    if (this.usesOptions.environmental_reporting) {
      academicResearch += 'environmental_reporting | '
    }
    if (this.usesOptions.other) {
      academicResearch += 'other | '
    }
    if (this.usesOptions.policy_making) {
      academicResearch += 'policy_making | '
    }
    if (this.usesOptions.biodiversity_management) {
      academicResearch += 'biodiversity_management | '
    }
    if (this.usesOptions.economic_planning) {
      academicResearch += 'economic_planning | '
    }
    let search = '';
    // tslint:disable-next-line:forin
    for (const k in this.data.last_search) {
      if (!Array.isArray(this.data.last_search[k])) {
        search += `${k}=${this.data.last_search[k]}&`;
        continue;
      }
      this.data.last_search[k].forEach(item => search += `${k}=${item}&`);
    }
    const userId = this.store.selectSnapshot<string>((state) => state?.main?.user?.id);
    search = search.slice(0, -1);
    this.submitting = true;
    const searchItem = {
      ...this.downloadsForm.value,
      academicResearch,
      search,
      user_id: userId
    }
    this.searchDownload.downloadSearch(searchItem).subscribe(
      success => {
        this.submitting = false;
        const today = new Date();
        const ftoday = formatDate(today, 'yyyy-MM-dd_h-mm', 'en-US');
        const filename = 'studiesRLE_CSV_'+ftoday+'.zip';
        this._FileSaverService.save(success.body, filename);
        this.dialogRef.close(true);
      },
      error => {
        this.submitting = false;
      }
    );
  }
  // convenience getter for easy access to form fields
  get f() { return this.downloadsForm.controls; }
  toggle = () => this.active = !this.active;
}