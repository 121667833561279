import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subscription } from 'rxjs';

import { Assessment, AssessmentReference, StudyTypeIcon} from '../../models/assessment.model';
import { Ecosystem } from '../../models/ecosystem.model';
import { EcosystemRisk } from '../../models/ecosystem.risk.model';
import { StudiesService } from '../../services/studies.service';
import { EcosystemsService } from '../../services/ecosystems.service';
import { EcosystemDistribution } from '../../models/ecosystem.distribution.model';
import { WindowRefService } from '../../services/window-ref.service';
import { RestService } from '../../services/rest.service';
import { GalleryType } from '../../models/image.model';
import { environment } from 'src/environments/environment';
import { FrmYearPipe  } from '../../pipes/frmYear/frmYear.pipe';
const API = environment.APIBASE;

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('assessmentLegend') legendBox: ElementRef;
  public legendShow = false;
  public legendAllowToShow = false;
  public legendClassSwitchA = false;
  public legendClassSwitchB = false;
  public downloadDropdownIsActive = false;
  legendBoxH: number;
  private subscriptions: Subscription[] = [];
  assessmentId: number;
  guidelines_url: string;
  loading = {
    assessment: false,
    ecosystem: false,
    risks: false,
    distribution: false,
  };
  public assessment: Assessment;
  public ecosystem: Ecosystem;
  public ecosystems: Ecosystem[];
  public distribution: EcosystemDistribution;
  public ecosystemRisk: EcosystemRisk;
  public globalScope: string;
  public reference: AssessmentReference;
  public icons: StudyTypeIcon[] = [];
  public icon_systematic: string;
  public icon_strategic: string;
  previous_version: number;
  public get riskAsUnit() { return this.ecosystemRisk ? this.ecosystemRisk.as_unit[0] : undefined; }
  public readMoreChange = null;
  public backendAPIBASE = API;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private studiesService: StudiesService,
    private ecosystemsService: EcosystemsService,
    private windowRef: WindowRefService,
    private restService: RestService
  ) { }

  @HostListener('document:scroll', ['$event.target'])
  onDocumentScroll() {
    const legendBoxCurrentBottomYPosition = this.windowRef.nativeWindow.innerHeight - this.legendBoxH - Math.round(this.legendBox.nativeElement.getBoundingClientRect().top);
    if (legendBoxCurrentBottomYPosition > 0 && !this.legendClassSwitchA) {
      this.legendAllowToShow = false;
      this.legendClassSwitchA = true;
      this.legendClassSwitchB = false;
    }
    if (legendBoxCurrentBottomYPosition <= 0 && !this.legendClassSwitchB) {
      this.legendAllowToShow = true;
      this.legendClassSwitchB = true;
      this.legendClassSwitchA = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.legendBox)
      this.legendBoxH = this.legendBox.nativeElement.offsetHeight;
  }

  async ngOnInit() {
    this.guidelines_url = null;
    this.icon_systematic = null;
    this.icon_strategic = null;
    const settingData = await this.restService.getSettings();
    this.guidelines_url = settingData ? settingData?.[0]?.guidelines_url : null;
    this.icons = await this.studiesService.getStudyIcons();
    if (this.icons){
      for (let icon of this.icons){
        if (icon.study_type === 'systematic'){
          this.icon_systematic = icon.icon_data;
        }
        if (icon.study_type === 'strategic'){
          this.icon_strategic = icon.icon_data;
        }
      }
    }

    history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
    this.assessmentId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (this.assessmentId) {
        this.fetchAssessmentDetails(this.route.snapshot.paramMap.get('prev')=='previous')
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    history.scrollRestoration = 'auto';
  }

  public tabChangeHandler(event: MatTabChangeEvent) {
    this.readMoreChange = event.index;
  }

  private fetchAssessmentDetails(prev) {
    // loading main assessment content
    this.loading.assessment = true;
    let subAssessment;
    if (!prev){

      subAssessment = this.studiesService.getStudy(this.assessmentId).subscribe(
        (assessment) => {
          this.assessment = assessment;
          this.reference = assessment.referen ? assessment.referen[0] : null;
          const scope = assessment.scope[0];
          let scope2level;
          this.globalScope = scope.scope_class_elem_ids[0].split(' ')[1];
          if (scope.scope_class_elem_ids.length > 1) {
            // scope2level = assessment.scope[1];
            scope2level = ' / ' + scope.scope_class_elem_ids[1].split(' ')[1];
            this.globalScope += scope2level;
          }
          this.loading.assessment = false;
        },
        (err) => {
          this.router.navigate(['/not-found']);
          this.loading.assessment = false;
          console.error(err);
        },
      );
    }
    else{

      subAssessment = this.studiesService.getStudyPrevious(this.assessmentId).subscribe(
        (assessment) => {
          this.assessment = assessment;
          this.reference = assessment.referen ? assessment.referen[0] : null;
          const scope = assessment.scope[0];
          let scope2level;
          this.globalScope = scope.scope_class_elem_ids[0].split(' ')[1];
          if (scope.scope_class_elem_ids.length > 1) {
            // scope2level = assessment.scope[1];
            scope2level = ' / ' + scope.scope_class_elem_ids[1].split(' ')[1];
            this.globalScope += scope2level;
          }
          this.loading.assessment = false;
        },
        (err) => {
          this.router.navigate(['/not-found']);
          this.loading.assessment = false;
          console.error(err);
        },
      );
    }

    this.subscriptions.push(subAssessment);

    // loading assessment ecosystems
    this.loading.ecosystem = true;
    const subEcosystem = this.ecosystemsService.getStudyEcosystems(this.assessmentId).subscribe(
      (ecosystems) => {
        this.ecosystems = ecosystems.length > 1 ? ecosystems : null;
        this.ecosystem = this.locateEcosystem(ecosystems, this.assessmentId);
        this.loading.ecosystem = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.ecosystem = false;
        console.error(err);
      }
    );
    this.subscriptions.push(subEcosystem);

    // loading assessment distribution section
    this.loading.distribution = true;
    const subDistribution = this.ecosystemsService.getStudyEcosystemDistribution(this.assessmentId).subscribe(
      (distribution) => {
        this.distribution = this.locateEcosystem(distribution, this.assessmentId);
        this.loading.distribution = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.distribution = false;
        console.error(err);
      }
    );
    this.subscriptions.push(subDistribution);

    // loading assessment risks section
    this.loading.risks = true;
    const subRisks = this.ecosystemsService.getStudyEcosystemRisks(this.assessmentId).subscribe(
      (risks) => {
        this.ecosystemRisk = this.locateEcosystemRisk(risks, this.assessmentId);
        this.loading.risks = false;
      },
      (err) => {
        this.router.navigate(['/not-found']);
        this.loading.risks = false;
        console.error(err);
      }
    );
    this.subscriptions.push(subRisks);
  }

  private locateEcosystem(ecosystemsOrDistributions: any, ecosystemId: number) {
    const found = ecosystemsOrDistributions.find(ecosystem => ecosystemId === ecosystem.id);
    if (found) {
      return found;
    } else {
      const response = ecosystemsOrDistributions.length > 0 ? ecosystemsOrDistributions[0] : undefined;
      if (!response) {
        console.error('Ecosystem\'s details were not found!');
        return response;
      } else {
        return response;
      }
    }
  }

  private locateEcosystemRisk(risks: EcosystemRisk[], assessmentId: number) {
    const locateHelper = (array: any, id: number) => {
      const foundHelper = array.find((el: any) => id === el.id);
      if (foundHelper) {
        return foundHelper;
      } else {
        return array.length > 0 ? array[0] : undefined;
      }
    };

    const foundEcosystemRisk: EcosystemRisk = locateHelper(risks, assessmentId);
    if (foundEcosystemRisk) {
      return foundEcosystemRisk;
    } else {
      console.error('Ecosystem Risk details were not found!');
      return undefined;
    }
  }

  public haveCEMImages(ecosystem: Ecosystem) {
    return ecosystem?.images.some((img) => img.imgtype === GalleryType.CEM);
  }

  public haveMAPImages(ecosystem: Ecosystem) {
    return ecosystem?.images.some((img) => img.imgtype === GalleryType.MAP);
  }

  public classificationLVLClass(level: string) {
    switch (level) {
      case '1': return 'level-1';
      case '2': return 'level-2';
      case '3': return 'level-3';
      case '4': return 'level-4';
      case '5': return 'level-5';
      case '6': return 'level-6';
      default:
        if (parseInt(level, 10) >= 7 ) {
          return 'level-7';
        }
        break;
    }
  }

  itHasDownloadableFiles(assessment) {
    return assessment && (assessment.pdf_files.length > 0 || assessment.raster_file || assessment.shape_file);
  }

}
