import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

import {StudyTypeIcon} from '../../models/assessment.model';
import { StudiesService } from '../../services/studies.service';


interface ngStyle {
  [key: string]: string;
}

const TRANSLATEKEY = 'core.riskCategorySwitcherComponent.';

@Component({
  selector: 'app-risk-category-switcher',
  templateUrl: './risk-category-switcher.component.html',
  styleUrls: ['./risk-category-switcher.component.scss']
})
export class RiskCategorySwitcherComponent implements OnChanges, OnInit, OnDestroy {
  @Input() assessment_category: string;
  @Input() assessment_type: boolean;
  @Input() size: string;
  @Input() ngStyleSize: ngStyle; // manual size overnights the size input and class
  index = null;
  show = false;
  bullet = null;
  size_class = null;
  public icons: StudyTypeIcon[] = [];
  classes = {
    xs: 'size-xs',
    s: 'size-s',
    m: 'size-m',
    l: 'size-l',
  };
  category = null;
  tooltip = null;
  bullets: string[] = [
    'assets/images/riskbullets/co.png',
    'assets/images/riskbullets/cr.png',
    'assets/images/riskbullets/dd.png',
    'assets/images/riskbullets/en.png',
    'assets/images/riskbullets/lc.png',
    'assets/images/riskbullets/ne.png',
    'assets/images/riskbullets/nt.png',
    'assets/images/riskbullets/vu.png'
  ];
  public systematic: string = 'assets/images/assessmentTypes/systematic.svg';
  public nonsystematic: string = 'assets/images/assessmentTypes/non_systematic.svg';
  translocoSub: Subscription;

  tooltipTranslation: string;
  systematicTranslation: string;
  strategicTranslation: string;

  constructor(
    private translocoS: TranslocoService,
    private studiesService: StudiesService,
  ) { }

  async ngOnInit() {
    this.icons = await this.studiesService.getStudyIcons();
    if (this.icons){
      for (let icon of this.icons){
        if (icon.study_type === 'systematic'){
          this.systematic = icon.icon_data;
        }
        if (icon.study_type === 'strategic'){
          this.nonsystematic = icon.icon_data;
        }
      }
    }
    this.translocoSub = this.translocoS.langChanges$.subscribe(() => this.updateInfo());
    this.translocoS.selectTranslate(TRANSLATEKEY + this.makeCategoryTranslatable(this.assessment_category)).subscribe((value) => {
      this.tooltipTranslation = value;
      this.updateInfo();
    });
    this.translocoS.selectTranslate(TRANSLATEKEY + 'systematic').subscribe((value) => {
      this.systematicTranslation = value;
      this.updateInfo();
    });
    this.translocoS.selectTranslate(TRANSLATEKEY + 'strategic').subscribe((value) => {
      this.strategicTranslation = value;
      this.updateInfo();
    });
  }

  ngOnDestroy() {
    if (this.translocoSub) {
      this.translocoSub.unsubscribe();
    }
  }

  private updateInfo() {
    if (this.assessment_category) {
      this.category = this.assessment_category;
      this.tooltip = this.tooltipTranslation;
      this.bullet = this.bullets[this.switchBullet(this.category)];
      this.show = true;
    }else{
      this.category = null;
      this.tooltip = null;
      this.bullet = null;
      this.show = false;
    }
    if (typeof this.assessment_type !== 'undefined') {
      if (this.assessment_type) {
        this.tooltip = this.systematicTranslation;
        this.bullet = this.systematic;
      } else {
        this.tooltip = this.strategicTranslation;
        this.bullet = this.nonsystematic;
      }
      this.show = true;
    }
  }

  ngOnChanges() {
    const size = this.size && !this.ngStyleSize ? this.size  : 's';
    this.size_class = this.classes[size];
    this.updateInfo();
  }

  private switchBullet(cat: string) {
    // CO - Collapsed
    // CR - Critically Endangered
    // EN - Endangered
    // VU - Vulnerable
    // NT - Near Threatened
    // LC - Least Concern
    // DD - Data Deficient
    // NE - Not Evaluated
    switch (cat) {
      case 'Collapsed':
        return 0;
      case 'Critically Endangered':
        return 1;
      case 'Data Deficient':
        return 2;
      case 'Endangered':
        return 3;
      case 'Least Concern':
        return 4;
      case 'Not Evaluated':
        return 5;
      case 'Near Threatened':
        return 6;
      case 'Vulnerable':
        return 7;
    }
  }

  private makeCategoryTranslatable(category: string) {
    if (category) {
      return category.trim().toLowerCase().replace(' ', '_');
    }
  }
}
