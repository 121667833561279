
<article class="wrapper">
  <div class="container" *transloco="let t; read:'core.assessment'">
<!-- <article class="wrapper" *transloco="let t; read:'core.assessment'">
  <div class="container"> -->
    <section class="head">
      <div class="columns">
        <div class="column is-one-third assessment-img">
          <app-image-gallery [cover]="assessment?.cover" [gallery]="assessment?.images" [loading]="loading.assessment">
          </app-image-gallery>
        </div>
        <div class="column is-two-thirds">
          <h3 class="scope-name" [appSkeletonLine]="loading.assessment" [SWidth]="40" SClass="s2">{{t('scope_of_assessment')}}: {{ globalScope }}</h3>
          <div class="assessment-name">
            <h1 class="title" [appSkeletonLine]="loading.assessment" *ngIf="!assessment?.parent_systematic" [SWidth]="80" SClass="s3">{{ assessment?.name }}</h1>
            <h1 class="title" [appSkeletonLine]="loading.assessment" *ngIf="assessment?.parent_systematic" [SWidth]="80" SClass="s3">{{ assessment?.name_systematic }}</h1>

            <div class="dropdown is-right download-btn" [ngClass]="{'is-active': downloadDropdownIsActive}">
              <div class="dropdown-trigger">
                <a *ngIf="itHasDownloadableFiles(assessment)"
                  (click)="downloadDropdownIsActive=true"
                  (mouseover)="downloadDropdownIsActive=true"
                  class="button is-white is-medium"
                  href="#">
                  <span class="icon has-text-primary"><i class="fas fa-lg fa-download"></i></span>
                </a>
              </div>
              <div class="dropdown-menu" id="downloads-dropdown-menu" role="menu">
                <div class="dropdown-content" (mouseover)="downloadDropdownIsActive=true" (mouseout)="downloadDropdownIsActive=false">
                  <a *ngIf="assessment?.pdf_files.length > 0" class="dropdown-item" target="_blank"
                    [href]="assessment?.pdf_files[0].file">
                    {{t('download_pdf')}}
                  </a>
                  <a *ngIf="assessment?.raster_file" class="dropdown-item" target="_blank"
                    [href]="assessment?.raster_file">
                    {{t('download_raster_file')}}
                  </a>
                  <a *ngIf="assessment?.shape_file" class="dropdown-item" target="_blank"
                    [href]="assessment?.shape_file">
                    {{t('download_shape_file')}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr class="scope-hr">
          <div class="scope-container">
            <div class="scope overall-risk" *ngIf="!loading.assessment">
              <span class="title">{{t('overall_risk_category')}}</span>
              <app-risk-category-switcher class="category-bullet" [ngStyleSize]="{'height': '50px', 'width': '50px'}"
                [assessment_category]="assessment?.category.name"></app-risk-category-switcher>
            </div>
            <div class="scope assessment-type" [appSkeletonLine]="loading.assessment" [SWidth]="40">
              <span class="title">{{t('assessment_type')}}</span>
              <app-risk-category-switcher class="category-bullet" [ngStyleSize]="{'height': '40px', 'width': '40px'}"
                [assessment_type]="assessment?.is_systematic"></app-risk-category-switcher>
            </div>
          </div>
          <hr class="scope-hr">
          <div class="parent-container" *ngIf="assessment?.parent_systematic.id">
            <div class="parent-name">{{t('systematic_assessment')}}: {{assessment.parent_systematic.name}}</div>
            <a class="button is-small parent-button" [routerLink]="['/systematics/', assessment.parent_systematic.id]">{{t('view_summary')}}</a>
          </div>
          <div class="column is-full">
            <div class="info-wrapper">
              <div class="info" [appSkeletonParagraph]="loading.assessment" Srows="6">
                <ng-container *ngIf="assessment?.parent_version && !assessment?.parent_version.last_version.id">
                  <div class="info-item"
                    *ngIf="assessment.parent_version && assessment.parent_version.versions.length > 0">
                    <div class="info-h">{{t('previously_published')}}:</div>
                    <div class="keywords-wrapper">
                      <span class="tag is-white" *ngFor="let version of assessment.parent_version.versions">
                        <a class="button" routerLink="/assessments/{{version.id}}/previous">{{ version.year }} -
                          {{ version.category }}</a>
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="assessment?.parent_version && assessment?.parent_version.last_version.id">
                  <div class="info-item"
                    *ngIf="assessment.parent_version && assessment.parent_version.versions.length > 0">
                    <div class="info-highlight">
                      <i class="fas fa-exclamation-circle"></i>
                      <span class="aligned-with-icon">{{t('message_latest_assessment')}}
                      <a routerLink="/assessments/{{assessment.parent_version.last_version.id}}">{{t('link_latest_assessment')}}</a> </span>
                    </div>
                    <!-- <div class="keywords-wrapper">
                      <span class="tag is-white"></span>
                        <a class="button"
                          routerLink="/assessments/{{assessment.parent_version.last_version.id}}">{{ assessment.parent_version.last_version.year }}
                          -
                          {{ assessment.parent_version.last_version.category }}</a>
                      </span>
                    </div> -->
                    <ng-container *ngIf="assessment.parent_version.versions.length>1">
                      <div class="info-h">{{t('previously_published')}}:</div>
                      <div class="keywords-wrapper">
                        <span class="tag is-white" *ngFor="let version of assessment.parent_version.versions">
                          <a class="button"
                            routerLink="/assessments/{{version.id}}/previous">{{ version.year }} -
                            {{ version.category }}</a>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="head-info box outer">
      <div class="info-wrapper">
        <div class="info" [appSkeletonParagraph]="loading.assessment" Srows="6">
          <ng-container *ngIf="reference">
            <div class="info-item" *ngIf="assessment.authors?.length > 0">
              <div class="info-h">{{t('assessors')}}:</div>
              <span *ngFor="let author of assessment.authors; let last = last;">{{ author }}{{last === false ? ', ' : ''}}</span>
            </div>
            <div class="info-item" *ngIf="reference.citation !== ' '">
              <div class="info-h">{{t('citation')}}:</div>
              <span>{{ reference.citation }}</span>
            </div>
            <div class="info-item" *ngIf="reference.web_url">
              <div class="info-h">{{t('link')}}:</div>
              <a class="button is-text link" target="_blank" [href]="reference.web_url">{{ reference.web_url }}</a>
            </div>
            <div class="info-item" *ngIf="reference.year">
              <div class="info-h">{{t('publication_date')}}:</div>
              <span>{{ reference.year }}</span>
            </div>
            <div class="info-item" *ngIf="assessment.keywords && assessment.keywords.length > 0">
              <div class="info-h">{{t('keywords')}}:</div>
              <div class="keywords-wrapper">
                <span class="tag is-white" *ngFor="let keyword of assessment.keywords">{{ keyword.name }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>
    <div class="box outer">
      <section class="ecosystem">
        <h2 class="section-title" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('ecosystem_description')}}</h2>
        <hr>
        <p [appSkeletonParagraph]="loading.ecosystem" appReadMore [readMoreReCalc]="ecosystem?.description"
          [fullBorder]="true">{{ ecosystem?.description }}</p>
      </section>
      <section class="classification">
        <h2 class="section-title" [appSkeletonLine]="loading.assessment" SWidth="40">{{t('classification')}}</h2>
        <hr>
        <div class="columns">
          <div class="column" *ngIf="ecosystem?.class_syst_iucn_tree_ids.length > 0">
            <div [appSkeletonBox]="loading.ecosystem">
              <h3>{{t('habitats_classification_scheme')}}</h3>
              <ul>
                <li *ngFor="let classification of ecosystem.class_syst_iucn_tree_ids[0].elems"
                  [ngClass]="classificationLVLClass(classification.level)">
                  <span>- {{classification.name}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="column" *ngIf="ecosystem?.class_global_typology_tree_ids?.length > 0">
            <div [appSkeletonBox]="loading.ecosystem">
              <h3>{{t('global_typology')}}</h3>
              <ul>
                <li *ngFor="let classification of ecosystem?.class_global_typology_tree_ids[0]?.elems"
                  [ngClass]="classificationLVLClass(classification.level)">
                  <span>- {{classification.name}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="column" *ngIf="ecosystem?.class_other_tree_ids?.length > 0">
            <div [appSkeletonBox]="loading.ecosystem">
              <h3>{{ecosystem?.class_other_tree_ids[0]?.code ? ecosystem?.class_other_tree_ids[0]?.code : 'Other'}}</h3>
              <ul>
                <li *ngFor="let classification of ecosystem?.class_other_tree_ids[0]?.elems"
                  [ngClass]="classificationLVLClass(classification.level)">
                  <span>- {{classification.name}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="distribution">
        <h2 class="section-title" [appSkeletonLine]="loading.distribution" SWidth="30" SClass="s3">{{t('distribution')}}
        </h2>
        <hr>
        <p [appSkeletonParagraph]="loading.distribution">{{ distribution?.summary }}</p>
        <div class="subsection-info" [appSkeletonParagraph]="loading.distribution">
          <div class="columns">
            <div class="column">
              <strong>{{t('system')}}</strong>
              <div>{{ ecosystem?.global_typology_origin?.name }}</div>
            </div>
            <div class="column">
              <strong>{{t('biogeographic_realm')}}</strong>
              <div>{{ distribution?.bio_region_ids }}</div>
            </div>
            <div class="column">
              <strong>{{t('geographic_region')}}</strong>
              <div>{{ distribution?.geo_region_ids }}</div>
            </div>
            <div class="column">
              <strong>{{t('countries')}}</strong>
              <div class="countries-tags">
                <span class="tag is-white is-medium" *ngFor="let country of distribution?.country_ids">{{country}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="subsection-img" *ngIf="haveMAPImages(ecosystem)">
          <app-image-gallery galleryOfType="MAP" [curtain]="true" [gallery]="ecosystem?.images"></app-image-gallery>
        </div>
      </section>
      <section class="native-biota">
        <h2 class="section-title" [appSkeletonLine]="loading.ecosystem" SWidth="30" SClass="s3">
          {{t('characteristic_native_biota')}}</h2>
        <hr>
        <p [appSkeletonParagraph]="loading.ecosystem" appReadMore [readMoreReCalc]="ecosystem?.summary_biota"
          [fullBorder]="true">{{ ecosystem?.summary_biota }}</p>
        <div class="box" [appSkeletonBox]="loading.ecosystem" *ngIf="ecosystem?.taxon_ids?.length > 0">
          <h4>{{t('taxa')}}</h4>
          <div class="taxons-container">
            <span class="tag is-white is-medium taxons" *ngFor="let taxon of ecosystem?.taxon_ids">{{taxon}}</span>
          </div>
        </div>
      </section>
      <section class="abiotic-features" *ngIf="ecosystem?.summary_abiotic_enviroment">
        <h2 class="section-title" [appSkeletonLine]="loading.ecosystem" [SWidth]="30" SClass="s3">
          {{t('abiotic_features')}}</h2>
        <hr>
        <p [appSkeletonParagraph]="loading.ecosystem" appReadMore
          [readMoreReCalc]="ecosystem?.summary_abiotic_enviroment" [fullBorder]="true">
          {{ ecosystem?.summary_abiotic_enviroment }}</p>
      </section>
      <section class="biotic-process" *ngIf="ecosystem?.summary_biotic_process">
        <h2 class="section-title" [appSkeletonLine]="loading.ecosystem" [SWidth]="30" SClass="s3">
          {{t('biotic_processes')}}</h2>
        <hr>
        <p [appSkeletonParagraph]="loading.ecosystem" appReadMore [readMoreReCalc]="ecosystem?.summary_biotic_process"
          [fullBorder]="true">{{ ecosystem?.summary_biotic_process }}</p>
      </section>
      <section class="conceptual-model" *ngIf="ecosystem?.summary_ecosys_model || haveCEMImages(ecosystem)">
        <h2 class="section-title" [appSkeletonLine]="loading.ecosystem" [SWidth]="30" SClass="s3">
          {{t('conceptual_model')}}</h2>
        <div class="columns">
          <div class="column" *ngIf="ecosystem?.summary_ecosys_model"
            [ngClass]="{'is-two-thirds': haveCEMImages(ecosystem)}">
            <div [appSkeletonBox]="loading.ecosystem" appReadMore [readMoreReCalc]="ecosystem?.summary_ecosys_model"
              [fullBorder]="true">{{ ecosystem?.summary_ecosys_model }}</div>
          </div>
          <div class="column conceptual-model-img" *ngIf="haveCEMImages(ecosystem)"
            [ngClass]="{'is-one-third': ecosystem?.summary_ecosys_model}">
            <app-image-gallery galleryOfType="CEM" [curtain]="true" [gallery]="ecosystem?.images"
              [loading]="loading.ecosystem"></app-image-gallery>
          </div>
        </div>
      </section>
      <section class="threatening-processes" *ngIf="ecosystem?.summary_threat">
        <h2 class="section-title" [appSkeletonLine]="loading.ecosystem" [SWidth]="30" SClass="s3">{{t('threatening_processes')}}</h2>
        <hr>
        <p [appSkeletonParagraph]="loading.ecosystem" appReadMore [readMoreReCalc]="ecosystem?.summary_threat"
          [fullBorder]="true">{{ ecosystem?.summary_threat }}</p>
      </section>
    </div>
    <div class="box outer">
      <section class="collapse">
        <h2 class="section-title" [appSkeletonLine]="loading.ecosystem" [SWidth]="30" SClass="s3">{{t('collapse')}}</h2>
        <hr>
        <p [appSkeletonParagraph]="loading.ecosystem" appReadMore [readMoreReCalc]="ecosystem?.summary_collapse"
          [fullBorder]="true">{{ ecosystem?.summary_collapse }}</p>
      </section>
    </div>
    <div class="box outer">
      <section class="risk-assessment">
        <h2 class="section-title is-flex" [appSkeletonLine]="loading.risks" [SWidth]="50" SClass="s3">
          <span>{{t('ecosystem_risk_assessment')}}</span>
          <div class="risk-guidelines">
            <a href="{{guidelines_url}}" target="_blank">{{t('rle_guidelines_link')}}</a>
            <span class="icon" [matTooltip]="t('rle_guidelines_tooltip')"><i class="fas fa-info-circle"></i></span>
          </div>
        </h2>
        <hr class="under-title">
        <div class="columns" style="background-color: #f6f6f6;">
          <div class="column is-one-quarter">
            <div class="box" [appSkeletonParagraph]="loading.risks" Srows="2" style="height: 106px;">
              <h4 class="sub-headers">{{t('assessment_protocol')}}</h4>
              <!-- {{ ecosystemRisk?.version }} | v{{ ecosystemRisk?.protocolo }} -->
              <span> IUCN RLE v{{ ecosystemRisk?.version }}</span>
            </div>
          </div>
          <div class="column">
            <div class="box category-criteria" [appSkeletonParagraph]="loading.risks" Srows="2">
              <h4 class="sub-headers">{{t('red_list_of_ecosystems_category_and_criteria')}}</h4>
              <div style="display:flex;">
                <div style="display:-webkit-inline-box;min-width: fit-content;">
                  <app-risk-category-switcher class="category-bullet" [ngStyleSize]="{'height': '50px', 'width': '50px'}" [assessment_category]="assessment?.category.name">
                  </app-risk-category-switcher>
                </div>
                <span class="text-l" style="font-size: 18px; min-width: fit-content;padding:12px;">
                  {{ assessment?.category.name }}
                </span>
                <span class="text-r" style="font-size: 18px; min-width: fit-content;padding:12px;">
                  {{riskAsUnit?.supporting_subcriteria}}</span>
              </div>
            </div>
          </div>
          <div class="column is-one-fifth" >
            <div class="box" [appSkeletonParagraph]="loading.risks" Srows="2" style="height: 106px;">
              <h4 class="sub-headers">{{t('last_assessed')}}</h4>
              <span>{{ assessment?.date_assessment | frmYear }}</span>
            </div>
          </div>
        </div>
        <h3 [appSkeletonLine]="loading.risks" [SWidth]="30" SClass="s2">{{t('justification_subtitle')}}</h3>
        <p [appSkeletonParagraph]="loading.risks">{{ riskAsUnit?.summary }}</p>
        <div class="box" [appSkeletonBox]="loading.risks" *ngIf="riskAsUnit?.criterion_ids.length === 0"></div>
        <mat-tab-group *ngIf="riskAsUnit?.criterion_ids" animationDuration="0ms"
          (selectedTabChange)="tabChangeHandler($event)">
          <mat-tab *ngFor="let criterion of riskAsUnit?.criterion_ids">
            <ng-template mat-tab-label>
              <span class="name" [matTooltip]="criterion.criteriainfo?.name">{{t('criterion')}}
                {{criterion.name}}</span>
              <app-risk-category-switcher class="category-bullet" size="m"
                [assessment_category]="criterion?.category?.name"></app-risk-category-switcher>
            </ng-template>
            <div class="criterion-content">
              <h4 class="content-header">{{ t('summary') }}</h4>
              <hr class="under-content-header">
              <p>{{ criterion.summary ? criterion.summary : criterion.rationale }}</p>

              <div class="content-header-wrapper">
                <h4 class="content-header">{{t('risk_category')}} ({{t('criterion')}} {{criterion.name}})</h4>
              </div>
              <hr class="under-content-header">
              <div class="risk-category">
                <app-risk-category-switcher class="category-bullet separator" size="m"
                  [assessment_category]="criterion?.category?.name"></app-risk-category-switcher>
                <div class="category-upper-lower"
                  *ngIf="criterion?.plausible_upper?.name && criterion?.plausible_lower?.name">
                  <span class="upper-lower">{{t('uncertainty_threshold')}}</span>
                  <app-risk-category-switcher class="category-bullet" size="m"
                    [assessment_category]="criterion.plausible_lower.name"></app-risk-category-switcher>
                  <hr>
                  <app-risk-category-switcher class="category-bullet" size="m"
                    [assessment_category]="criterion.plausible_upper.name"></app-risk-category-switcher>
                </div>
              </div>

              <div class="criterion-e"
                *ngIf="criterion.name=='E' && criterion?.modelling?.length > 0 && (criterion?.modelling[0]?.name || criterion?.modelling[0]?.modelling_type)">
                <h4 class="is-size-4">{{t('model')}}</h4>
                <div *ngIf="criterion?.modelling[0]?.name">
                  <span class="has-text-weight-bold">{{t('model_name')}}: </span>
                  <span>{{criterion?.modelling[0]?.name}}</span>
                </div>
                <div *ngIf="criterion?.modelling[0]?.modelling_type">
                  <span class="has-text-weight-bold">{{t('type_of_model')}}: </span>
                  <span>{{criterion?.modelling[0]?.modelling_type}}</span>
                </div>
                <div *ngIf="criterion?.modelling[0]?.overall_collapse">
                  <span class="has-text-weight-bold">{{t('probability_of_collapse')}}: </span>
                  <span>{{criterion?.modelling[0]?.overall_collapse.years}} years</span>
                </div>
                <!-- <div *ngIf="criterion?.modelling[0]?.scenarios?.length > 0" appReadMore [fullBorder]="true" [readMoreReCalc]="readMoreChange">
                  <span class="has-text-weight-bold">Variables: </span>
                  <div class="scenarios-container">
                    <span class="tag is-white is-medium scenarios" [matTooltip]="scenario.description" *ngFor="let scenario of criterion?.modelling[0]?.scenarios">{{scenario.name}}</span>
                  </div>
                </div> -->
              </div>
              <div class="risk-table-wrapper">
                <div class="risk-table" *ngIf="criterion.name!=='E'">
                  <div class="tr">
                    <div class="th name">{{t('subcriteria')}}</div>
                    <div class="th cat">{{t('category')}}</div>
                    <div class="th just">{{t('justification')}}</div>
                    <div class="th indicators">{{t('key_indicators_in_detail')}}</div>
                  </div>
                  <div class="tr" *ngFor="let subcriterion of criterion.subcriterion" appReadMore
                    [readMoreReCalc]="readMoreChange">
                    <div class="td name">
                      <span [matTooltip]="subcriterion.subcriteriainfo?.name">{{subcriterion.name}}</span>
                    </div>
                    <div class="td cat">
                      <app-risk-category-switcher size="m" [assessment_category]="subcriterion?.category?.name">
                      </app-risk-category-switcher>
                    </div>
                    <div class="td just">
                      <span>{{subcriterion.summary ? subcriterion.summary : subcriterion.rationale}}</span></div>
                    <div class="td indicators">
                      <ul>
                        <li *ngIf="subcriterion.threat_defined_location">
                          <span>{{t('threat_defined_location_label')}}: </span><span
                            class="is-capitalized">{{subcriterion.threat_defined_location}}</span>
                        </li>
                        <li *ngIf="subcriterion.continuing_decline">
                          <span>{{t('continuing_decline_label')}}: </span><span
                            class="is-capitalized">{{subcriterion.continuing_decline}}</span>
                        </li>
                        <li>
                          <span>{{t('threatening_process_label')}}:
                          </span><span>{{subcriterion.threatening_process}}</span>
                        </li>
                        <ul class="key-indicators" *ngIf="subcriterion.key_indicators?.length > 0">
                          <ng-container *ngFor="let key_ind of subcriterion.key_indicators">
                            <li *ngIf="key_ind.name" class="key-indicator">
                              <div class="key-name">
                                <div><strong>{{t('indicator_variable')}}: </strong>{{key_ind.name}}</div>
                                <div>
                                  <ng-container [ngSwitch]="criterion.name">
                                    <span *ngSwitchCase="'A'">
                                      <ng-container *ngIf="key_ind.overall_extent_value">
                                        {{t('extent_decline')}} ({{key_ind.overall_extent_unit}}): <span
                                          class="is-capitalized">{{key_ind.overall_extent_value}}</span>
                                      </ng-container>
                                    </span>
                                    <span *ngSwitchCase="'B'"></span>
                                    <span *ngSwitchCase="'C'">
                                      <ng-container *ngIf="key_ind.overall_extent_value">
                                        {{t('extent')}} ({{key_ind.overall_extent_unit}}): <span
                                          class="is-capitalized">{{key_ind.overall_extent_value}}</span>
                                      </ng-container>
                                    </span>
                                    <span *ngSwitchCase="'D'">
                                      <ng-container *ngIf="key_ind.overall_extent_value">
                                        {{t('extent')}} ({{key_ind.overall_extent_unit}}): <span
                                          class="is-capitalized">{{key_ind.overall_extent_value}}</span>
                                      </ng-container>
                                    </span>
                                    <span *ngSwitchDefault>
                                      <ng-container *ngIf="key_ind.overall_extent_value">
                                        {{t('overall_extent')}} ({{key_ind.overall_extent_unit}}): <span
                                          class="is-capitalized">{{key_ind.overall_extent_value}}</span>
                                      </ng-container>
                                    </span>
                                  </ng-container>
                                </div>
                                <div *ngIf="criterion.name === 'C' || criterion.name === 'D'">
                                  <span>{{t('relative_severity')}} ({{key_ind.overall_severity_unit}}): <span
                                      class="is-capitalized">{{key_ind.overall_severity_value}}</span></span>
                                </div>
                              </div>
                              <ng-container *ngIf="key_ind.keyindicatorsdata.length > 0">
                                <ng-container *ngFor="let indicatorData of key_ind.keyindicatorsdata">
                                  <ul class="multiple-data"
                                    *ngIf="indicatorData.values && indicatorData.values.length > 0">
                                    <li class="data" *ngFor="let value of indicatorData.values">
                                      <div *ngIf="value.year">{{t('year')}}: {{value.year}}</div>
                                      <div *ngIf="indicatorData.extent_value">
                                        {{t('estimated_extent')}} ({{indicatorData.extent_unit}}):
                                        {{indicatorData.extent_value}}
                                      </div>
                                      <div *ngIf="value.indicator_value">
                                        <ng-container [ngSwitch]="criterion.name">
                                          <span *ngSwitchCase="'A'">{{t('estimated_extent')}}</span>
                                          <span *ngSwitchCase="'B'">{{t('mapped_distribution')}}</span>
                                          <span *ngSwitchDefault>{{t('value')}}</span>
                                        </ng-container>
                                        ({{value.indicator_unit}}): {{value.indicator_value}}
                                      </div>
                                    </li>
                                  </ul>
                                </ng-container>
                              </ng-container>
                            </li>
                          </ng-container>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </section>
    </div>
    <section class="references" *ngIf="reference">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{t('cited_references')}}</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <ul>
          <li *ngFor="let ref of reference.cited_references">{{ref.name}}</li>
        </ul>
      </mat-expansion-panel>
    </section>
  </div>
  <div class="floating-bullet" [ngClass]="{'allowToShow': legendAllowToShow, 'inactive': legendShow}">
    <button class="button is-large" (click)="legendShow = true">
      <span class="icon is-medium"><i class="fas fa-question"></i></span>
    </button>
  </div>
  <div class="floating-wrapper is-hidden-mobile" [ngClass]="{'allowToShow': legendAllowToShow, 'show': legendShow}">
    <div class="assessment-legend" *transloco="let t;">
      <div class="container">
        <div class="legend-title">{{t('core.assessment.legend')}}</div>
        <div class="pipe"></div>
        <div class="legend-head" [innerHTML]="t('core.assessment.red_list_ecosystems_categories_html')"></div>
        <div class="categories">
          <img src="assets/images/riskbullets/co.png" [matTooltip]="t('core.riskCategorySwitcherComponent.collapsed')" alt="Collapsed">
          <img src="assets/images/riskbullets/cr.png" [matTooltip]="t('core.riskCategorySwitcherComponent.critically_endangered')" alt="Critically Endangered">
          <img src="assets/images/riskbullets/en.png" [matTooltip]="t('core.riskCategorySwitcherComponent.endangered')" alt="Endangered">
          <img src="assets/images/riskbullets/vu.png" [matTooltip]="t('core.riskCategorySwitcherComponent.vulnerable')" alt="Vulnerable">
          <img src="assets/images/riskbullets/nt.png" [matTooltip]="t('core.riskCategorySwitcherComponent.near_threatened')" alt="Near Threatened">
          <img src="assets/images/riskbullets/lc.png" [matTooltip]="t('core.riskCategorySwitcherComponent.least_concern')" alt="Least Concern">
          <img src="assets/images/riskbullets/dd.png" [matTooltip]="t('core.riskCategorySwitcherComponent.data_deficient')" alt="Data Deficient">
          <img src="assets/images/riskbullets/ne.png" [matTooltip]="t('core.riskCategorySwitcherComponent.not_evaluated')" alt="Not Evaluated">
        </div>
        <div class="pipe"></div>
        <div class="legend-head" [innerHTML]="t('core.assessment.assessment_type_html')"></div>
        <div class="categories">
          <img src="{{icon_strategic}}" [matTooltip]="t('core.riskCategorySwitcherComponent.strategic')" alt="Strategic">
          <img src="{{icon_systematic}}" [matTooltip]="t('core.riskCategorySwitcherComponent.systematic')" alt="Systematic">
        </div>
      </div>
      <div class="dismiss" (click)="legendShow = false">
        <span class="icon is-medium"><i class="fas fa-times"></i></span>
      </div>
    </div>
  </div>
  <div class="assessment-legend is-hidden-mobile" #assessmentLegend>
    <div class="container" *transloco="let t;">
      <div class="legend-title">{{t('core.assessment.legend')}}</div>
      <div class="pipe"></div>
      <div class="legend-head" [innerHTML]="t('core.assessment.red_list_ecosystems_categories_html')"></div>
      <div class="categories">
        <img src="assets/images/riskbullets/co.png" [matTooltip]="t('core.riskCategorySwitcherComponent.collapsed')" alt="Collapsed">
        <img src="assets/images/riskbullets/cr.png" [matTooltip]="t('core.riskCategorySwitcherComponent.critically_endangered')" alt="Critically Endangered">
        <img src="assets/images/riskbullets/en.png" [matTooltip]="t('core.riskCategorySwitcherComponent.endangered')" alt="Endangered">
        <img src="assets/images/riskbullets/vu.png" [matTooltip]="t('core.riskCategorySwitcherComponent.vulnerable')" alt="Vulnerable">
        <img src="assets/images/riskbullets/nt.png" [matTooltip]="t('core.riskCategorySwitcherComponent.near_threatened')" alt="Near Threatened">
        <img src="assets/images/riskbullets/lc.png" [matTooltip]="t('core.riskCategorySwitcherComponent.least_concern')" alt="Least Concern">
        <img src="assets/images/riskbullets/dd.png" [matTooltip]="t('core.riskCategorySwitcherComponent.data_deficient')" alt="Data Deficient">
        <img src="assets/images/riskbullets/ne.png" [matTooltip]="t('core.riskCategorySwitcherComponent.not_evaluated')" alt="Not Evaluated">
      </div>
      <div class="pipe"></div>
      <div class="legend-head" [innerHTML]="t('core.assessment.assessment_type_html')"></div>
      <div class="categories">
        <img src="{{icon_strategic}}" [matTooltip]="t('core.riskCategorySwitcherComponent.strategic')" alt="Strategic">
        <img src="{{icon_systematic}}" [matTooltip]="t('core.riskCategorySwitcherComponent.systematic')" alt="Systematic">
      </div>
    </div>
  </div>
</article>
