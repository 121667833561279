import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { FilterNode } from '../../models/filter.model';

@Component({
  selector: 'app-filters-menu',
  templateUrl: './filters-menu.component.html',
  styleUrls: ['./filters-menu.component.scss']
})
export class FiltersMenuComponent implements OnInit {
  @Input() nodes: FilterNode[] = [];
  @Output() selection = new EventEmitter();
  cleanEvent = new EventEmitter<boolean>();
  filterResult: FilterNode[] = [];
  public skeletons: number[] = [90, 100, 85, 70, 83, 92, 90, 76];

  private flatArray(nodes: FilterNode[]) {
    const result: FilterNode[] = [];

    const reducer = (node: FilterNode, rootFilterId?: number | string) => {
      if (!node) {
        return;
      }
      if (node.selected) {
        result.push({
          id: node.id,
          rootId: rootFilterId,
        });
        return;
      }
      if (node.child_ids) {
        node.child_ids.forEach(child => reducer(child, rootFilterId));
      }
    };
    nodes.forEach(node => {
      // in this loop we are dealing directly with the root filters
      // we can carry the filter node rootId, to latter map it into something usefull
      reducer(node, node.id);
    });
    return result;
  }

  ngOnInit() {
    this.filterResult = this.flatArray(this.nodes);
  }

  // this is a function receiver from the filter-item component
  filterChange() {
    this.filterResult = this.flatArray(this.nodes);
    this.selection.emit(this.filterResult);
  }

  public clearFilters() {
    const clear = (node: FilterNode) => {
      if (node) {
        node.selected = false;
        if (node.child_ids) {
          node.child_ids.forEach(child => clear(child));
        }
      }
    };
    this.nodes.forEach(node => {
      // in this loop we are dealing directly with the root filters
      clear(node);
    });
    this.cleanEvent.emit(true);
    this.filterResult = [];
    this.selection.emit([]);
  }

  public locateFilter(nodeCode: string) {
    return this.nodes.find((n) => n.code === nodeCode);
  }
}
