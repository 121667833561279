import { Directive, Input, Renderer2, ElementRef, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
  selector: '[appSkeletonImage]'
})
export class SkeletonImageDirective implements OnChanges {
  @Input('appSkeletonImage') loading = false;
  @Input() SClass: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading) {
      if (this.loading) {
        this.renderer.addClass(this.el.nativeElement, 'skeleton-img');
        if (this.SClass) {
          this.renderer.addClass(this.el.nativeElement, this.SClass);
        }
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'skeleton-img');
        if (this.SClass) {
          this.renderer.removeClass(this.el.nativeElement, this.SClass);
        }
      }
    }
  }

}
