<div class="container" *transloco="let t; read:'core.search'">
  <div class="columns is-marginless">
    <aside class="column is-fixed-size search-filters">
      <app-filters-menu [nodes]="filterNodes" (selection)="filterSelection($event)"></app-filters-menu>
    </aside>
    <div class="systematic-button-mobile">
      <button *ngIf="show_download_button" class="button has-text-white is-primary download-button" type="button" aria-haspopup="true" (click)="downloadCSV()">
        <span>
          {{ t('download') }}
        </span>
      </button>
      <a routerLink="/searchsys" class="button is-primary">{{ t('systematics') }}</a>
    </div>
    <mat-tab-group class="column" animationDuration="0ms" (selectedTabChange)="tabSelected($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <span class="icon"><i class="fas fa-th"></i></span>
            <span>
              <span>{{ t('grid') }}</span>
              <span class="counter" *ngIf="!loading.results">({{searchResultsCount}})</span>
              <span class="loading-icon" *ngIf="loading.results"><i class="fas fa-spinner"></i></span>
            </span>
          </div>
        </ng-template>
        <ng-container *ngIf="!loading.results">
          <ng-container *ngIf="assessments?.length > 0">
            <div class="columns is-multiline is-marginless">
              <div class="column is-one-third-widescreen is-half-desktop is-half-tablet" *ngFor="let assessment of assessments; trackBy: trackByFn">
                <app-assessment-card [appSkeletonBox]="loading.results" SVersion="2" [assessment]="assessment"></app-assessment-card>
              </div>
            </div>
            <div class="load-more" *ngIf="searchResultsNextUrl">
              <button class="button is-primary" [ngClass]="{'is-loading': loading.loadingMore}" (click)="searchStudiesNextPage()">{{ t('load_more') }}</button>
            </div>
          </ng-container>

          <ng-container *ngIf="assessments?.length === 0">
            <div class="box empty-result">{{ t('results_empty') }}</div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="loading.results">
          <div class="columns is-multiline is-marginless">
            <div class="column is-one-third-widescreen is-half-desktop is-half-tablet" *ngFor="let skeleton of skeletons;">
              <div class="card" [appSkeletonBox]="loading.results" SVersion="2"></div>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <span class="icon"><i class="fas fa-list"></i></span>
            <span>
              <span>{{ t('list') }}</span>
              <span class="counter" *ngIf="!loading.results">({{searchResultsCount}})</span>
              <span class="loading-icon" *ngIf="loading.results"><i class="fas fa-spinner"></i></span>
            </span>
          </div>
        </ng-template>
        <ng-container *ngIf="!loading.results">
          <div class="box assessment-list-wrapper" *ngIf="assessments?.length > 0">
            <table class="assessment-list-container">
              <thead>
                <tr><th class="study-typology is-hidden-touch">{{t('global_typology')}}</th><th class="study-name">{{t('assessment_title')}}</th><th class="col-category">{{t('category')}}</th></tr>
              </thead>
              <tbody>
                <tr class="assessment-list-item" *ngFor="let assessment of assessments; trackBy: trackByFn" [routerLink]="[ '/assessments', assessment.id ]">
                  <td class="study-typology is-hidden-touch">{{ assessment.global_typology_origin?.name }}</td>
                  <td class="study-name">{{ assessment.is_systematic ? assessment.name_systematic : assessment.name }}</td>
                  <td class="col-category">
                    <span class="global-category is-hidden-touch">{{assessment.scope[0].scope_class_elem_ids | categorizer}}</span>
                    <div class="cat-wrapper">
                      <app-risk-category-switcher class="category" size="s" [assessment_category]="assessment.category.name"></app-risk-category-switcher>
                      <app-risk-category-switcher class="category is-hidden-touch" size="s" [assessment_type]="assessment.is_systematic"></app-risk-category-switcher>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="load-more" *ngIf="searchResultsNextUrl">
              <button class="button is-primary" [ngClass]="{'is-loading': loading.loadingMore}" (click)="searchStudiesNextPage()">{{ t('load_more') }}</button>
            </div>
          </div>
          <ng-container *ngIf="assessments?.length === 0">
            <div class="box empty-result">{{ t('results_empty') }}</div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="loading.results">
          <div class="list-container box">
            <div class="list-item skeletons" *ngFor="let skeleton of skeletons;">
              <div [appSkeletonLine]="true"></div>
              <div class="loading-category is-hidden-touch"></div>
              <div class="loading-category"></div>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <span class="icon"><i class="fas fa-map"></i></span>
            <span>
              <span>{{ t('map') }}</span>
              <span class="counter" *ngIf="!loading.results">({{searchResultsCount}})</span>
              <span class="loading-icon" *ngIf="loading.results"><i class="fas fa-spinner"></i></span>
            </span>
          </div>
        </ng-template>
        <ng-container *ngIf="!loading.results">
          <ng-container *ngIf="assessments?.length > 0 && showMap">
            <div class="load-more" *ngIf="searchResultsNextUrl">
              <span class="icon has-text-info"><i class="fas fa-lg fa-info-circle"></i></span>
              <span>{{ t('button_loadmore_message') }} </span>
              <button class="button is-primary" [ngClass]="{'is-loading': loading.loadingMore}" (click)="searchStudiesNextPage()">{{ t('load_more') }}</button>
            </div>
            <div class="map-wrapper">
              <app-leaflet-map [markers]="assessments"></app-leaflet-map>
            </div>
          </ng-container>

          <ng-container *ngIf="assessments?.length === 0">
            <div class="box empty-result">{{ t('results_empty') }}</div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="loading.results">
          <div class="map-wrapper">
            <div class="box"><app-skeleton-svg-map></app-skeleton-svg-map></div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <span class="icon"><i class="fas fa-chart-pie"></i></span>
            <span>
              <span>{{ t('statistics') }}</span>
              <span class="counter" *ngIf="!loading.results">({{searchResultsCount}})</span>
              <span class="loading-icon" *ngIf="loading.results"><i class="fas fa-spinner"></i></span>
            </span>
          </div>
        </ng-template>
        <ng-container *ngIf="loading.results">
          <div class="columns statistics">
            <div class="column is-full-desktop is-half-widescreen">
              <div class="box" *ngIf="!pie1Options"><figure class="image is-1by1" [appSkeletonImage]="true"></figure></div>
            </div>
            <div class="column is-full-desktop is-half-widescreen">
              <div class="box" *ngIf="!pie2Options"><figure class="image is-1by1" [appSkeletonImage]="true"></figure></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!loading.results">
          <div class="columns statistics" *ngIf="assessments?.length > 0">
            <div class="column is-full">
              <div class="chart-container box">
                <highcharts-chart *ngIf="pie1Options"
                  [Highcharts]="Highcharts"
                  [options]="pie1Options"
                  [runOutsideAngular]="true"
                ></highcharts-chart>
                <div class="chart-footer">{{ chart_legend }}</div>
              </div>
            </div>
            <div class="column is-full">
              <div class="chart-container box">
                <highcharts-chart *ngIf="pie2Options"
                  [Highcharts]="Highcharts"
                  [options]="pie2Options"
                  [runOutsideAngular]="true"
                ></highcharts-chart>
                <div class="chart-footer">{{ chart_legend }}</div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="assessments?.length === 0">
            <div class="box empty-result">{{ t('results_empty') }}</div>
          </ng-container>
        </ng-container>
      </mat-tab>

      <!-- <mat-tab class="systematic-tab"  >
        <ng-template mat-tab-label>
          <a  routerLink="/searchsys" class="button is-primary">{{ t('systematics') }}</a>
        </ng-template>
        <ng-container>
        </ng-container>
        </mat-tab> -->
    </mat-tab-group>
    <div class="systematic-button  is-inline-flex-desktop">
      <button
        *ngIf="show_download_button"
        class="button has-text-white is-primary download-button"
        type="button"
        aria-haspopup="true"
        (click)="downloadCSV()">
        <span>
          {{ t('download') }}
        </span>
      </button>
      <a routerLink="/searchsys" class="button is-primary">{{ t('systematics') }}</a>
    </div>
  </div>
</div>


