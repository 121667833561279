import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';


import { MainState } from '../../store/main.state';
import { FilterNode, FilterGET } from '../../models/filter.model';
import { StudiesService, SearchResults } from '../../services/studies.service';
import { Assessment } from '../../models/assessment.model';
import { WindowRefService } from '../../services/window-ref.service';
import { getCatColor, getSystemColor } from '../../utils/functions';
import { SysStudiesService } from '../../services/sysstudies.service';

@Component({
  selector: 'app-search',
  templateUrl: './search-systematic.component.html',
  styleUrls: ['./search-systematic.component.scss'],
})
export class SearchSystematicComponent implements OnInit, OnDestroy {
  showMap = false;


  @Select(MainState.searchString) searchString$: Observable<string>;
  @Select(MainState.rootFilters) filterNodes$: Observable<FilterNode[]>;
  loading = {
    results: true,
    loadingMore: false,
  };
  search: string;
  selectedFilters: FilterNode[] = [];
  filterNodes: FilterNode[] = [];
  private subscriptions: Subscription[] = [];
  public assessments: Assessment[] = [];
  public skeletons: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public searchResultsCount: number;
  public searchResultsNextUrl: string;

  constructor(
    private studiesService: SysStudiesService,
    private window: WindowRefService,
  ) { }

  ngOnInit() {
    const searchObservable$ = this.searchString$.subscribe((payload) => {
      this.search = payload;
      this.searchStudies(this.selectedFilters, this.search);
    });
    const rootFiltersObservable$ = this.filterNodes$.subscribe((payload) => this.filterNodes = JSON.parse(JSON.stringify(payload)));
    const StudiesDelayedObservable$ = this.studiesService.searchStudiesResultsDelayed().subscribe(
      (searchResults) => {
        this.searchResultsCount = searchResults.search.count;
        this.searchResultsNextUrl = searchResults.search.next;
        
        this.assessments = searchResults.search.results;
        this.loading.results = false;
      }
    );
    this.subscriptions.push(searchObservable$, rootFiltersObservable$, StudiesDelayedObservable$);

    this.searchStudies(this.selectedFilters, this.search);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  filterSelection(selectedFilters: FilterNode[]) {
    this.selectedFilters = selectedFilters;
    this.searchStudies(this.selectedFilters, this.search);
  }

  tabSelected(event: MatTabChangeEvent) {
    if (event.index === 2) {
      this.showMap = true;
    } else {
      this.showMap = false;
    }

 
  }

  // TODO: add interface to chartData endpoint
  // TODO: add translation to labels

  private searchStudies(selectedFilters?: FilterNode[], searchPattern?: string) {
    const mapSelectedFilters = (filters: FilterNode[]) => {
      const getParams = {};
      filters.forEach(el => {
        if (!getParams.hasOwnProperty(el.rootId)) {
          getParams[el.rootId] = [];
        }
      });
      filters.forEach(filter => getParams[filter.rootId].push(filter.id));
      return getParams;
    };

    this.loading.results = true;
    let searchParams: FilterGET = {};
    // selectedFilters could be an empty array which is still intended behaviour
    if (selectedFilters) {
      searchParams = mapSelectedFilters(selectedFilters);
    }
    if (searchPattern) {
      searchParams.name = [searchPattern];
    }

    if (selectedFilters || searchPattern) {
      this.studiesService.searchStudies(searchParams);
    }
    if (!selectedFilters && !searchPattern) {
      this.subscriptions.push(this.studiesService.searchStudiesResults().subscribe(
        (searchResults) => {
          this.assessments = searchResults.search.results;
          this.loading.results = false;
        }
      ));
    }
  }

  public searchStudiesNextPage() {
    if (this.searchResultsNextUrl) {
      this.loading.loadingMore = true;
      this.studiesService.searchStudiesNextPage(this.searchResultsNextUrl).then(
        (nextPageResults) => {
          this.searchResultsNextUrl = nextPageResults.search.next;
          this.assessments = this.assessments.concat(nextPageResults.search.results);
          this.loading.loadingMore = false;
        },
        () => { this.loading.loadingMore = false; }
      );
    }
  }

  public trackByFn(index: number, item: Assessment) { return item.id; }
}
