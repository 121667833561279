import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BrandLogo, AdminSetting } from 'src/app/adminModule/models/adminsetting.model';

const server = environment.APIBASE;

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private http: HttpClient
  ) { }

  getBrandLogo() {
    return this.http.get<BrandLogo[]>(server + 'ops/settings/logo/').toPromise();
  }
  getSettings() {
    return this.http.get<AdminSetting[]>(server + 'ops/settings/').toPromise();
  }

  downloadPDF(pdf_url) {
    return this.http.get(pdf_url, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  loadSections(){
    return this.http.get(server + 'themes/sections/')
  }
  loadHabitatsOptions(){
    return this.http.get(server + 'ecosystems/habitatclassificationsfilter/');
  }
  loadThreatsOptions(classId) {
    return this.http.get(server + 'ecosystems/threatclassifications/' + classId + '/elements/');
  }
  loadConActionsOptions(){
    return this.http.get(server + 'ecosystems/habitatclassificationsfilter/');
  }
  loadCriteriaAssesedOptions(){
    return this.http.get(server + 'ecosystems/habitatclassificationsfilter/');
  }
  loadScopeOptions(){
    return this.http.get(server + 'ecosystems/scope/');
  }
  loadFooter(){
    return this.http.get(server + 'themes/footer/');
  }
}
