<div class="card has-text-black strategic" [ngClass]="{'no-vfx': noVfx}" *ngIf="!is_systematic">
  <a [routerLink]="[ '/assessments', assessment.id ]">
    <div class="card-image">
      <figure class="image is-4by3">
        <img [appImgLoader]="assessment?.cover">
      </figure>
    </div>
    <div class="card-content">
      <div class="content">
        <div class="study-typology">{{ assessment.global_typology_origin?.name }}</div>
        <div class="study-name" [matTooltip]="assessment.name">
          <ng-container *ngIf="assessment.is_systematic">{{ assessment.name_systematic }}</ng-container>
          <ng-container *ngIf="!assessment.is_systematic">{{ assessment.name }}</ng-container>
        </div>
        <div class="categories" >
          <span class="global-category" >{{assessment.scope[0].scope_class_elem_ids | categorizer}}</span>
          <div class="cat-wrapper">
            <app-risk-category-switcher class="category" size="m" [assessment_category]="assessment.category.name"></app-risk-category-switcher>
            <app-risk-category-switcher class="category" size="m" [assessment_type]="assessment.is_systematic"></app-risk-category-switcher>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
<div class="card has-text-black systematic" [ngClass]="{'no-vfx': noVfx}" *ngIf="is_systematic">
  <a [routerLink]="[ '/systematics', assessment.id ]">
    <div class="card-image">
      <figure class="image is-4by3">
        <img [appImgLoader]="assessment?.cover">
      </figure>
    </div>
    <div class="card-content" *transloco="let t; read:'core.assessment'">
      <div class="content ">
        <div class="study-typology">{{ typology_origin }}</div>
        <div class="study-name" [matTooltip]="assessment.name_systematic">{{ assessment.name }}</div>
        <div class="categories" >
          <span class="global-category systematic">{{t('number_of_assessed_units')}}: <b>{{ assessment.number_units }}</b></span>
          <div class="cat-wrapper">
            <app-risk-category-switcher class="category" size="m" [assessment_type]="true"></app-risk-category-switcher>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
