export const createRandomId = (id: number | string) => {
  const min = 1;
  const max = 999999999;
  const random = Math.random() * ( +max - +min) + +min;
  return id.toString() + Math.floor(random).toString();
};

export const getSystemColor = (text: string) => {
  // Subterranean/Marine RGB(178,189,197) #b2bdc5
  // Subterranean RGB(255,217,179) #ffd9b3
  // Subterranean/Freshwater RGB(220,228,215) #dce4d7
  // Freshwater/Terrestrial RGB(184,227,206) #b8e3ce
  // Freshwater RGB(186,239,252) #baeffc
  // Freshwater/Marine RGB(144,200,234) #90c8ea
  // Marine RGB(102,162,216) #66a2d8
  // Terrestrial RGB(183,216,160) #b7d8a0
  // Marine/Terrestrial RGB(142,189,188) #8ebdbc
  // Marine/Freshwater/Terrestrial RGB(143,194,211) #8fc2d3
  switch (text) {
    case 'Subterranean/Marine': return '#b2bdc5';
    case 'Subterranean': return '#ffd9b3';
    case 'Subterranean/Freshwater': return '#dce4d7';
    case 'Freshwater/Terrestrial': return '#b8e3ce';
    case 'Freshwater': return '#baeffc';
    case 'Freshwater/Marine': return '#90c8ea';
    case 'Marine': return '#66a2d8';
    case 'Terrestrial': return '#b7d8a0';
    case 'Marine/Terrestrial': return '#8ebdbc';
    case 'Marine/Freshwater/Terrestrial': return '#8fc2d3';
  }
};

export const getCatColor = (text: string) => {
  // CO - Collapsed
  // CR - Critically Endangered
  // EN - Endangered
  // VU - Vulnerable
  // NT - Near Threatened
  // LC - Least Concern
  // DD - Data Deficient
  // NE - Not Evaluated
  switch (text) {
    case 'CO': return '#000000';
    case 'Collapsed': return '#000000';
    case 'CR': return '#FF0000';
    case 'Critically Endangered': return '#FF0000';
    case 'DD': return '#808080';
    case 'Data Deficient': return '#808080';
    case 'EN': return '#ffa600';
    case 'Endangered': return '#ffa600';
    case 'LC': return '#008000';
    case 'Least Concern': return '#008000';
    case 'NE': return '#ffffff';
    case 'Not Evaluated': return '#ffffff';
    case 'NT': return '#aeff2b';
    case 'Near Threatened': return '#aeff2b';
    case 'VU': return '#ffff00';
    case 'Vulnerable': return '#ffff00';
  }
};

export const categoryImg = (cat: string) => {
  // CO - Collapsed
  // CR - Critically Endangered
  // EN - Endangered
  // VU - Vulnerable
  // NT - Near Threatened
  // LC - Least Concern
  // DD - Data Deficient
  // NE - Not Evaluated
  switch (cat) {
    case 'Collapsed':
      return 'assets/images/riskbullets/co.png';
    case 'Critically Endangered':
      return 'assets/images/riskbullets/cr.png';
    case 'Data Deficient':
      return 'assets/images/riskbullets/dd.png';
    case 'Endangered':
      return 'assets/images/riskbullets/en.png';
    case 'Least Concern':
      return 'assets/images/riskbullets/lc.png';
    case 'Not Evaluated':
      return 'assets/images/riskbullets/ne.png';
    case 'Near Threatened':
      return 'assets/images/riskbullets/nt.png';
    case 'Vulnerable':
      return 'assets/images/riskbullets/vu.png';
  }
};
