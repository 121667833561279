<div class="container">
  <div class="columns is-marginless">
    <aside class="column is-fixed-size search-filters">
      <app-profile-menu selected="change-password"></app-profile-menu>
    </aside>
    <section class="section" *transloco="let t;">
      <div class="wrapper">
        <div class="auth-main-container">
          <div class="box editor-box">
            <div class="section-title">
              {{t('admin.cPassword.container_header')}}
            </div>
            <hr class="section-separator">
            <div class="section-content">
              <form [formGroup]="resetPasswordForm" *ngIf="!showResetPasswordSuccess" (ngSubmit)="onSubmit()">
                <div class="field field-password">
                  <div class="control has-icons-left">
                    <input class="input" type="password" minlength="8" [ngClass]="{'is-danger': f.password3.dirty && f.password3.errors}" formControlName="password3" placeholder="{{ t('admin.cPassword.password_placeholder_3') }}">
                    <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
                  </div>
                  <p class="help is-danger" *ngIf="f.password3.dirty">
                    <span *ngIf="f.password3.errors?.required">{{ t('global.validation.required_field') }}</span>
                  </p>
                </div>
                <div class="field field-password">
                  <div class="control has-icons-left">
                    <input class="input" type="password" minlength="8" [ngClass]="{'is-danger': f.password1.dirty && f.password1.errors}" formControlName="password1" placeholder="{{ t('admin.cPassword.password_placeholder_1') }}">
                    <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
                  </div>
                  <p class="help is-danger" *ngIf="f.password1.dirty">
                    <span *ngIf="f.password1.errors?.required">{{ t('global.validation.required_field') }}</span>
                  </p>
                </div>
                <div class="field field-password">
                  <div class="control has-icons-left">
                    <input class="input" type="password" minlength="8" [ngClass]="{'is-danger': f.password2.dirty && f.password2.errors}" formControlName="password2" placeholder="{{ t('admin.cPassword.password_placeholder_2') }}">
                    <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
                  </div>
                  <p class="help is-danger" *ngIf="f.password2.dirty">
                    <span *ngIf="f.password2.errors?.required">{{ t('global.validation.required_field') }}</span>
                    <span *ngIf="f.password2.errors?.compare">{{ t('global.validation.password_match_error') }}</span>
                  </p>
                </div>
                <div class="field field-action">
                  <div class="control">
                    <button class="button is-fullwidth is-primary" [ngClass]="{'is-loading': submitting }" [disabled]="(submitting || resetPasswordForm.invalid) && resetPasswordForm.dirty" type="submit">{{ t('admin.cPassword.send_new_password_button') }}</button>
                  </div>
                </div>
              </form>
              <!--<div class="container-footer" *ngIf="!showResetPasswordSuccess">{{t('admin.cPassword.container_footer')}}</div>-->
              <div class="container-footer" *ngIf="showResetPasswordSuccess">
                <div class="success-message">{{t('admin.cPassword.success_message')}}</div>
                <!--<a class="button is-fullwidth is-primary" routerLink="/admin/login">{{ t('admin.cPassword.back_to_login_button') }}</a>-->
              </div>
            </div>
          </div>
        </div>
        <br><br>
        <div class="notifications-container" *ngIf="backendErrorMessage">
          <div class="notification is-danger">
            <button class="delete" (click)="backendErrorMessage = null;"></button>
            <span>{{backendErrorMessage}}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>



