import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Assessment } from '../../models/assessment.model';

@Component({
  selector: 'app-assessment-card',
  templateUrl: './assessment-card.component.html',
  styleUrls: ['./assessment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentCardComponent implements OnInit {
  @Input() assessment: Assessment;
  @Input() noVfx: boolean;
  is_systematic:boolean=false;
  typology_origin: string;
  tooltip: string;

  constructor() { }

  ngOnInit() {
    this.typology_origin = null;
    if (this.assessment.number_units){
      this.is_systematic=true;
      if (this.assessment.global_typology_origin && this.assessment.global_typology_origin[0]){
        this.typology_origin = this.assessment.global_typology_origin[0].name;
      }
    }
    this.tooltip = this.assessment.cover && this.assessment.cover.description ?
      this.assessment.cover.description : this.assessment.name;
    console.log(this.tooltip);
  }

}
