import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../../services/authentication.service';
import { AAccount } from '../../models/user.model';


@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  loading = false;
  success = false;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    const params = this.route.snapshot.queryParams;
    if (!params.uid || !params.token) {
      this.success = false;
      return;
    }

    try {
      this.loading = true;
      const { uid, token } = this.route.snapshot.queryParams;
      const activateAccountObj: AAccount = { uid, token };
      await this.authService.activateAccount(activateAccountObj);
      this.success = true;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.success = false;
    }
  }

}
