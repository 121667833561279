import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { switchMap, tap, debounceTime, catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

const createHttpParams = (params: {}): HttpParams => {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(param => {
    if (params[param]) {
      httpParams = httpParams.set(param, params[param]);
    }
  });
  return httpParams;
}

import { environment } from '../../../environments/environment';
import { Assessment, StudyTypeIcon} from '../models/assessment.model';
import { FilterGET } from '../models/filter.model';
const API = environment.APIBASE;

export interface AssessmentResults {
  count: number;
  next: string;
  previous: string;
  results: Assessment[];
}
export interface ChartItem {
  name: string;
  y: string | number;
}
export interface ChartResults {
  categ_data: ChartItem[];
  system_data: ChartItem[];
}
export interface SearchResults {
  search: AssessmentResults;
  charts: ChartResults;
}

@Injectable({
  providedIn: 'root'
})

export class SysStudiesService {
  searchResultsEmitter = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  searchStudies(params?: FilterGET) {
    this.searchResultsEmitter.next(params);
  }
  searchStudiesResults() {
    return this.http.get<SearchResults>(API + 'cases/systematics/');
  }
  searchStudiesResultsDelayed(): Observable<SearchResults> {
    return this.searchResultsEmitter.pipe(
      debounceTime(600),
      switchMap(
        (params: FilterGET) => this.http.get<null | SearchResults>(API + 'cases/systematics/', { params }).pipe(
          catchError((err) => {
            console.error(err);
            return of(null);
          }),
        )
      )
    );
  }



  getUnits(id: any, name?: string){
    const params = createHttpParams({systematicmaster: id, name});
    return this.http.get(API + 'cases/studies/search/', { params });
  }


  searchStudiesNextPage(nextUrl: string): Promise<SearchResults> {
    return this.http.get<null | SearchResults>(nextUrl).toPromise();
  }

  getChartData(assessments?: Assessment[]) {
    // TODO: remove this params, in the future, this data should be received using cases/studies/search/ endpoint
    const studies = assessments ? assessments.map((a) => a.id.toString()) : null;
    let params: any;
    if (studies) {
      params = { studies };
    }
    return this.http.get<any>(API + 'cases/charts/', { params });
  }
  getStudies() {
    return this.http.get<Assessment[]>(API + 'cases/systematics/');
  }

  getStudy(studieId: number | string) {
    return this.http.get<Assessment>(API + `cases/systematics/${studieId}/`);
  }

  getStudyPrevious(studieId: number | string) {
    // TODO: agregar prevesion al final
    return this.http.get<Assessment>(API + `cases/systematics/${studieId}`);
  }
  getStudyIcons() {
    return this.http.get<StudyTypeIcon[]>(API + `cases/studyicons/`).toPromise();
  }
}


