import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Ecosystem } from '../models/ecosystem.model';
import { EcosystemRisk } from '../models/ecosystem.risk.model';
import { EcosystemDistribution } from '../models/ecosystem.distribution.model';
const API = environment.APIBASE;

@Injectable({
  providedIn: 'root'
})
export class EcosystemsService {

  constructor(
    private http: HttpClient
  ) { }

  getStudyEcosystems(studieId: number | string) {
    return this.http.get<Ecosystem[]>(API + `ecosystems/studies/${studieId}/targets/`);
  }
  getStudyEcosystem(studieId: number | string) {
    return this.http.get<Ecosystem>(API + `ecosystems/studies/${studieId}/targets/${studieId}/`);
  }
  getStudyEcosystemRisks(studieId: number | string) {
    return this.http.get<EcosystemRisk[]>(API + `ecosystems/studies/${studieId}/risks/`);
  }
  getStudyEcosystemDistribution(studieId: number | string) {
    return this.http.get<EcosystemDistribution[]>(API + `ecosystems/studies/${studieId}/targets/${studieId}/distribution/`);
  }
}
