<div class="container">
  <div class="columns is-marginless">
    <aside class="column is-fixed-size search-filters">
      <app-profile-menu selected="profile"></app-profile-menu>
    </aside>
    <section class="section" *transloco="let t;">
      <div class="wrapper">
        <div class="auth-main-container">
          <div class="section-title">
            <h2 style="color: #363636;font-weight: 700;">{{ t('core.profile.title') }}</h2>
          </div>
          <hr class="section-separator">
          <div class="box editor-box">
            <div class="section-content">
              <ng-container *ngIf="(user$ | async) as user">
                <h2>{{ t('admin.usersDetail.email') }}</h2>
                <p>{{user.email}}</p>
                <h2>{{ t('admin.usersDetail.first_name') }}</h2>
                <p>{{user.first_name}}</p>
                <h2>{{ t('admin.usersDetail.last_name') }}</h2>
                <p>{{user.last_name}}</p>
              </ng-container>
            </div>
          </div>
          <div class="section-title">
            <h2>{{ t('core.profile.download_history') }}</h2>
          </div>
          <hr class="section-separator">
          <div class="box editor-box">
            <div class="section-title">
              {{t('core.profile.download_sugestions')}}
            </div>
            <div class="section-content">
              <br>
              <br>
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{t('core.profile.search')}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef> {{t('core.profile.description')}} </th>
                  <td mat-cell *matCellDef="let element" [innerHTML]="element.description"></td>
                </ng-container>

                <ng-container matColumnDef="date_created">
                  <th mat-header-cell *matHeaderCellDef> {{t('core.profile.search_date')}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.date_created | date: 'M/dd/yyyy'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>


