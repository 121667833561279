<main class="admin-auth-base">
  <section class="section" *transloco="let t">
    <div class="wrapper">
      <div class="auth-main-container">
        <div class="container-header">{{t('admin.rPassword.container_header')}}</div>
        <form [formGroup]="resetPasswordForm" *ngIf="!showIncorrectLink && !showResetPasswordSuccess" (ngSubmit)="onSubmit()">
          <div class="field field-password">
            <div class="control has-icons-left">
              <input class="input" type="password" minlength="8" [ngClass]="{'is-danger': f.password1.dirty && f.password1.errors}" formControlName="password1" placeholder="{{ t('admin.rPassword.password_placeholder_1') }}">
              <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
            </div>
            <p class="help is-danger" *ngIf="f.password1.dirty">
              <span *ngIf="f.password1.errors?.required">{{ t('global.validation.required_field') }}</span>
            </p>
          </div>
          <div class="field field-password">
            <div class="control has-icons-left">
              <input class="input" type="password" minlength="8" [ngClass]="{'is-danger': f.password2.dirty && f.password2.errors}" formControlName="password2" placeholder="{{ t('admin.rPassword.password_placeholder_2') }}">
              <span class="icon is-small is-left"><i class="fas fa-lock"></i></span>
            </div>
            <p class="help is-danger" *ngIf="f.password2.dirty">
              <span *ngIf="f.password2.errors?.required">{{ t('global.validation.required_field') }}</span>
              <span *ngIf="f.password2.errors?.compare">{{ t('global.validation.password_match_error') }}</span>
            </p>
          </div>
          <div class="field field-action">
            <div class="control">
              <button class="button is-fullwidth is-primary" [ngClass]="{'is-loading': submitting }" [disabled]="(submitting || resetPasswordForm.invalid) && resetPasswordForm.dirty" type="submit">{{ t('admin.rPassword.send_new_password_button') }}</button>
            </div>
          </div>
        </form>
        <div class="container-footer" *ngIf="showIncorrectLink && !showResetPasswordSuccess">{{t('admin.rPassword.container_footer')}}</div>
        <div class="container-footer" *ngIf="!showIncorrectLink && showResetPasswordSuccess">
          <div class="success-message">{{t('admin.rPassword.success_message')}}</div>
          <a class="button is-fullwidth is-primary" routerLink="/login">{{ t('admin.rPassword.back_to_login_button') }}</a>
        </div>
      </div>
      <div class="notifications-container" *ngIf="backendErrorMessage">
        <div class="notification is-danger">
          <button class="delete" (click)="backendErrorMessage = null;"></button>
          <span>{{backendErrorMessage}}</span>
        </div>
      </div>
    </div>

  </section>
</main>









<!-- <section class="section" *transloco="let t">
  <div class="wrapper">
    <div class="box reset-container">
      <div class="lds-ripple"><div></div><div></div></div>

      <h2 class="sub-title">{{ t('admin.rPassword.loading') }}</h2>
    </div>
    <div class="errors" *ngIf="backendErrorMessage">
      <div class="notification is-danger">
        <button class="delete" (click)="backendErrorMessage = null;"></button>
        {{backendErrorMessage}}
      </div>
    </div>
  </div>
</section> -->
