import { Directive, Input, OnInit, HostListener, HostBinding, Renderer2, ElementRef, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Image, DEFAULT_IMG_SQUARE } from '../models/image.model';

@Directive({
  selector: '[appImgLoader]',
})
export class ImgLoaderDirective implements OnInit, OnChanges {
  @Input('appImgLoader') image: Image;
  @Input() imageUrl: string;
  @Input() defaultImgUrl: string = DEFAULT_IMG_SQUARE;
  @HostBinding('src') src: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image || changes.imageUrl) {
      this.setImageUrl();
    }
  }
  ngOnInit() {
    this.setImageUrl();
  }

  private setImageUrl() {
    if (!this.image && !this.imageUrl) {
      this.src = this.defaultImgUrl;
      return;
    }
    if (this.image) {
      this.setLoadingStyles();
      this.src = this.image.image_data;
      return;
    }
    if (this.imageUrl) {
      this.setLoadingStyles();
      this.src = this.imageUrl;
      return;
    }
  }
  private setLoadingStyles() {
    this.renderer.setStyle(this.element.nativeElement, 'background-color', '#ccc');
    this.renderer.setStyle(this.element.nativeElement, 'filter', 'blur(15px)');
  }
  private removeLoadingStyles() {
    this.renderer.removeStyle(this.element.nativeElement, 'filter');
    this.renderer.removeStyle(this.element.nativeElement, 'background-color');
  }

  @HostListener('load') onImgloaded() {
    this.removeLoadingStyles();
  }
  @HostListener('error') onImgError() {
    this.src = this.defaultImgUrl;
    this.removeLoadingStyles();
  }
}
