import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { FilterNode } from '../../models/filter.model';
import { createRandomId } from '../../utils/functions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters-menu-item',
  templateUrl: './filters-menu-item.component.html',
  styleUrls: ['./filters-menu-item.component.scss']
})
export class FiltersMenuItemComponent implements OnInit, OnDestroy {
  @Input() node: FilterNode;
  @Input() clean: EventEmitter<boolean>;
  @Input() base = false;
  @Output() selection = new EventEmitter();
  cleanEvent = new EventEmitter<boolean>();
  active = false;
  psudoUUID: string;
  subscriptions: Subscription[] = [];

  ngOnInit() {
    // some child_ids are coming null, we need to filter those null childs for the UI
    // to not create a runtime exception
    if (this.node && this.node.child_ids) {
      this.node.child_ids = this.node.child_ids.filter((n) => n);
    }
    this.psudoUUID = createRandomId(this.node.id);
    if (this.base) {
      const cleanSub = this.clean.subscribe(() => {
        this.node.hasChildSelected = false;
        this.clearHasChildren(this.node.child_ids, false);
      });
      this.subscriptions.push(cleanSub);
    }
    this.active = this.node?.hasChildSelected;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private clearHasChildren(children: FilterNode[], value: boolean) {
    // child_ids === children
    if (children) {
      for (const object of children) {
        if (object) {
          object.hasChildSelected = value;
          if (object.child_ids) {
            this.clearHasChildren(object.child_ids, value);
          }
        }
      }
    }
  }

  private toggleAllvalues(children: FilterNode[], value: boolean) {
    // child_ids === children
    if (children) {
      for (const object of children) {
        if (!object) {
          return;
        }
        object.selected = value;
        if (object.child_ids) {
          this.toggleAllvalues(object.child_ids, value);
        }
      }
    }
  }

  private searchForActive(children: FilterNode[]): boolean {
    // child_ids === children
    if (children) {
      let foundSelected = false;
      for (const object of children) {
        if (object.selected) {
          return true;
        }
        if (object.child_ids) {
          foundSelected = this.searchForActive(object.child_ids);
        }
        if (foundSelected) {
          return true; // if found stop looping and return state
        }
      }
      return foundSelected; // finally return result
    }
  }

  passToParent() {
    this.node.hasChildSelected = this.searchForActive(this.node.child_ids);
    this.selection.emit(true);
  }

  onSelection() {
    if (this.node.selected) {
      this.toggleAllvalues(this.node.child_ids, true);
    } else {
      this.node.selected = null;
      this.node.hasChildSelected = false;
      this.toggleAllvalues(this.node.child_ids, null);
    }
    this.selection.emit(true);
  }

  toggle() {
    this.active = !this.active;
  }
}
