import { Component, OnInit, Input, Inject, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Image, GalleryType } from '../../models/image.model';
import { Subscription } from 'rxjs';

export interface DialogData {
  gallery: Image[];
  coverId: number;
}

@Component({
  selector: 'app-dialog-image-gallery',
  templateUrl: './dialog-image-gallery-dialog.html',
  styleUrls: ['./dialog-image-gallery-dialog.scss']
})
export class DialogImageGalleryComponent implements OnInit, OnDestroy {
  public gallery: Image[];
  public currentImg: Image;
  public currentIndex = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogImageGalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.gallery = this.data.gallery.sort((a, b) => {
      if (a.is_cover && !b.is_cover) { return -1; }
      if (!a.is_cover && b.is_cover) { return 1; }
      return 0;
    });
    this.initGallery(this.gallery, this.data.coverId);

    const subKeyEvents = this.dialogRef.keydownEvents().subscribe((key) => {
      switch (key.key) {
        case 'ArrowLeft':
          this.cycleLeft();
          break;
        case 'ArrowRight':
          this.cycleRight();
          break;
      }
    });
    this.subscriptions.push(subKeyEvents);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private initGallery(gallery: Image[], coverId) {
    const currentCoverIndex = gallery.findIndex((img) => img.id === coverId);
    this.currentIndex = currentCoverIndex;
    this.currentImg = gallery[currentCoverIndex];
  }

  public cycleLeft() {
    if (this.currentIndex !== 0) {
      this.currentIndex--;
      this.currentImg = this.gallery[this.currentIndex];
    }
  }
  public cycleRight() {
    if (this.currentIndex !== (this.gallery.length - 1)) {
      this.currentIndex++;
      this.currentImg = this.gallery[this.currentIndex];
    }
  }
}


@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnChanges {
  @Input() cover: Image;
  @Input() curtain = false;
  @Input() galleryOfType: GalleryType;
  @Input() gallery: Image[];
  @Input() loading: boolean;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gallery && this.gallery && !this.cover) {
      if (!this.galleryOfType) {
        // if this component is not being used in the Distribution nor CEM sections of assessment detail
        this.findAndSetCover(this.gallery);
      } else {
        this.findAndSetCover(this.gallery.filter((image) => image.imgtype === this.galleryOfType));
      }
    }
  }

  private findAndSetCover(images: Image[]) {
    this.cover = images.find((image) => image.is_cover);
  }

  openGallery(): void {
    if (this.gallery && !this.galleryOfType) {
      const dialogRef = this.dialog.open(DialogImageGalleryComponent, {
        data: { gallery: this.gallery, coverId: this.cover.id },
        panelClass: 'image-gallery-dialog',
      });
    }
    if (this.gallery && this.galleryOfType) {
      const dialogRef = this.dialog.open(DialogImageGalleryComponent, {
        data: {
          gallery: this.gallery.filter((image) => image.imgtype === this.galleryOfType),
          coverId: this.cover.id
        },
        panelClass: 'image-gallery-dialog',
      });
    }
  }
}
