<figure class="image click" *ngIf="cover" (click)="openGallery()">
  <img [appImgLoader]="cover" [matTooltip]="cover.name">
  <div class="curtain" *ngIf="curtain">
    <span class="icon is-large"><i class="fas fa-2x fa-search"></i></span>
  </div>
</figure>

<figure class="image" *ngIf="!loading && !cover">
  <img appImgLoader>
</figure>
<figure class="image is-4by3" *ngIf="!cover && loading" [appSkeletonImage]="true">
  <img src="" alt="">
</figure>

