import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frmYear'
})
export class FrmYearPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let index = 0;
    if (value && value.length > 0) {
    	let year = value.substring(0,4);
      index = year.indexOf( "-" ); 
      if (index > 0){
        year = value.substring(6,10);
      } else {
    	  index = year.indexOf( "/" ); 
        if (index > 0){
          year = value.substring(6,10);
        }
      }
      return year;
    } else {
      return '';
    }
  }
}
