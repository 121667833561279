import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vunits'
})
export class ValuesPipe implements PipeTransform {
// TODO con el nuevo backend esto es innecesario. Deuda Tecnica
  transform(value: any, args?: any): any {
    if (value !== null && value !==  '') {
      const obj = JSON.parse(value.replace('/', ''));
      switch (args) {
        case 'units':
          return obj.units;
        case 'value':
          return obj.value;
        default:
          return ' ' + obj.value + ' ' + obj.units;
      }
    } else {
      return 'Not Available';
    }
  }
}
