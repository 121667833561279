import { Component, OnInit, Input } from '@angular/core';

import { Stadistic } from '../../models/stadistic.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() bannerTitle: string;
  @Input() bannerSubtitle: string;
  @Input() stadistics: Stadistic[] = [];

  constructor() { }

  ngOnInit() {
  }

}
